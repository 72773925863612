var e,
  t = "undefined" != typeof window ? window : void 0,
  i = "undefined" != typeof globalThis ? globalThis : t,
  r = Array.prototype,
  s = r.forEach,
  n = r.indexOf,
  o = null == i ? void 0 : i.navigator,
  a = null == i ? void 0 : i.document,
  l = null == i ? void 0 : i.location,
  u = null == i ? void 0 : i.fetch,
  c = null != i && i.XMLHttpRequest && "withCredentials" in new i.XMLHttpRequest() ? i.XMLHttpRequest : void 0,
  d = null == i ? void 0 : i.AbortController,
  h = null == o ? void 0 : o.userAgent,
  _ = null != t ? t : {},
  p = {
    DEBUG: !1,
    LIB_VERSION: "1.215.1"
  },
  v = "$copy_autocapture",
  g = ["$snapshot", "$pageview", "$pageleave", "$set", "survey dismissed", "survey sent", "survey shown", "$identify", "$groupidentify", "$create_alias", "$$client_ingestion_warning", "$web_experiment_applied", "$feature_enrollment_update", "$feature_flag_called"];
!function (e) {
  e.GZipJS = "gzip-js", e.Base64 = "base64";
}(e || (e = {}));
var f = ["fatal", "error", "warning", "log", "info", "debug"],
  m = {};
function b(e, t, i) {
  if (B(e)) if (s && e.forEach === s) e.forEach(t, i);else if ("length" in e && e.length === +e.length) for (var r = 0, n = e.length; r < n; r++) if (r in e && t.call(i, e[r], r) === m) return;
}
function y(e, t, i) {
  if (!J(e)) {
    if (B(e)) return b(e, t, i);
    if (X(e)) {
      for (var r of e.entries()) if (t.call(i, r[1], r[0]) === m) return;
    } else for (var s in e) if (N.call(e, s) && t.call(i, e[s], s) === m) return;
  }
}
var w = function (e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) i[r - 1] = arguments[r];
    return b(i, function (t) {
      for (var i in t) void 0 !== t[i] && (e[i] = t[i]);
    }), e;
  },
  S = function (e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) i[r - 1] = arguments[r];
    return b(i, function (t) {
      b(t, function (t) {
        e.push(t);
      });
    }), e;
  };
function k(e) {
  for (var t = Object.keys(e), i = t.length, r = new Array(i); i--;) r[i] = [t[i], e[t[i]]];
  return r;
}
var E = function (e) {
    try {
      return e();
    } catch (e) {
      return;
    }
  },
  x = function (e) {
    return function () {
      try {
        for (var t = arguments.length, i = new Array(t), r = 0; r < t; r++) i[r] = arguments[r];
        return e.apply(this, i);
      } catch (e) {
        ee.critical("Implementation error. Please turn on debug mode and open a ticket on https://app.posthog.com/home#panel=support%3Asupport%3A."), ee.critical(e);
      }
    };
  },
  I = function (e) {
    var t = {};
    return y(e, function (e, i) {
      W(e) && e.length > 0 && (t[i] = e);
    }), t;
  };
function C(e, t) {
  return i = e, r = e => W(e) && !G(t) ? e.slice(0, t) : e, s = new Set(), function e(t, i) {
    return t !== Object(t) ? r ? r(t, i) : t : s.has(t) ? void 0 : (s.add(t), B(t) ? (n = [], b(t, t => {
      n.push(e(t));
    })) : (n = {}, y(t, (t, i) => {
      s.has(t) || (n[i] = e(t, i));
    })), n);
    var n;
  }(i);
  var i, r, s;
}
var P = ["herokuapp.com", "vercel.app", "netlify.app"];
function F(e) {
  var t = null == e ? void 0 : e.hostname;
  if (!W(t)) return !1;
  var i = t.split(".").slice(-2).join(".");
  for (var r of P) if (i === r) return !1;
  return !0;
}
function T(e, t) {
  for (var i = 0; i < e.length; i++) if (t(e[i])) return e[i];
}
function R(e, t, i, r) {
  var {
    capture: s = !1,
    passive: n = !0
  } = null != r ? r : {};
  null == e || e.addEventListener(t, i, {
    capture: s,
    passive: n
  });
}
function $(e, t) {
  return -1 !== e.indexOf(t);
}
var O = function (e) {
    return e.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
  },
  M = function (e) {
    return e.replace(/^\$/, "");
  };
var A = function (e, t) {
    if (!function (e) {
      try {
        new RegExp(e);
      } catch (e) {
        return !1;
      }
      return !0;
    }(t)) return !1;
    try {
      return new RegExp(t).test(e);
    } catch (e) {
      return !1;
    }
  },
  L = Array.isArray,
  D = Object.prototype,
  N = D.hasOwnProperty,
  q = D.toString,
  B = L || function (e) {
    return "[object Array]" === q.call(e);
  },
  H = e => "function" == typeof e,
  U = e => e === Object(e) && !B(e),
  j = e => {
    if (U(e)) {
      for (var t in e) if (N.call(e, t)) return !1;
      return !0;
    }
    return !1;
  },
  z = e => void 0 === e,
  W = e => "[object String]" == q.call(e),
  V = e => W(e) && 0 === e.trim().length,
  G = e => null === e,
  J = e => z(e) || G(e),
  Y = e => "[object Number]" == q.call(e),
  K = e => "[object Boolean]" === q.call(e),
  X = e => e instanceof FormData,
  Q = e => $(g, e),
  Z = e => {
    var i = {
      _log: function (i) {
        if (t && (p.DEBUG || _.POSTHOG_DEBUG) && !z(t.console) && t.console) {
          for (var r = ("__rrweb_original__" in t.console[i]) ? t.console[i].__rrweb_original__ : t.console[i], s = arguments.length, n = new Array(s > 1 ? s - 1 : 0), o = 1; o < s; o++) n[o - 1] = arguments[o];
          r(e, ...n);
        }
      },
      info: function () {
        for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) t[r] = arguments[r];
        i._log("log", ...t);
      },
      warn: function () {
        for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) t[r] = arguments[r];
        i._log("warn", ...t);
      },
      error: function () {
        for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) t[r] = arguments[r];
        i._log("error", ...t);
      },
      critical: function () {
        for (var t = arguments.length, i = new Array(t), r = 0; r < t; r++) i[r] = arguments[r];
        console.error(e, ...i);
      },
      uninitializedWarning: e => {
        i.error("You must initialize PostHog before calling ".concat(e));
      },
      createLogger: t => Z("".concat(e, " ").concat(t))
    };
    return i;
  },
  ee = Z("[PostHog.js]"),
  te = ee.createLogger,
  ie = te("[ExternalScriptsLoader]"),
  re = (e, t, i) => {
    if (e.config.disable_external_dependency_loading) return ie.warn("".concat(t, " was requested but loading of external scripts is disabled.")), i("Loading of external scripts is disabled");
    var r = () => {
      if (!a) return i("document not found");
      var r = a.createElement("script");
      if (r.type = "text/javascript", r.crossOrigin = "anonymous", r.src = t, r.onload = e => i(void 0, e), r.onerror = e => i(e), e.config.prepare_external_dependency_script && (r = e.config.prepare_external_dependency_script(r)), !r) return i("prepare_external_dependency_script returned null");
      var s,
        n = a.querySelectorAll("body > script");
      n.length > 0 ? null === (s = n[0].parentNode) || void 0 === s || s.insertBefore(r, n[0]) : a.body.appendChild(r);
    };
    null != a && a.body ? r() : null == a || a.addEventListener("DOMContentLoaded", r);
  };
function se(e, t) {
  var i = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(e);
    t && (r = r.filter(function (t) {
      return Object.getOwnPropertyDescriptor(e, t).enumerable;
    })), i.push.apply(i, r);
  }
  return i;
}
function ne(e) {
  for (var t = 1; t < arguments.length; t++) {
    var i = null != arguments[t] ? arguments[t] : {};
    t % 2 ? se(Object(i), !0).forEach(function (t) {
      oe(e, t, i[t]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(i)) : se(Object(i)).forEach(function (t) {
      Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(i, t));
    });
  }
  return e;
}
function oe(e, t, i) {
  return t in e ? Object.defineProperty(e, t, {
    value: i,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = i, e;
}
function ae(e, t) {
  if (null == e) return {};
  var i,
    r,
    s = function (e, t) {
      if (null == e) return {};
      var i,
        r,
        s = {},
        n = Object.keys(e);
      for (r = 0; r < n.length; r++) i = n[r], t.indexOf(i) >= 0 || (s[i] = e[i]);
      return s;
    }(e, t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    for (r = 0; r < n.length; r++) i = n[r], t.indexOf(i) >= 0 || Object.prototype.propertyIsEnumerable.call(e, i) && (s[i] = e[i]);
  }
  return s;
}
_.__PosthogExtensions__ = _.__PosthogExtensions__ || {}, _.__PosthogExtensions__.loadExternalDependency = (e, t, i) => {
  var r = "/static/".concat(t, ".js") + "?v=".concat(e.version);
  if ("remote-config" === t && (r = "/array/".concat(e.config.token, "/config.js")), "toolbar" === t) {
    var s = 3e5,
      n = Math.floor(Date.now() / s) * s;
    r = "".concat(r, "&t=").concat(n);
  }
  var o = e.requestRouter.endpointFor("assets", r);
  re(e, o, i);
}, _.__PosthogExtensions__.loadSiteApp = (e, t, i) => {
  var r = e.requestRouter.endpointFor("api", t);
  re(e, r, i);
};
var le = "$people_distinct_id",
  ue = "__alias",
  ce = "__timers",
  de = "$autocapture_disabled_server_side",
  he = "$heatmaps_enabled_server_side",
  _e = "$exception_capture_enabled_server_side",
  pe = "$web_vitals_enabled_server_side",
  ve = "$dead_clicks_enabled_server_side",
  ge = "$web_vitals_allowed_metrics",
  fe = "$session_recording_enabled_server_side",
  me = "$console_log_recording_enabled_server_side",
  be = "$session_recording_network_payload_capture",
  ye = "$session_recording_canvas_recording",
  we = "$replay_sample_rate",
  Se = "$replay_minimum_duration",
  ke = "$replay_script_config",
  Ee = "$sesid",
  xe = "$session_is_sampled",
  Ie = "$session_recording_url_trigger_activated_session",
  Ce = "$session_recording_event_trigger_activated_session",
  Pe = "$enabled_feature_flags",
  Fe = "$early_access_features",
  Te = "$stored_person_properties",
  Re = "$stored_group_properties",
  $e = "$surveys",
  Oe = "$surveys_activated",
  Me = "$flag_call_reported",
  Ae = "$user_state",
  Le = "$client_session_props",
  De = "$capture_rate_limit",
  Ne = "$initial_campaign_params",
  qe = "$initial_referrer_info",
  Be = "$initial_person_info",
  He = "$epp",
  Ue = "__POSTHOG_TOOLBAR__",
  je = "$posthog_cookieless",
  ze = [le, ue, "__cmpns", ce, fe, he, Ee, Pe, Ae, Fe, Re, Te, $e, Me, Le, De, Ne, qe, He],
  We = te("[FeatureFlags]"),
  Ve = "$active_feature_flags",
  Ge = "$override_feature_flags",
  Je = "$feature_flag_payloads",
  Ye = "$override_feature_flag_payloads",
  Ke = e => {
    var t = {};
    for (var [i, r] of k(e || {})) r && (t[i] = r);
    return t;
  };
class Xe {
  constructor(e) {
    oe(this, "_override_warning", !1), oe(this, "_hasLoadedFlags", !1), oe(this, "_requestInFlight", !1), oe(this, "_reloadingDisabled", !1), oe(this, "_additionalReloadRequested", !1), oe(this, "_decideCalled", !1), oe(this, "_flagsLoadedFromRemote", !1), this.instance = e, this.featureFlagEventHandlers = [];
  }
  decide() {
    if (this.instance.config.__preview_remote_config) this._decideCalled = !0;else {
      var e = !this._reloadDebouncer && (this.instance.config.advanced_disable_feature_flags || this.instance.config.advanced_disable_feature_flags_on_first_load);
      this._callDecideEndpoint({
        disableFlags: e
      });
    }
  }
  get hasLoadedFlags() {
    return this._hasLoadedFlags;
  }
  getFlags() {
    return Object.keys(this.getFlagVariants());
  }
  getFlagVariants() {
    var e = this.instance.get_property(Pe),
      t = this.instance.get_property(Ge);
    if (!t) return e || {};
    for (var i = w({}, e), r = Object.keys(t), s = 0; s < r.length; s++) i[r[s]] = t[r[s]];
    return this._override_warning || (We.warn(" Overriding feature flags!", {
      enabledFlags: e,
      overriddenFlags: t,
      finalFlags: i
    }), this._override_warning = !0), i;
  }
  getFlagPayloads() {
    var e = this.instance.get_property(Je),
      t = this.instance.get_property(Ye);
    if (!t) return e || {};
    for (var i = w({}, e || {}), r = Object.keys(t), s = 0; s < r.length; s++) i[r[s]] = t[r[s]];
    return this._override_warning || (We.warn(" Overriding feature flag payloads!", {
      flagPayloads: e,
      overriddenPayloads: t,
      finalPayloads: i
    }), this._override_warning = !0), i;
  }
  reloadFeatureFlags() {
    this._reloadingDisabled || this.instance.config.advanced_disable_feature_flags || this._reloadDebouncer || (this._reloadDebouncer = setTimeout(() => {
      this._callDecideEndpoint();
    }, 5));
  }
  clearDebouncer() {
    clearTimeout(this._reloadDebouncer), this._reloadDebouncer = void 0;
  }
  ensureFlagsLoaded() {
    this._hasLoadedFlags || this._requestInFlight || this._reloadDebouncer || this.reloadFeatureFlags();
  }
  setAnonymousDistinctId(e) {
    this.$anon_distinct_id = e;
  }
  setReloadingPaused(e) {
    this._reloadingDisabled = e;
  }
  _callDecideEndpoint(t) {
    if (this.clearDebouncer(), !this.instance.config.advanced_disable_decide) if (this._requestInFlight) this._additionalReloadRequested = !0;else {
      var i = {
        token: this.instance.config.token,
        distinct_id: this.instance.get_distinct_id(),
        groups: this.instance.getGroups(),
        $anon_distinct_id: this.$anon_distinct_id,
        person_properties: this.instance.get_property(Te),
        group_properties: this.instance.get_property(Re)
      };
      (null != t && t.disableFlags || this.instance.config.advanced_disable_feature_flags) && (i.disable_flags = !0), this._requestInFlight = !0, this.instance._send_request({
        method: "POST",
        url: this.instance.requestRouter.endpointFor("api", "/decide/?v=3"),
        data: i,
        compression: this.instance.config.disable_compression ? void 0 : e.Base64,
        timeout: this.instance.config.feature_flag_request_timeout_ms,
        callback: e => {
          var t,
            r,
            s = !0;
          (200 === e.statusCode && (this.$anon_distinct_id = void 0, s = !1), this._requestInFlight = !1, this._decideCalled) || (this._decideCalled = !0, this.instance._onRemoteConfig(null !== (r = e.json) && void 0 !== r ? r : {}));
          i.disable_flags || (this._flagsLoadedFromRemote = !s, this.receivedFeatureFlags(null !== (t = e.json) && void 0 !== t ? t : {}, s), this._additionalReloadRequested && (this._additionalReloadRequested = !1, this._callDecideEndpoint()));
        }
      });
    }
  }
  getFeatureFlag(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (this._hasLoadedFlags || this.getFlags() && this.getFlags().length > 0) {
      var i,
        r,
        s,
        n,
        o,
        a = this.getFlagVariants()[e],
        l = "".concat(a),
        u = this.instance.get_property(Me) || {};
      if (t.send_event || !("send_event" in t)) if (!(e in u) || !u[e].includes(l)) B(u[e]) ? u[e].push(l) : u[e] = [l], null === (i = this.instance.persistence) || void 0 === i || i.register({
        [Me]: u
      }), this.instance.capture("$feature_flag_called", {
        $feature_flag: e,
        $feature_flag_response: a,
        $feature_flag_payload: this.getFeatureFlagPayload(e) || null,
        $feature_flag_bootstrapped_response: (null === (r = this.instance.config.bootstrap) || void 0 === r || null === (s = r.featureFlags) || void 0 === s ? void 0 : s[e]) || null,
        $feature_flag_bootstrapped_payload: (null === (n = this.instance.config.bootstrap) || void 0 === n || null === (o = n.featureFlagPayloads) || void 0 === o ? void 0 : o[e]) || null,
        $used_bootstrap_value: !this._flagsLoadedFromRemote
      });
      return a;
    }
    We.warn('getFeatureFlag for key "' + e + "\" failed. Feature flags didn't load in time.");
  }
  getFeatureFlagPayload(e) {
    return this.getFlagPayloads()[e];
  }
  isFeatureEnabled(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (this._hasLoadedFlags || this.getFlags() && this.getFlags().length > 0) return !!this.getFeatureFlag(e, t);
    We.warn('isFeatureEnabled for key "' + e + "\" failed. Feature flags didn't load in time.");
  }
  addFeatureFlagsHandler(e) {
    this.featureFlagEventHandlers.push(e);
  }
  removeFeatureFlagsHandler(e) {
    this.featureFlagEventHandlers = this.featureFlagEventHandlers.filter(t => t !== e);
  }
  receivedFeatureFlags(e, t) {
    if (this.instance.persistence) {
      this._hasLoadedFlags = !0;
      var i = this.getFlagVariants(),
        r = this.getFlagPayloads();
      !function (e, t) {
        var i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
          r = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {},
          s = e.featureFlags,
          n = e.featureFlagPayloads;
        if (s) if (B(s)) {
          var o = {};
          if (s) for (var a = 0; a < s.length; a++) o[s[a]] = !0;
          t && t.register({
            [Ve]: s,
            [Pe]: o
          });
        } else {
          var l = s,
            u = n;
          e.errorsWhileComputingFlags && (l = ne(ne({}, i), l), u = ne(ne({}, r), u)), t && t.register({
            [Ve]: Object.keys(Ke(l)),
            [Pe]: l || {},
            [Je]: u || {}
          });
        }
      }(e, this.instance.persistence, i, r), this._fireFeatureFlagsCallbacks(t);
    }
  }
  override(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    We.warn("override is deprecated. Please use overrideFeatureFlags instead."), this.overrideFeatureFlags({
      flags: e,
      suppressWarning: t
    });
  }
  overrideFeatureFlags(e) {
    if (!this.instance.__loaded || !this.instance.persistence) return We.uninitializedWarning("posthog.feature_flags.overrideFeatureFlags");
    if (!1 === e) return this.instance.persistence.unregister(Ge), this.instance.persistence.unregister(Ye), void this._fireFeatureFlagsCallbacks();
    if (e && "object" == typeof e && ("flags" in e || "payloads" in e)) {
      var t,
        i = e;
      if (this._override_warning = Boolean(null !== (t = i.suppressWarning) && void 0 !== t && t), "flags" in i) if (!1 === i.flags) this.instance.persistence.unregister(Ge);else if (i.flags) if (B(i.flags)) {
        for (var r = {}, s = 0; s < i.flags.length; s++) r[i.flags[s]] = !0;
        this.instance.persistence.register({
          [Ge]: r
        });
      } else this.instance.persistence.register({
        [Ge]: i.flags
      });
      return "payloads" in i && (!1 === i.payloads ? this.instance.persistence.unregister(Ye) : i.payloads && this.instance.persistence.register({
        [Ye]: i.payloads
      })), void this._fireFeatureFlagsCallbacks();
    }
    this._fireFeatureFlagsCallbacks();
  }
  onFeatureFlags(e) {
    if (this.addFeatureFlagsHandler(e), this._hasLoadedFlags) {
      var {
        flags: t,
        flagVariants: i
      } = this._prepareFeatureFlagsForCallbacks();
      e(t, i);
    }
    return () => this.removeFeatureFlagsHandler(e);
  }
  updateEarlyAccessFeatureEnrollment(e, t) {
    var i,
      r = (this.instance.get_property(Fe) || []).find(t => t.flagKey === e),
      s = {
        ["$feature_enrollment/".concat(e)]: t
      },
      n = {
        $feature_flag: e,
        $feature_enrollment: t,
        $set: s
      };
    r && (n.$early_access_feature_name = r.name), this.instance.capture("$feature_enrollment_update", n), this.setPersonPropertiesForFlags(s, !1);
    var o = ne(ne({}, this.getFlagVariants()), {}, {
      [e]: t
    });
    null === (i = this.instance.persistence) || void 0 === i || i.register({
      [Ve]: Object.keys(Ke(o)),
      [Pe]: o
    }), this._fireFeatureFlagsCallbacks();
  }
  getEarlyAccessFeatures(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
      i = this.instance.get_property(Fe);
    if (i && !t) return e(i);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", "/api/early_access_features/?token=".concat(this.instance.config.token)),
      method: "GET",
      callback: t => {
        var i;
        if (t.json) {
          var r = t.json.earlyAccessFeatures;
          return null === (i = this.instance.persistence) || void 0 === i || i.register({
            [Fe]: r
          }), e(r);
        }
      }
    });
  }
  _prepareFeatureFlagsForCallbacks() {
    var e = this.getFlags(),
      t = this.getFlagVariants();
    return {
      flags: e.filter(e => t[e]),
      flagVariants: Object.keys(t).filter(e => t[e]).reduce((e, i) => (e[i] = t[i], e), {})
    };
  }
  _fireFeatureFlagsCallbacks(e) {
    var {
      flags: t,
      flagVariants: i
    } = this._prepareFeatureFlagsForCallbacks();
    this.featureFlagEventHandlers.forEach(r => r(t, i, {
      errorsLoading: e
    }));
  }
  setPersonPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
      i = this.instance.get_property(Te) || {};
    this.instance.register({
      [Te]: ne(ne({}, i), e)
    }), t && this.instance.reloadFeatureFlags();
  }
  resetPersonPropertiesForFlags() {
    this.instance.unregister(Te);
  }
  setGroupPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
      i = this.instance.get_property(Re) || {};
    0 !== Object.keys(i).length && Object.keys(i).forEach(t => {
      i[t] = ne(ne({}, i[t]), e[t]), delete e[t];
    }), this.instance.register({
      [Re]: ne(ne({}, i), e)
    }), t && this.instance.reloadFeatureFlags();
  }
  resetGroupPropertiesForFlags(e) {
    if (e) {
      var t = this.instance.get_property(Re) || {};
      this.instance.register({
        [Re]: ne(ne({}, t), {}, {
          [e]: {}
        })
      });
    } else this.instance.unregister(Re);
  }
}
Math.trunc || (Math.trunc = function (e) {
  return e < 0 ? Math.ceil(e) : Math.floor(e);
}), Number.isInteger || (Number.isInteger = function (e) {
  return Y(e) && isFinite(e) && Math.floor(e) === e;
});
var Qe = "0123456789abcdef";
class Ze {
  constructor(e) {
    if (this.bytes = e, 16 !== e.length) throw new TypeError("not 128-bit length");
  }
  static fromFieldsV7(e, t, i, r) {
    if (!Number.isInteger(e) || !Number.isInteger(t) || !Number.isInteger(i) || !Number.isInteger(r) || e < 0 || t < 0 || i < 0 || r < 0 || e > 0xffffffffffff || t > 4095 || i > 1073741823 || r > 4294967295) throw new RangeError("invalid field value");
    var s = new Uint8Array(16);
    return s[0] = e / Math.pow(2, 40), s[1] = e / Math.pow(2, 32), s[2] = e / Math.pow(2, 24), s[3] = e / Math.pow(2, 16), s[4] = e / Math.pow(2, 8), s[5] = e, s[6] = 112 | t >>> 8, s[7] = t, s[8] = 128 | i >>> 24, s[9] = i >>> 16, s[10] = i >>> 8, s[11] = i, s[12] = r >>> 24, s[13] = r >>> 16, s[14] = r >>> 8, s[15] = r, new Ze(s);
  }
  toString() {
    for (var e = "", t = 0; t < this.bytes.length; t++) e = e + Qe.charAt(this.bytes[t] >>> 4) + Qe.charAt(15 & this.bytes[t]), 3 !== t && 5 !== t && 7 !== t && 9 !== t || (e += "-");
    if (36 !== e.length) throw new Error("Invalid UUIDv7 was generated");
    return e;
  }
  clone() {
    return new Ze(this.bytes.slice(0));
  }
  equals(e) {
    return 0 === this.compareTo(e);
  }
  compareTo(e) {
    for (var t = 0; t < 16; t++) {
      var i = this.bytes[t] - e.bytes[t];
      if (0 !== i) return Math.sign(i);
    }
    return 0;
  }
}
class et {
  constructor() {
    oe(this, "timestamp", 0), oe(this, "counter", 0), oe(this, "random", new rt());
  }
  generate() {
    var e = this.generateOrAbort();
    if (z(e)) {
      this.timestamp = 0;
      var t = this.generateOrAbort();
      if (z(t)) throw new Error("Could not generate UUID after timestamp reset");
      return t;
    }
    return e;
  }
  generateOrAbort() {
    var e = Date.now();
    if (e > this.timestamp) this.timestamp = e, this.resetCounter();else {
      if (!(e + 1e4 > this.timestamp)) return;
      this.counter++, this.counter > 4398046511103 && (this.timestamp++, this.resetCounter());
    }
    return Ze.fromFieldsV7(this.timestamp, Math.trunc(this.counter / Math.pow(2, 30)), this.counter & Math.pow(2, 30) - 1, this.random.nextUint32());
  }
  resetCounter() {
    this.counter = 1024 * this.random.nextUint32() + (1023 & this.random.nextUint32());
  }
}
var tt,
  it = e => {
    if ("undefined" != typeof UUIDV7_DENY_WEAK_RNG && UUIDV7_DENY_WEAK_RNG) throw new Error("no cryptographically strong RNG available");
    for (var t = 0; t < e.length; t++) e[t] = 65536 * Math.trunc(65536 * Math.random()) + Math.trunc(65536 * Math.random());
    return e;
  };
t && !z(t.crypto) && crypto.getRandomValues && (it = e => crypto.getRandomValues(e));
class rt {
  constructor() {
    oe(this, "buffer", new Uint32Array(8)), oe(this, "cursor", 1 / 0);
  }
  nextUint32() {
    return this.cursor >= this.buffer.length && (it(this.buffer), this.cursor = 0), this.buffer[this.cursor++];
  }
}
var st = () => nt().toString(),
  nt = () => (tt || (tt = new et())).generate(),
  ot = "Thu, 01 Jan 1970 00:00:00 GMT",
  at = "";
var lt = /[a-z0-9][a-z0-9-]+\.[a-z]{2,}$/i;
function ut(e, t) {
  if (t) {
    var i = function (e) {
      var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : a;
      if (at) return at;
      if (!t) return "";
      if (["localhost", "127.0.0.1"].includes(e)) return "";
      for (var i = e.split("."), r = Math.min(i.length, 8), s = "dmn_chk_" + st(), n = new RegExp("(^|;)\\s*" + s + "=1"); !at && r--;) {
        var o = i.slice(r).join("."),
          l = s + "=1;domain=." + o;
        t.cookie = l, n.test(t.cookie) && (t.cookie = l + ";expires=" + ot, at = o);
      }
      return at;
    }(e);
    if (!i) {
      var r = (e => {
        var t = e.match(lt);
        return t ? t[0] : "";
      })(e);
      r !== i && ee.info("Warning: cookie subdomain discovery mismatch", r, i), i = r;
    }
    return i ? "; domain=." + i : "";
  }
  return "";
}
var ct = {
    is_supported: () => !!a,
    error: function (e) {
      ee.error("cookieStore error: " + e);
    },
    get: function (e) {
      if (a) {
        try {
          for (var t = e + "=", i = a.cookie.split(";").filter(e => e.length), r = 0; r < i.length; r++) {
            for (var s = i[r]; " " == s.charAt(0);) s = s.substring(1, s.length);
            if (0 === s.indexOf(t)) return decodeURIComponent(s.substring(t.length, s.length));
          }
        } catch (e) {}
        return null;
      }
    },
    parse: function (e) {
      var t;
      try {
        t = JSON.parse(ct.get(e)) || {};
      } catch (e) {}
      return t;
    },
    set: function (e, t, i, r, s) {
      if (a) try {
        var n = "",
          o = "",
          l = ut(a.location.hostname, r);
        if (i) {
          var u = new Date();
          u.setTime(u.getTime() + 24 * i * 60 * 60 * 1e3), n = "; expires=" + u.toUTCString();
        }
        s && (o = "; secure");
        var c = e + "=" + encodeURIComponent(JSON.stringify(t)) + n + "; SameSite=Lax; path=/" + l + o;
        return c.length > 3686.4 && ee.warn("cookieStore warning: large cookie, len=" + c.length), a.cookie = c, c;
      } catch (e) {
        return;
      }
    },
    remove: function (e, t) {
      try {
        ct.set(e, "", -1, t);
      } catch (e) {
        return;
      }
    }
  },
  dt = null,
  ht = {
    is_supported: function () {
      if (!G(dt)) return dt;
      var e = !0;
      if (z(t)) e = !1;else try {
        var i = "__mplssupport__";
        ht.set(i, "xyz"), '"xyz"' !== ht.get(i) && (e = !1), ht.remove(i);
      } catch (t) {
        e = !1;
      }
      return e || ee.error("localStorage unsupported; falling back to cookie store"), dt = e, e;
    },
    error: function (e) {
      ee.error("localStorage error: " + e);
    },
    get: function (e) {
      try {
        return null == t ? void 0 : t.localStorage.getItem(e);
      } catch (e) {
        ht.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(ht.get(e)) || {};
      } catch (e) {}
      return null;
    },
    set: function (e, i) {
      try {
        null == t || t.localStorage.setItem(e, JSON.stringify(i));
      } catch (e) {
        ht.error(e);
      }
    },
    remove: function (e) {
      try {
        null == t || t.localStorage.removeItem(e);
      } catch (e) {
        ht.error(e);
      }
    }
  },
  _t = ["distinct_id", Ee, xe, He, Be],
  pt = ne(ne({}, ht), {}, {
    parse: function (e) {
      try {
        var t = {};
        try {
          t = ct.parse(e) || {};
        } catch (e) {}
        var i = w(t, JSON.parse(ht.get(e) || "{}"));
        return ht.set(e, i), i;
      } catch (e) {}
      return null;
    },
    set: function (e, t, i, r, s, n) {
      try {
        ht.set(e, t, void 0, void 0, n);
        var o = {};
        _t.forEach(e => {
          t[e] && (o[e] = t[e]);
        }), Object.keys(o).length && ct.set(e, o, i, r, s, n);
      } catch (e) {
        ht.error(e);
      }
    },
    remove: function (e, i) {
      try {
        null == t || t.localStorage.removeItem(e), ct.remove(e, i);
      } catch (e) {
        ht.error(e);
      }
    }
  }),
  vt = {},
  gt = {
    is_supported: function () {
      return !0;
    },
    error: function (e) {
      ee.error("memoryStorage error: " + e);
    },
    get: function (e) {
      return vt[e] || null;
    },
    parse: function (e) {
      return vt[e] || null;
    },
    set: function (e, t) {
      vt[e] = t;
    },
    remove: function (e) {
      delete vt[e];
    }
  },
  ft = null,
  mt = {
    is_supported: function () {
      if (!G(ft)) return ft;
      if (ft = !0, z(t)) ft = !1;else try {
        var e = "__support__";
        mt.set(e, "xyz"), '"xyz"' !== mt.get(e) && (ft = !1), mt.remove(e);
      } catch (e) {
        ft = !1;
      }
      return ft;
    },
    error: function (e) {
      ee.error("sessionStorage error: ", e);
    },
    get: function (e) {
      try {
        return null == t ? void 0 : t.sessionStorage.getItem(e);
      } catch (e) {
        mt.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(mt.get(e)) || null;
      } catch (e) {}
      return null;
    },
    set: function (e, i) {
      try {
        null == t || t.sessionStorage.setItem(e, JSON.stringify(i));
      } catch (e) {
        mt.error(e);
      }
    },
    remove: function (e) {
      try {
        null == t || t.sessionStorage.removeItem(e);
      } catch (e) {
        mt.error(e);
      }
    }
  },
  bt = ["localhost", "127.0.0.1"],
  yt = e => {
    var t = null == a ? void 0 : a.createElement("a");
    return z(t) ? null : (t.href = e, t);
  },
  wt = function (e) {
    var t,
      i,
      r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "&",
      s = [];
    return y(e, function (e, r) {
      z(e) || z(r) || "undefined" === r || (t = encodeURIComponent((e => e instanceof File)(e) ? e.name : e.toString()), i = encodeURIComponent(r), s[s.length] = i + "=" + t);
    }), s.join(r);
  },
  St = function (e, t) {
    for (var i, r = ((e.split("#")[0] || "").split("?")[1] || "").split("&"), s = 0; s < r.length; s++) {
      var n = r[s].split("=");
      if (n[0] === t) {
        i = n;
        break;
      }
    }
    if (!B(i) || i.length < 2) return "";
    var o = i[1];
    try {
      o = decodeURIComponent(o);
    } catch (e) {
      ee.error("Skipping decoding for malformed query param: " + o);
    }
    return o.replace(/\+/g, " ");
  },
  kt = function (e, t, i) {
    if (!e || !t || !t.length) return e;
    for (var r = e.split("#"), s = r[0] || "", n = r[1], o = s.split("?"), a = o[1], l = o[0], u = (a || "").split("&"), c = [], d = 0; d < u.length; d++) {
      var h = u[d].split("=");
      B(h) && (t.includes(h[0]) ? c.push(h[0] + "=" + i) : c.push(u[d]));
    }
    var _ = l;
    return null != a && (_ += "?" + c.join("&")), null != n && (_ += "#" + n), _;
  },
  Et = function (e, t) {
    var i = e.match(new RegExp(t + "=([^&]*)"));
    return i ? i[1] : null;
  },
  xt = "Mobile",
  It = "iOS",
  Ct = "Android",
  Pt = "Tablet",
  Ft = Ct + " " + Pt,
  Tt = "iPad",
  Rt = "Apple",
  $t = Rt + " Watch",
  Ot = "Safari",
  Mt = "BlackBerry",
  At = "Samsung",
  Lt = At + "Browser",
  Dt = At + " Internet",
  Nt = "Chrome",
  qt = Nt + " OS",
  Bt = Nt + " " + It,
  Ht = "Internet Explorer",
  Ut = Ht + " " + xt,
  jt = "Opera",
  zt = jt + " Mini",
  Wt = "Edge",
  Vt = "Microsoft " + Wt,
  Gt = "Firefox",
  Jt = Gt + " " + It,
  Yt = "Nintendo",
  Kt = "PlayStation",
  Xt = "Xbox",
  Qt = Ct + " " + xt,
  Zt = xt + " " + Ot,
  ei = "Windows",
  ti = ei + " Phone",
  ii = "Nokia",
  ri = "Ouya",
  si = "Generic",
  ni = si + " " + xt.toLowerCase(),
  oi = si + " " + Pt.toLowerCase(),
  ai = "Konqueror",
  li = "(\\d+(\\.\\d+)?)",
  ui = new RegExp("Version/" + li),
  ci = new RegExp(Xt, "i"),
  di = new RegExp(Kt + " \\w+", "i"),
  hi = new RegExp(Yt + " \\w+", "i"),
  _i = new RegExp(Mt + "|PlayBook|BB10", "i"),
  pi = {
    "NT3.51": "NT 3.11",
    "NT4.0": "NT 4.0",
    "5.0": "2000",
    5.1: "XP",
    5.2: "XP",
    "6.0": "Vista",
    6.1: "7",
    6.2: "8",
    6.3: "8.1",
    6.4: "10",
    "10.0": "10"
  };
var vi = (e, t) => t && $(t, Rt) || function (e) {
    return $(e, Ot) && !$(e, Nt) && !$(e, Ct);
  }(e),
  gi = function (e, t) {
    return t = t || "", $(e, " OPR/") && $(e, "Mini") ? zt : $(e, " OPR/") ? jt : _i.test(e) ? Mt : $(e, "IE" + xt) || $(e, "WPDesktop") ? Ut : $(e, Lt) ? Dt : $(e, Wt) || $(e, "Edg/") ? Vt : $(e, "FBIOS") ? "Facebook " + xt : $(e, "UCWEB") || $(e, "UCBrowser") ? "UC Browser" : $(e, "CriOS") ? Bt : $(e, "CrMo") || $(e, Nt) ? Nt : $(e, Ct) && $(e, Ot) ? Qt : $(e, "FxiOS") ? Jt : $(e.toLowerCase(), ai.toLowerCase()) ? ai : vi(e, t) ? $(e, xt) ? Zt : Ot : $(e, Gt) ? Gt : $(e, "MSIE") || $(e, "Trident/") ? Ht : $(e, "Gecko") ? Gt : "";
  },
  fi = {
    [Ut]: [new RegExp("rv:" + li)],
    [Vt]: [new RegExp(Wt + "?\\/" + li)],
    [Nt]: [new RegExp("(" + Nt + "|CrMo)\\/" + li)],
    [Bt]: [new RegExp("CriOS\\/" + li)],
    "UC Browser": [new RegExp("(UCBrowser|UCWEB)\\/" + li)],
    [Ot]: [ui],
    [Zt]: [ui],
    [jt]: [new RegExp("(Opera|OPR)\\/" + li)],
    [Gt]: [new RegExp(Gt + "\\/" + li)],
    [Jt]: [new RegExp("FxiOS\\/" + li)],
    [ai]: [new RegExp("Konqueror[:/]?" + li, "i")],
    [Mt]: [new RegExp(Mt + " " + li), ui],
    [Qt]: [new RegExp("android\\s" + li, "i")],
    [Dt]: [new RegExp(Lt + "\\/" + li)],
    [Ht]: [new RegExp("(rv:|MSIE )" + li)],
    Mozilla: [new RegExp("rv:" + li)]
  },
  mi = [[new RegExp(Xt + "; " + Xt + " (.*?)[);]", "i"), e => [Xt, e && e[1] || ""]], [new RegExp(Yt, "i"), [Yt, ""]], [new RegExp(Kt, "i"), [Kt, ""]], [_i, [Mt, ""]], [new RegExp(ei, "i"), (e, t) => {
    if (/Phone/.test(t) || /WPDesktop/.test(t)) return [ti, ""];
    if (new RegExp(xt).test(t) && !/IEMobile\b/.test(t)) return [ei + " " + xt, ""];
    var i = /Windows NT ([0-9.]+)/i.exec(t);
    if (i && i[1]) {
      var r = i[1],
        s = pi[r] || "";
      return /arm/i.test(t) && (s = "RT"), [ei, s];
    }
    return [ei, ""];
  }], [/((iPhone|iPad|iPod).*?OS (\d+)_(\d+)_?(\d+)?|iPhone)/, e => {
    if (e && e[3]) {
      var t = [e[3], e[4], e[5] || "0"];
      return [It, t.join(".")];
    }
    return [It, ""];
  }], [/(watch.*\/(\d+\.\d+\.\d+)|watch os,(\d+\.\d+),)/i, e => {
    var t = "";
    return e && e.length >= 3 && (t = z(e[2]) ? e[3] : e[2]), ["watchOS", t];
  }], [new RegExp("(" + Ct + " (\\d+)\\.(\\d+)\\.?(\\d+)?|" + Ct + ")", "i"), e => {
    if (e && e[2]) {
      var t = [e[2], e[3], e[4] || "0"];
      return [Ct, t.join(".")];
    }
    return [Ct, ""];
  }], [/Mac OS X (\d+)[_.](\d+)[_.]?(\d+)?/i, e => {
    var t = ["Mac OS X", ""];
    if (e && e[1]) {
      var i = [e[1], e[2], e[3] || "0"];
      t[1] = i.join(".");
    }
    return t;
  }], [/Mac/i, ["Mac OS X", ""]], [/CrOS/, [qt, ""]], [/Linux|debian/i, ["Linux", ""]]],
  bi = function (e) {
    return hi.test(e) ? Yt : di.test(e) ? Kt : ci.test(e) ? Xt : new RegExp(ri, "i").test(e) ? ri : new RegExp("(" + ti + "|WPDesktop)", "i").test(e) ? ti : /iPad/.test(e) ? Tt : /iPod/.test(e) ? "iPod Touch" : /iPhone/.test(e) ? "iPhone" : /(watch)(?: ?os[,/]|\d,\d\/)[\d.]+/i.test(e) ? $t : _i.test(e) ? Mt : /(kobo)\s(ereader|touch)/i.test(e) ? "Kobo" : new RegExp(ii, "i").test(e) ? ii : /(kf[a-z]{2}wi|aeo[c-r]{2})( bui|\))/i.test(e) || /(kf[a-z]+)( bui|\)).+silk\//i.test(e) ? "Kindle Fire" : /(Android|ZTE)/i.test(e) ? !new RegExp(xt).test(e) || /(9138B|TB782B|Nexus [97]|pixel c|HUAWEISHT|BTV|noble nook|smart ultra 6)/i.test(e) ? /pixel[\daxl ]{1,6}/i.test(e) && !/pixel c/i.test(e) || /(huaweimed-al00|tah-|APA|SM-G92|i980|zte|U304AA)/i.test(e) || /lmy47v/i.test(e) && !/QTAQZ3/i.test(e) ? Ct : Ft : Ct : new RegExp("(pda|" + xt + ")", "i").test(e) ? ni : new RegExp(Pt, "i").test(e) && !new RegExp(Pt + " pc", "i").test(e) ? oi : "";
  },
  yi = "https?://(.*)",
  wi = ["gclid", "gclsrc", "dclid", "gbraid", "wbraid", "fbclid", "msclkid", "twclid", "li_fat_id", "igshid", "ttclid", "rdt_cid", "irclid", "_kx"],
  Si = S(["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term", "gad_source", "mc_cid"], wi),
  ki = "<masked>",
  Ei = {
    campaignParams: function () {
      var {
        customTrackedParams: e,
        maskPersonalDataProperties: t,
        customPersonalDataProperties: i
      } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
      if (!a) return {};
      var r = t ? S([], wi, i || []) : [];
      return this._campaignParamsFromUrl(kt(a.URL, r, ki), e);
    },
    _campaignParamsFromUrl: function (e, t) {
      var i = Si.concat(t || []),
        r = {};
      return y(i, function (t) {
        var i = St(e, t);
        r[t] = i || null;
      }), r;
    },
    _searchEngine: function (e) {
      return e ? 0 === e.search(yi + "google.([^/?]*)") ? "google" : 0 === e.search(yi + "bing.com") ? "bing" : 0 === e.search(yi + "yahoo.com") ? "yahoo" : 0 === e.search(yi + "duckduckgo.com") ? "duckduckgo" : null : null;
    },
    _searchInfoFromReferrer: function (e) {
      var t = Ei._searchEngine(e),
        i = "yahoo" != t ? "q" : "p",
        r = {};
      if (!G(t)) {
        r.$search_engine = t;
        var s = a ? St(a.referrer, i) : "";
        s.length && (r.ph_keyword = s);
      }
      return r;
    },
    searchInfo: function () {
      var e = null == a ? void 0 : a.referrer;
      return e ? this._searchInfoFromReferrer(e) : {};
    },
    browser: gi,
    browserVersion: function (e, t) {
      var i = gi(e, t),
        r = fi[i];
      if (z(r)) return null;
      for (var s = 0; s < r.length; s++) {
        var n = r[s],
          o = e.match(n);
        if (o) return parseFloat(o[o.length - 2]);
      }
      return null;
    },
    browserLanguage: function () {
      return navigator.language || navigator.userLanguage;
    },
    browserLanguagePrefix: function () {
      var e = this.browserLanguage();
      return "string" == typeof e ? e.split("-")[0] : void 0;
    },
    os: function (e) {
      for (var t = 0; t < mi.length; t++) {
        var [i, r] = mi[t],
          s = i.exec(e),
          n = s && (H(r) ? r(s, e) : r);
        if (n) return n;
      }
      return ["", ""];
    },
    device: bi,
    deviceType: function (e) {
      var t = bi(e);
      return t === Tt || t === Ft || "Kobo" === t || "Kindle Fire" === t || t === oi ? Pt : t === Yt || t === Xt || t === Kt || t === ri ? "Console" : t === $t ? "Wearable" : t ? xt : "Desktop";
    },
    referrer: function () {
      return (null == a ? void 0 : a.referrer) || "$direct";
    },
    referringDomain: function () {
      var e;
      return null != a && a.referrer && (null === (e = yt(a.referrer)) || void 0 === e ? void 0 : e.host) || "$direct";
    },
    referrerInfo: function () {
      return {
        $referrer: this.referrer(),
        $referring_domain: this.referringDomain()
      };
    },
    initialPersonInfo: function () {
      return {
        r: this.referrer().substring(0, 1e3),
        u: null == l ? void 0 : l.href.substring(0, 1e3)
      };
    },
    initialPersonPropsFromInfo: function (e) {
      var t,
        {
          r: i,
          u: r
        } = e,
        s = {
          $initial_referrer: i,
          $initial_referring_domain: null == i ? void 0 : "$direct" == i ? "$direct" : null === (t = yt(i)) || void 0 === t ? void 0 : t.host
        };
      if (r) {
        s.$initial_current_url = r;
        var n = yt(r);
        s.$initial_host = null == n ? void 0 : n.host, s.$initial_pathname = null == n ? void 0 : n.pathname, y(this._campaignParamsFromUrl(r), function (e, t) {
          s["$initial_" + M(t)] = e;
        });
      }
      i && y(this._searchInfoFromReferrer(i), function (e, t) {
        s["$initial_" + M(t)] = e;
      });
      return s;
    },
    timezone: function () {
      try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (e) {
        return;
      }
    },
    timezoneOffset: function () {
      try {
        return new Date().getTimezoneOffset();
      } catch (e) {
        return;
      }
    },
    properties: function () {
      var {
        maskPersonalDataProperties: e,
        customPersonalDataProperties: i
      } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
      if (!h) return {};
      var r = e ? S([], wi, i || []) : [],
        [s, n] = Ei.os(h);
      return w(I({
        $os: s,
        $os_version: n,
        $browser: Ei.browser(h, navigator.vendor),
        $device: Ei.device(h),
        $device_type: Ei.deviceType(h),
        $timezone: Ei.timezone(),
        $timezone_offset: Ei.timezoneOffset()
      }), {
        $current_url: kt(null == l ? void 0 : l.href, r, ki),
        $host: null == l ? void 0 : l.host,
        $pathname: null == l ? void 0 : l.pathname,
        $raw_user_agent: h.length > 1e3 ? h.substring(0, 997) + "..." : h,
        $browser_version: Ei.browserVersion(h, navigator.vendor),
        $browser_language: Ei.browserLanguage(),
        $browser_language_prefix: Ei.browserLanguagePrefix(),
        $screen_height: null == t ? void 0 : t.screen.height,
        $screen_width: null == t ? void 0 : t.screen.width,
        $viewport_height: null == t ? void 0 : t.innerHeight,
        $viewport_width: null == t ? void 0 : t.innerWidth,
        $lib: "web",
        $lib_version: p.LIB_VERSION,
        $insert_id: Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10),
        $time: Date.now() / 1e3
      });
    },
    people_properties: function () {
      if (!h) return {};
      var [e, t] = Ei.os(h);
      return w(I({
        $os: e,
        $os_version: t,
        $browser: Ei.browser(h, navigator.vendor)
      }), {
        $browser_version: Ei.browserVersion(h, navigator.vendor)
      });
    }
  },
  xi = ["cookie", "localstorage", "localstorage+cookie", "sessionstorage", "memory"];
class Ii {
  constructor(e) {
    this.config = e, this.props = {}, this.campaign_params_saved = !1, this.name = (e => {
      var t = "";
      return e.token && (t = e.token.replace(/\+/g, "PL").replace(/\//g, "SL").replace(/=/g, "EQ")), e.persistence_name ? "ph_" + e.persistence_name : "ph_" + t + "_posthog";
    })(e), this.storage = this.buildStorage(e), this.load(), e.debug && ee.info("Persistence loaded", e.persistence, ne({}, this.props)), this.update_config(e, e), this.save();
  }
  buildStorage(e) {
    -1 === xi.indexOf(e.persistence.toLowerCase()) && (ee.critical("Unknown persistence type " + e.persistence + "; falling back to localStorage+cookie"), e.persistence = "localStorage+cookie");
    var t = e.persistence.toLowerCase();
    return "localstorage" === t && ht.is_supported() ? ht : "localstorage+cookie" === t && pt.is_supported() ? pt : "sessionstorage" === t && mt.is_supported() ? mt : "memory" === t ? gt : "cookie" === t ? ct : pt.is_supported() ? pt : ct;
  }
  properties() {
    var e = {};
    return y(this.props, function (t, i) {
      if (i === Pe && U(t)) for (var r = Object.keys(t), s = 0; s < r.length; s++) e["$feature/".concat(r[s])] = t[r[s]];else a = i, l = !1, (G(o = ze) ? l : n && o.indexOf === n ? -1 != o.indexOf(a) : (y(o, function (e) {
        if (l || (l = e === a)) return m;
      }), l)) || (e[i] = t);
      var o, a, l;
    }), e;
  }
  load() {
    if (!this.disabled) {
      var e = this.storage.parse(this.name);
      e && (this.props = w({}, e));
    }
  }
  save() {
    this.disabled || this.storage.set(this.name, this.props, this.expire_days, this.cross_subdomain, this.secure, this.config.debug);
  }
  remove() {
    this.storage.remove(this.name, !1), this.storage.remove(this.name, !0);
  }
  clear() {
    this.remove(), this.props = {};
  }
  register_once(e, t, i) {
    if (U(e)) {
      z(t) && (t = "None"), this.expire_days = z(i) ? this.default_expiry : i;
      var r = !1;
      if (y(e, (e, i) => {
        this.props.hasOwnProperty(i) && this.props[i] !== t || (this.props[i] = e, r = !0);
      }), r) return this.save(), !0;
    }
    return !1;
  }
  register(e, t) {
    if (U(e)) {
      this.expire_days = z(t) ? this.default_expiry : t;
      var i = !1;
      if (y(e, (t, r) => {
        e.hasOwnProperty(r) && this.props[r] !== t && (this.props[r] = t, i = !0);
      }), i) return this.save(), !0;
    }
    return !1;
  }
  unregister(e) {
    e in this.props && (delete this.props[e], this.save());
  }
  update_campaign_params() {
    if (!this.campaign_params_saved) {
      var e = Ei.campaignParams({
        customTrackedParams: this.config.custom_campaign_params,
        maskPersonalDataProperties: this.config.mask_personal_data_properties,
        customPersonalDataProperties: this.config.custom_personal_data_properties
      });
      j(I(e)) || this.register(e), this.campaign_params_saved = !0;
    }
  }
  update_search_keyword() {
    this.register(Ei.searchInfo());
  }
  update_referrer_info() {
    this.register_once(Ei.referrerInfo(), void 0);
  }
  set_initial_person_info() {
    this.props[Ne] || this.props[qe] || this.register_once({
      [Be]: Ei.initialPersonInfo()
    }, void 0);
  }
  get_referrer_info() {
    return I({
      $referrer: this.props.$referrer,
      $referring_domain: this.props.$referring_domain
    });
  }
  get_initial_props() {
    var e = {};
    y([qe, Ne], t => {
      var i = this.props[t];
      i && y(i, function (t, i) {
        e["$initial_" + M(i)] = t;
      });
    });
    var t = this.props[Be];
    if (t) {
      var i = Ei.initialPersonPropsFromInfo(t);
      w(e, i);
    }
    return e;
  }
  safe_merge(e) {
    return y(this.props, function (t, i) {
      i in e || (e[i] = t);
    }), e;
  }
  update_config(e, t) {
    if (this.default_expiry = this.expire_days = e.cookie_expiration, this.set_disabled(e.disable_persistence), this.set_cross_subdomain(e.cross_subdomain_cookie), this.set_secure(e.secure_cookie), e.persistence !== t.persistence) {
      var i = this.buildStorage(e),
        r = this.props;
      this.clear(), this.storage = i, this.props = r, this.save();
    }
  }
  set_disabled(e) {
    this.disabled = e, this.disabled ? this.remove() : this.save();
  }
  set_cross_subdomain(e) {
    e !== this.cross_subdomain && (this.cross_subdomain = e, this.remove(), this.save());
  }
  get_cross_subdomain() {
    return !!this.cross_subdomain;
  }
  set_secure(e) {
    e !== this.secure && (this.secure = e, this.remove(), this.save());
  }
  set_event_timer(e, t) {
    var i = this.props[ce] || {};
    i[e] = t, this.props[ce] = i, this.save();
  }
  remove_event_timer(e) {
    var t = (this.props[ce] || {})[e];
    return z(t) || (delete this.props[ce][e], this.save()), t;
  }
  get_property(e) {
    return this.props[e];
  }
  set_property(e, t) {
    this.props[e] = t, this.save();
  }
}
function Ci(e) {
  var t, i;
  return (null === (t = JSON.stringify(e, (i = [], function (e, t) {
    if (U(t)) {
      for (; i.length > 0 && i[i.length - 1] !== this;) i.pop();
      return i.includes(t) ? "[Circular]" : (i.push(t), t);
    }
    return t;
  }))) || void 0 === t ? void 0 : t.length) || 0;
}
function Pi(e) {
  var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 6606028.8;
  if (e.size >= t && e.data.length > 1) {
    var i = Math.floor(e.data.length / 2),
      r = e.data.slice(0, i),
      s = e.data.slice(i);
    return [Pi({
      size: Ci(r),
      data: r,
      sessionId: e.sessionId,
      windowId: e.windowId
    }), Pi({
      size: Ci(s),
      data: s,
      sessionId: e.sessionId,
      windowId: e.windowId
    })].flatMap(e => e);
  }
  return [e];
}
var Fi = (e => (e[e.DomContentLoaded = 0] = "DomContentLoaded", e[e.Load = 1] = "Load", e[e.FullSnapshot = 2] = "FullSnapshot", e[e.IncrementalSnapshot = 3] = "IncrementalSnapshot", e[e.Meta = 4] = "Meta", e[e.Custom = 5] = "Custom", e[e.Plugin = 6] = "Plugin", e))(Fi || {}),
  Ti = (e => (e[e.Mutation = 0] = "Mutation", e[e.MouseMove = 1] = "MouseMove", e[e.MouseInteraction = 2] = "MouseInteraction", e[e.Scroll = 3] = "Scroll", e[e.ViewportResize = 4] = "ViewportResize", e[e.Input = 5] = "Input", e[e.TouchMove = 6] = "TouchMove", e[e.MediaInteraction = 7] = "MediaInteraction", e[e.StyleSheetRule = 8] = "StyleSheetRule", e[e.CanvasMutation = 9] = "CanvasMutation", e[e.Font = 10] = "Font", e[e.Log = 11] = "Log", e[e.Drag = 12] = "Drag", e[e.StyleDeclaration = 13] = "StyleDeclaration", e[e.Selection = 14] = "Selection", e[e.AdoptedStyleSheet = 15] = "AdoptedStyleSheet", e[e.CustomElement = 16] = "CustomElement", e))(Ti || {});
function Ri(e) {
  var t;
  return e instanceof Element && (e.id === Ue || !(null === (t = e.closest) || void 0 === t || !t.call(e, ".toolbar-global-fade-container")));
}
function $i(e) {
  return !!e && 1 === e.nodeType;
}
function Oi(e, t) {
  return !!e && !!e.tagName && e.tagName.toLowerCase() === t.toLowerCase();
}
function Mi(e) {
  return !!e && 3 === e.nodeType;
}
function Ai(e) {
  return !!e && 11 === e.nodeType;
}
function Li(e) {
  return e ? O(e).split(/\s+/) : [];
}
function Di(e) {
  var i = null == t ? void 0 : t.location.href;
  return !!(i && e && e.some(e => i.match(e)));
}
function Ni(e) {
  var t = "";
  switch (typeof e.className) {
    case "string":
      t = e.className;
      break;
    case "object":
      t = (e.className && "baseVal" in e.className ? e.className.baseVal : null) || e.getAttribute("class") || "";
      break;
    default:
      t = "";
  }
  return Li(t);
}
function qi(e) {
  return J(e) ? null : O(e).split(/(\s+)/).filter(e => Zi(e)).join("").replace(/[\r\n]/g, " ").replace(/[ ]+/g, " ").substring(0, 255);
}
function Bi(e) {
  var t = "";
  return Wi(e) && !Vi(e) && e.childNodes && e.childNodes.length && y(e.childNodes, function (e) {
    var i;
    Mi(e) && e.textContent && (t += null !== (i = qi(e.textContent)) && void 0 !== i ? i : "");
  }), O(t);
}
function Hi(e) {
  return z(e.target) ? e.srcElement || null : null !== (t = e.target) && void 0 !== t && t.shadowRoot ? e.composedPath()[0] || null : e.target || null;
  var t;
}
var Ui = ["a", "button", "form", "input", "select", "textarea", "label"];
function ji(e) {
  var t = e.parentNode;
  return !(!t || !$i(t)) && t;
}
function zi(e, i) {
  var r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0,
    s = arguments.length > 3 ? arguments[3] : void 0,
    n = arguments.length > 4 ? arguments[4] : void 0;
  if (!t || !e || Oi(e, "html") || !$i(e)) return !1;
  if (null != r && r.url_allowlist && !Di(r.url_allowlist)) return !1;
  if (null != r && r.url_ignorelist && Di(r.url_ignorelist)) return !1;
  if (null != r && r.dom_event_allowlist) {
    var o = r.dom_event_allowlist;
    if (o && !o.some(e => i.type === e)) return !1;
  }
  for (var a = !1, l = [e], u = !0, c = e; c.parentNode && !Oi(c, "body");) if (Ai(c.parentNode)) l.push(c.parentNode.host), c = c.parentNode.host;else {
    if (!(u = ji(c))) break;
    if (s || Ui.indexOf(u.tagName.toLowerCase()) > -1) a = !0;else {
      var d = t.getComputedStyle(u);
      d && "pointer" === d.getPropertyValue("cursor") && (a = !0);
    }
    l.push(u), c = u;
  }
  if (!function (e, t) {
    var i = null == t ? void 0 : t.element_allowlist;
    if (z(i)) return !0;
    var r = function (e) {
      if (i.some(t => e.tagName.toLowerCase() === t)) return {
        v: !0
      };
    };
    for (var s of e) {
      var n = r(s);
      if ("object" == typeof n) return n.v;
    }
    return !1;
  }(l, r)) return !1;
  if (!function (e, t) {
    var i = null == t ? void 0 : t.css_selector_allowlist;
    if (z(i)) return !0;
    var r = function (e) {
      if (i.some(t => e.matches(t))) return {
        v: !0
      };
    };
    for (var s of e) {
      var n = r(s);
      if ("object" == typeof n) return n.v;
    }
    return !1;
  }(l, r)) return !1;
  var h = t.getComputedStyle(e);
  if (h && "pointer" === h.getPropertyValue("cursor") && "click" === i.type) return !0;
  var _ = e.tagName.toLowerCase();
  switch (_) {
    case "html":
      return !1;
    case "form":
      return (n || ["submit"]).indexOf(i.type) >= 0;
    case "input":
    case "select":
    case "textarea":
      return (n || ["change", "click"]).indexOf(i.type) >= 0;
    default:
      return a ? (n || ["click"]).indexOf(i.type) >= 0 : (n || ["click"]).indexOf(i.type) >= 0 && (Ui.indexOf(_) > -1 || "true" === e.getAttribute("contenteditable"));
  }
}
function Wi(e) {
  for (var t = e; t.parentNode && !Oi(t, "body"); t = t.parentNode) {
    var i = Ni(t);
    if ($(i, "ph-sensitive") || $(i, "ph-no-capture")) return !1;
  }
  if ($(Ni(e), "ph-include")) return !0;
  var r = e.type || "";
  if (W(r)) switch (r.toLowerCase()) {
    case "hidden":
    case "password":
      return !1;
  }
  var s = e.name || e.id || "";
  if (W(s)) {
    if (/^cc|cardnum|ccnum|creditcard|csc|cvc|cvv|exp|pass|pwd|routing|seccode|securitycode|securitynum|socialsec|socsec|ssn/i.test(s.replace(/[^a-zA-Z0-9]/g, ""))) return !1;
  }
  return !0;
}
function Vi(e) {
  return !!(Oi(e, "input") && !["button", "checkbox", "submit", "reset"].includes(e.type) || Oi(e, "select") || Oi(e, "textarea") || "true" === e.getAttribute("contenteditable"));
}
var Gi = "(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11})",
  Ji = new RegExp("^(?:".concat(Gi, ")$")),
  Yi = new RegExp(Gi),
  Ki = "\\d{3}-?\\d{2}-?\\d{4}",
  Xi = new RegExp("^(".concat(Ki, ")$")),
  Qi = new RegExp("(".concat(Ki, ")"));
function Zi(e) {
  var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
  if (J(e)) return !1;
  if (W(e)) {
    if (e = O(e), (t ? Ji : Yi).test((e || "").replace(/[- ]/g, ""))) return !1;
    if ((t ? Xi : Qi).test(e)) return !1;
  }
  return !0;
}
function er(e) {
  var t = Bi(e);
  return Zi(t = "".concat(t, " ").concat(tr(e)).trim()) ? t : "";
}
function tr(e) {
  var t = "";
  return e && e.childNodes && e.childNodes.length && y(e.childNodes, function (e) {
    var i;
    if (e && "span" === (null === (i = e.tagName) || void 0 === i ? void 0 : i.toLowerCase())) try {
      var r = Bi(e);
      t = "".concat(t, " ").concat(r).trim(), e.childNodes && e.childNodes.length && (t = "".concat(t, " ").concat(tr(e)).trim());
    } catch (e) {
      ee.error("[AutoCapture]", e);
    }
  }), t;
}
function ir(e) {
  return function (e) {
    var t = e.map(e => {
      var t,
        i,
        r = "";
      if (e.tag_name && (r += e.tag_name), e.attr_class) for (var s of (e.attr_class.sort(), e.attr_class)) r += ".".concat(s.replace(/"/g, ""));
      var n = ne(ne(ne(ne({}, e.text ? {
          text: e.text
        } : {}), {}, {
          "nth-child": null !== (t = e.nth_child) && void 0 !== t ? t : 0,
          "nth-of-type": null !== (i = e.nth_of_type) && void 0 !== i ? i : 0
        }, e.href ? {
          href: e.href
        } : {}), e.attr_id ? {
          attr_id: e.attr_id
        } : {}), e.attributes),
        o = {};
      return k(n).sort((e, t) => {
        var [i] = e,
          [r] = t;
        return i.localeCompare(r);
      }).forEach(e => {
        var [t, i] = e;
        return o[rr(t.toString())] = rr(i.toString());
      }), r += ":", r += k(n).map(e => {
        var [t, i] = e;
        return "".concat(t, '="').concat(i, '"');
      }).join("");
    });
    return t.join(";");
  }(function (e) {
    return e.map(e => {
      var t,
        i,
        r = {
          text: null === (t = e.$el_text) || void 0 === t ? void 0 : t.slice(0, 400),
          tag_name: e.tag_name,
          href: null === (i = e.attr__href) || void 0 === i ? void 0 : i.slice(0, 2048),
          attr_class: sr(e),
          attr_id: e.attr__id,
          nth_child: e.nth_child,
          nth_of_type: e.nth_of_type,
          attributes: {}
        };
      return k(e).filter(e => {
        var [t] = e;
        return 0 === t.indexOf("attr__");
      }).forEach(e => {
        var [t, i] = e;
        return r.attributes[t] = i;
      }), r;
    });
  }(e));
}
function rr(e) {
  return e.replace(/"|\\"/g, '\\"');
}
function sr(e) {
  var t = e.attr__class;
  return t ? B(t) ? t : Li(t) : void 0;
}
var nr = "[SessionRecording]",
  or = "redacted",
  ar = {
    initiatorTypes: ["audio", "beacon", "body", "css", "early-hint", "embed", "fetch", "frame", "iframe", "icon", "image", "img", "input", "link", "navigation", "object", "ping", "script", "track", "video", "xmlhttprequest"],
    maskRequestFn: e => e,
    recordHeaders: !1,
    recordBody: !1,
    recordInitialRequests: !1,
    recordPerformance: !1,
    performanceEntryTypeToObserve: ["first-input", "navigation", "paint", "resource"],
    payloadSizeLimitBytes: 1e6,
    payloadHostDenyList: [".lr-ingest.io", ".ingest.sentry.io", ".clarity.ms", "analytics.google.com"]
  },
  lr = ["authorization", "x-forwarded-for", "authorization", "cookie", "set-cookie", "x-api-key", "x-real-ip", "remote-addr", "forwarded", "proxy-authorization", "x-csrf-token", "x-csrftoken", "x-xsrf-token"],
  ur = ["password", "secret", "passwd", "api_key", "apikey", "auth", "credentials", "mysql_pwd", "privatekey", "private_key", "token"],
  cr = ["/s/", "/e/", "/i/"];
function dr(e, t, i, r) {
  if (J(e)) return e;
  var s = (null == t ? void 0 : t["content-length"]) || function (e) {
    return new Blob([e]).size;
  }(e);
  return W(s) && (s = parseInt(s)), s > i ? nr + " ".concat(r, " body too large to record (").concat(s, " bytes)") : e;
}
function hr(e, t) {
  if (J(e)) return e;
  var i = e;
  return Zi(i, !1) || (i = nr + " " + t + " body " + or), y(ur, e => {
    var r, s;
    null !== (r = i) && void 0 !== r && r.length && -1 !== (null === (s = i) || void 0 === s ? void 0 : s.indexOf(e)) && (i = nr + " " + t + " body " + or + " as might contain: " + e);
  }), i;
}
var _r = (e, t) => {
  var i,
    r,
    s,
    n = {
      payloadSizeLimitBytes: ar.payloadSizeLimitBytes,
      performanceEntryTypeToObserve: [...ar.performanceEntryTypeToObserve],
      payloadHostDenyList: [...(t.payloadHostDenyList || []), ...ar.payloadHostDenyList]
    },
    o = !1 !== e.session_recording.recordHeaders && t.recordHeaders,
    a = !1 !== e.session_recording.recordBody && t.recordBody,
    l = !1 !== e.capture_performance && t.recordPerformance,
    u = (i = n, s = Math.min(1e6, null !== (r = i.payloadSizeLimitBytes) && void 0 !== r ? r : 1e6), e => (null != e && e.requestBody && (e.requestBody = dr(e.requestBody, e.requestHeaders, s, "Request")), null != e && e.responseBody && (e.responseBody = dr(e.responseBody, e.responseHeaders, s, "Response")), e)),
    c = t => {
      return u(((e, t) => {
        var i,
          r = yt(e.name),
          s = 0 === t.indexOf("http") ? null === (i = yt(t)) || void 0 === i ? void 0 : i.pathname : t;
        "/" === s && (s = "");
        var n = null == r ? void 0 : r.pathname.replace(s || "", "");
        if (!(r && n && cr.some(e => 0 === n.indexOf(e)))) return e;
      })((r = (i = t).requestHeaders, J(r) || y(Object.keys(null != r ? r : {}), e => {
        lr.includes(e.toLowerCase()) && (r[e] = or);
      }), i), e.api_host));
      var i, r;
    },
    d = H(e.session_recording.maskNetworkRequestFn);
  return d && H(e.session_recording.maskCapturedNetworkRequestFn) && ee.warn("Both `maskNetworkRequestFn` and `maskCapturedNetworkRequestFn` are defined. `maskNetworkRequestFn` will be ignored."), d && (e.session_recording.maskCapturedNetworkRequestFn = t => {
    var i = e.session_recording.maskNetworkRequestFn({
      url: t.name
    });
    return ne(ne({}, t), {}, {
      name: null == i ? void 0 : i.url
    });
  }), n.maskRequestFn = H(e.session_recording.maskCapturedNetworkRequestFn) ? t => {
    var i,
      r,
      s,
      n = c(t);
    return n && null !== (i = null === (r = (s = e.session_recording).maskCapturedNetworkRequestFn) || void 0 === r ? void 0 : r.call(s, n)) && void 0 !== i ? i : void 0;
  } : e => function (e) {
    if (!z(e)) return e.requestBody = hr(e.requestBody, "Request"), e.responseBody = hr(e.responseBody, "Response"), e;
  }(c(e)), ne(ne(ne({}, ar), n), {}, {
    recordHeaders: o,
    recordBody: a,
    recordPerformance: l,
    recordInitialRequests: l
  });
};
function pr(e, t, i, r, s) {
  return t > i && (ee.warn("min cannot be greater than max."), t = i), Y(e) ? e > i ? (r && ee.warn(r + " cannot be  greater than max: " + i + ". Using max value instead."), i) : e < t ? (r && ee.warn(r + " cannot be less than min: " + t + ". Using min value instead."), t) : e : (r && ee.warn(r + " must be a number. using max or fallback. max: " + i + ", fallback: " + s), pr(s || i, t, i, r));
}
class vr {
  constructor(e) {
    var t,
      i,
      r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    oe(this, "bucketSize", 100), oe(this, "refillRate", 10), oe(this, "mutationBuckets", {}), oe(this, "loggedTracker", {}), oe(this, "refillBuckets", () => {
      Object.keys(this.mutationBuckets).forEach(e => {
        this.mutationBuckets[e] = this.mutationBuckets[e] + this.refillRate, this.mutationBuckets[e] >= this.bucketSize && delete this.mutationBuckets[e];
      });
    }), oe(this, "getNodeOrRelevantParent", e => {
      var t = this.rrweb.mirror.getNode(e);
      if ("svg" !== (null == t ? void 0 : t.nodeName) && t instanceof Element) {
        var i = t.closest("svg");
        if (i) return [this.rrweb.mirror.getId(i), i];
      }
      return [e, t];
    }), oe(this, "numberOfChanges", e => {
      var t, i, r, s, n, o, a, l;
      return (null !== (t = null === (i = e.removes) || void 0 === i ? void 0 : i.length) && void 0 !== t ? t : 0) + (null !== (r = null === (s = e.attributes) || void 0 === s ? void 0 : s.length) && void 0 !== r ? r : 0) + (null !== (n = null === (o = e.texts) || void 0 === o ? void 0 : o.length) && void 0 !== n ? n : 0) + (null !== (a = null === (l = e.adds) || void 0 === l ? void 0 : l.length) && void 0 !== a ? a : 0);
    }), oe(this, "throttleMutations", e => {
      if (3 !== e.type || 0 !== e.data.source) return e;
      var t = e.data,
        i = this.numberOfChanges(t);
      t.attributes && (t.attributes = t.attributes.filter(e => {
        var t,
          i,
          r,
          [s, n] = this.getNodeOrRelevantParent(e.id);
        if (0 === this.mutationBuckets[s]) return !1;
        (this.mutationBuckets[s] = null !== (t = this.mutationBuckets[s]) && void 0 !== t ? t : this.bucketSize, this.mutationBuckets[s] = Math.max(this.mutationBuckets[s] - 1, 0), 0 === this.mutationBuckets[s]) && (this.loggedTracker[s] || (this.loggedTracker[s] = !0, null === (i = (r = this.options).onBlockedNode) || void 0 === i || i.call(r, s, n)));
        return e;
      }));
      var r = this.numberOfChanges(t);
      return 0 !== r || i === r ? e : void 0;
    }), this.rrweb = e, this.options = r, this.refillRate = pr(null !== (t = this.options.refillRate) && void 0 !== t ? t : this.refillRate, 0, 100, "mutation throttling refill rate"), this.bucketSize = pr(null !== (i = this.options.bucketSize) && void 0 !== i ? i : this.bucketSize, 0, 100, "mutation throttling bucket size"), setInterval(() => {
      this.refillBuckets();
    }, 1e3);
  }
}
var gr = Uint8Array,
  fr = Uint16Array,
  mr = Uint32Array,
  br = new gr([0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 0, 0, 0, 0]),
  yr = new gr([0, 0, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12, 13, 13, 0, 0]),
  wr = new gr([16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15]),
  Sr = function (e, t) {
    for (var i = new fr(31), r = 0; r < 31; ++r) i[r] = t += 1 << e[r - 1];
    var s = new mr(i[30]);
    for (r = 1; r < 30; ++r) for (var n = i[r]; n < i[r + 1]; ++n) s[n] = n - i[r] << 5 | r;
    return [i, s];
  },
  kr = Sr(br, 2),
  Er = kr[0],
  xr = kr[1];
Er[28] = 258, xr[258] = 28;
for (var Ir = Sr(yr, 0)[1], Cr = new fr(32768), Pr = 0; Pr < 32768; ++Pr) {
  var Fr = (43690 & Pr) >>> 1 | (21845 & Pr) << 1;
  Fr = (61680 & (Fr = (52428 & Fr) >>> 2 | (13107 & Fr) << 2)) >>> 4 | (3855 & Fr) << 4, Cr[Pr] = ((65280 & Fr) >>> 8 | (255 & Fr) << 8) >>> 1;
}
var Tr = function (e, t, i) {
    for (var r = e.length, s = 0, n = new fr(t); s < r; ++s) ++n[e[s] - 1];
    var o,
      a = new fr(t);
    for (s = 0; s < t; ++s) a[s] = a[s - 1] + n[s - 1] << 1;
    if (i) {
      o = new fr(1 << t);
      var l = 15 - t;
      for (s = 0; s < r; ++s) if (e[s]) for (var u = s << 4 | e[s], c = t - e[s], d = a[e[s] - 1]++ << c, h = d | (1 << c) - 1; d <= h; ++d) o[Cr[d] >>> l] = u;
    } else for (o = new fr(r), s = 0; s < r; ++s) o[s] = Cr[a[e[s] - 1]++] >>> 15 - e[s];
    return o;
  },
  Rr = new gr(288);
for (Pr = 0; Pr < 144; ++Pr) Rr[Pr] = 8;
for (Pr = 144; Pr < 256; ++Pr) Rr[Pr] = 9;
for (Pr = 256; Pr < 280; ++Pr) Rr[Pr] = 7;
for (Pr = 280; Pr < 288; ++Pr) Rr[Pr] = 8;
var $r = new gr(32);
for (Pr = 0; Pr < 32; ++Pr) $r[Pr] = 5;
var Or = Tr(Rr, 9, 0),
  Mr = Tr($r, 5, 0),
  Ar = function (e) {
    return (e / 8 >> 0) + (7 & e && 1);
  },
  Lr = function (e, t, i) {
    (null == i || i > e.length) && (i = e.length);
    var r = new (e instanceof fr ? fr : e instanceof mr ? mr : gr)(i - t);
    return r.set(e.subarray(t, i)), r;
  },
  Dr = function (e, t, i) {
    i <<= 7 & t;
    var r = t / 8 >> 0;
    e[r] |= i, e[r + 1] |= i >>> 8;
  },
  Nr = function (e, t, i) {
    i <<= 7 & t;
    var r = t / 8 >> 0;
    e[r] |= i, e[r + 1] |= i >>> 8, e[r + 2] |= i >>> 16;
  },
  qr = function (e, t) {
    for (var i = [], r = 0; r < e.length; ++r) e[r] && i.push({
      s: r,
      f: e[r]
    });
    var s = i.length,
      n = i.slice();
    if (!s) return [new gr(0), 0];
    if (1 == s) {
      var o = new gr(i[0].s + 1);
      return o[i[0].s] = 1, [o, 1];
    }
    i.sort(function (e, t) {
      return e.f - t.f;
    }), i.push({
      s: -1,
      f: 25001
    });
    var a = i[0],
      l = i[1],
      u = 0,
      c = 1,
      d = 2;
    for (i[0] = {
      s: -1,
      f: a.f + l.f,
      l: a,
      r: l
    }; c != s - 1;) a = i[i[u].f < i[d].f ? u++ : d++], l = i[u != c && i[u].f < i[d].f ? u++ : d++], i[c++] = {
      s: -1,
      f: a.f + l.f,
      l: a,
      r: l
    };
    var h = n[0].s;
    for (r = 1; r < s; ++r) n[r].s > h && (h = n[r].s);
    var _ = new fr(h + 1),
      p = Br(i[c - 1], _, 0);
    if (p > t) {
      r = 0;
      var v = 0,
        g = p - t,
        f = 1 << g;
      for (n.sort(function (e, t) {
        return _[t.s] - _[e.s] || e.f - t.f;
      }); r < s; ++r) {
        var m = n[r].s;
        if (!(_[m] > t)) break;
        v += f - (1 << p - _[m]), _[m] = t;
      }
      for (v >>>= g; v > 0;) {
        var b = n[r].s;
        _[b] < t ? v -= 1 << t - _[b]++ - 1 : ++r;
      }
      for (; r >= 0 && v; --r) {
        var y = n[r].s;
        _[y] == t && (--_[y], ++v);
      }
      p = t;
    }
    return [new gr(_), p];
  },
  Br = function (e, t, i) {
    return -1 == e.s ? Math.max(Br(e.l, t, i + 1), Br(e.r, t, i + 1)) : t[e.s] = i;
  },
  Hr = function (e) {
    for (var t = e.length; t && !e[--t];);
    for (var i = new fr(++t), r = 0, s = e[0], n = 1, o = function (e) {
        i[r++] = e;
      }, a = 1; a <= t; ++a) if (e[a] == s && a != t) ++n;else {
      if (!s && n > 2) {
        for (; n > 138; n -= 138) o(32754);
        n > 2 && (o(n > 10 ? n - 11 << 5 | 28690 : n - 3 << 5 | 12305), n = 0);
      } else if (n > 3) {
        for (o(s), --n; n > 6; n -= 6) o(8304);
        n > 2 && (o(n - 3 << 5 | 8208), n = 0);
      }
      for (; n--;) o(s);
      n = 1, s = e[a];
    }
    return [i.subarray(0, r), t];
  },
  Ur = function (e, t) {
    for (var i = 0, r = 0; r < t.length; ++r) i += e[r] * t[r];
    return i;
  },
  jr = function (e, t, i) {
    var r = i.length,
      s = Ar(t + 2);
    e[s] = 255 & r, e[s + 1] = r >>> 8, e[s + 2] = 255 ^ e[s], e[s + 3] = 255 ^ e[s + 1];
    for (var n = 0; n < r; ++n) e[s + n + 4] = i[n];
    return 8 * (s + 4 + r);
  },
  zr = function (e, t, i, r, s, n, o, a, l, u, c) {
    Dr(t, c++, i), ++s[256];
    for (var d = qr(s, 15), h = d[0], _ = d[1], p = qr(n, 15), v = p[0], g = p[1], f = Hr(h), m = f[0], b = f[1], y = Hr(v), w = y[0], S = y[1], k = new fr(19), E = 0; E < m.length; ++E) k[31 & m[E]]++;
    for (E = 0; E < w.length; ++E) k[31 & w[E]]++;
    for (var x = qr(k, 7), I = x[0], C = x[1], P = 19; P > 4 && !I[wr[P - 1]]; --P);
    var F,
      T,
      R,
      $,
      O = u + 5 << 3,
      M = Ur(s, Rr) + Ur(n, $r) + o,
      A = Ur(s, h) + Ur(n, v) + o + 14 + 3 * P + Ur(k, I) + (2 * k[16] + 3 * k[17] + 7 * k[18]);
    if (O <= M && O <= A) return jr(t, c, e.subarray(l, l + u));
    if (Dr(t, c, 1 + (A < M)), c += 2, A < M) {
      F = Tr(h, _, 0), T = h, R = Tr(v, g, 0), $ = v;
      var L = Tr(I, C, 0);
      Dr(t, c, b - 257), Dr(t, c + 5, S - 1), Dr(t, c + 10, P - 4), c += 14;
      for (E = 0; E < P; ++E) Dr(t, c + 3 * E, I[wr[E]]);
      c += 3 * P;
      for (var D = [m, w], N = 0; N < 2; ++N) {
        var q = D[N];
        for (E = 0; E < q.length; ++E) {
          var B = 31 & q[E];
          Dr(t, c, L[B]), c += I[B], B > 15 && (Dr(t, c, q[E] >>> 5 & 127), c += q[E] >>> 12);
        }
      }
    } else F = Or, T = Rr, R = Mr, $ = $r;
    for (E = 0; E < a; ++E) if (r[E] > 255) {
      B = r[E] >>> 18 & 31;
      Nr(t, c, F[B + 257]), c += T[B + 257], B > 7 && (Dr(t, c, r[E] >>> 23 & 31), c += br[B]);
      var H = 31 & r[E];
      Nr(t, c, R[H]), c += $[H], H > 3 && (Nr(t, c, r[E] >>> 5 & 8191), c += yr[H]);
    } else Nr(t, c, F[r[E]]), c += T[r[E]];
    return Nr(t, c, F[256]), c + T[256];
  },
  Wr = new mr([65540, 131080, 131088, 131104, 262176, 1048704, 1048832, 2114560, 2117632]),
  Vr = function () {
    for (var e = new mr(256), t = 0; t < 256; ++t) {
      for (var i = t, r = 9; --r;) i = (1 & i && 3988292384) ^ i >>> 1;
      e[t] = i;
    }
    return e;
  }(),
  Gr = function () {
    var e = 4294967295;
    return {
      p: function (t) {
        for (var i = e, r = 0; r < t.length; ++r) i = Vr[255 & i ^ t[r]] ^ i >>> 8;
        e = i;
      },
      d: function () {
        return 4294967295 ^ e;
      }
    };
  },
  Jr = function (e, t, i, r, s) {
    return function (e, t, i, r, s, n) {
      var o = e.length,
        a = new gr(r + o + 5 * (1 + Math.floor(o / 7e3)) + s),
        l = a.subarray(r, a.length - s),
        u = 0;
      if (!t || o < 8) for (var c = 0; c <= o; c += 65535) {
        var d = c + 65535;
        d < o ? u = jr(l, u, e.subarray(c, d)) : (l[c] = n, u = jr(l, u, e.subarray(c, o)));
      } else {
        for (var h = Wr[t - 1], _ = h >>> 13, p = 8191 & h, v = (1 << i) - 1, g = new fr(32768), f = new fr(v + 1), m = Math.ceil(i / 3), b = 2 * m, y = function (t) {
            return (e[t] ^ e[t + 1] << m ^ e[t + 2] << b) & v;
          }, w = new mr(25e3), S = new fr(288), k = new fr(32), E = 0, x = 0, I = (c = 0, 0), C = 0, P = 0; c < o; ++c) {
          var F = y(c),
            T = 32767 & c,
            R = f[F];
          if (g[T] = R, f[F] = T, C <= c) {
            var $ = o - c;
            if ((E > 7e3 || I > 24576) && $ > 423) {
              u = zr(e, l, 0, w, S, k, x, I, P, c - P, u), I = E = x = 0, P = c;
              for (var O = 0; O < 286; ++O) S[O] = 0;
              for (O = 0; O < 30; ++O) k[O] = 0;
            }
            var M = 2,
              A = 0,
              L = p,
              D = T - R & 32767;
            if ($ > 2 && F == y(c - D)) for (var N = Math.min(_, $) - 1, q = Math.min(32767, c), B = Math.min(258, $); D <= q && --L && T != R;) {
              if (e[c + M] == e[c + M - D]) {
                for (var H = 0; H < B && e[c + H] == e[c + H - D]; ++H);
                if (H > M) {
                  if (M = H, A = D, H > N) break;
                  var U = Math.min(D, H - 2),
                    j = 0;
                  for (O = 0; O < U; ++O) {
                    var z = c - D + O + 32768 & 32767,
                      W = z - g[z] + 32768 & 32767;
                    W > j && (j = W, R = z);
                  }
                }
              }
              D += (T = R) - (R = g[T]) + 32768 & 32767;
            }
            if (A) {
              w[I++] = 268435456 | xr[M] << 18 | Ir[A];
              var V = 31 & xr[M],
                G = 31 & Ir[A];
              x += br[V] + yr[G], ++S[257 + V], ++k[G], C = c + M, ++E;
            } else w[I++] = e[c], ++S[e[c]];
          }
        }
        u = zr(e, l, n, w, S, k, x, I, P, c - P, u);
      }
      return Lr(a, 0, r + Ar(u) + s);
    }(e, null == t.level ? 6 : t.level, null == t.mem ? Math.ceil(1.5 * Math.max(8, Math.min(13, Math.log(e.length)))) : 12 + t.mem, i, r, !s);
  },
  Yr = function (e, t, i) {
    for (; i; ++t) e[t] = i, i >>>= 8;
  },
  Kr = function (e, t) {
    var i = t.filename;
    if (e[0] = 31, e[1] = 139, e[2] = 8, e[8] = t.level < 2 ? 4 : 9 == t.level ? 2 : 0, e[9] = 3, 0 != t.mtime && Yr(e, 4, Math.floor(new Date(t.mtime || Date.now()) / 1e3)), i) {
      e[3] = 8;
      for (var r = 0; r <= i.length; ++r) e[r + 10] = i.charCodeAt(r);
    }
  },
  Xr = function (e) {
    return 10 + (e.filename && e.filename.length + 1 || 0);
  };
function Qr(e, t) {
  void 0 === t && (t = {});
  var i = Gr(),
    r = e.length;
  i.p(e);
  var s = Jr(e, t, Xr(t), 8),
    n = s.length;
  return Kr(s, t), Yr(s, n - 8, i.d()), Yr(s, n - 4, r), s;
}
function Zr(e, t) {
  var i = e.length;
  if ("undefined" != typeof TextEncoder) return new TextEncoder().encode(e);
  for (var r = new gr(e.length + (e.length >>> 1)), s = 0, n = function (e) {
      r[s++] = e;
    }, o = 0; o < i; ++o) {
    if (s + 5 > r.length) {
      var a = new gr(s + 8 + (i - o << 1));
      a.set(r), r = a;
    }
    var l = e.charCodeAt(o);
    l < 128 || t ? n(l) : l < 2048 ? (n(192 | l >>> 6), n(128 | 63 & l)) : l > 55295 && l < 57344 ? (n(240 | (l = 65536 + (1047552 & l) | 1023 & e.charCodeAt(++o)) >>> 18), n(128 | l >>> 12 & 63), n(128 | l >>> 6 & 63), n(128 | 63 & l)) : (n(224 | l >>> 12), n(128 | l >>> 6 & 63), n(128 | 63 & l));
  }
  return Lr(r, 0, s);
}
function es(e, t) {
  return function (e) {
    for (var t = 0, i = 0; i < e.length; i++) t = (t << 5) - t + e.charCodeAt(i), t |= 0;
    return Math.abs(t);
  }(e) % 100 < pr(100 * t, 0, 100);
}
var ts = "[SessionRecording]",
  is = te(ts),
  rs = 3e5,
  ss = [Ti.MouseMove, Ti.MouseInteraction, Ti.Scroll, Ti.ViewportResize, Ti.Input, Ti.TouchMove, Ti.MediaInteraction, Ti.Drag],
  ns = e => ({
    rrwebMethod: e,
    enqueuedAt: Date.now(),
    attempt: 1
  });
function os(e) {
  return function (e, t) {
    for (var i = "", r = 0; r < e.length;) {
      var s = e[r++];
      s < 128 || t ? i += String.fromCharCode(s) : s < 224 ? i += String.fromCharCode((31 & s) << 6 | 63 & e[r++]) : s < 240 ? i += String.fromCharCode((15 & s) << 12 | (63 & e[r++]) << 6 | 63 & e[r++]) : (s = ((15 & s) << 18 | (63 & e[r++]) << 12 | (63 & e[r++]) << 6 | 63 & e[r++]) - 65536, i += String.fromCharCode(55296 | s >> 10, 56320 | 1023 & s));
    }
    return i;
  }(Qr(Zr(JSON.stringify(e))), !0);
}
function as(e) {
  return e.type === Fi.Custom && "sessionIdle" === e.data.tag;
}
function ls(e, t) {
  return t.some(t => "regex" === t.matching && new RegExp(t.url).test(e));
}
class us {
  get sessionIdleThresholdMilliseconds() {
    return this.instance.config.session_recording.session_idle_threshold_ms || 3e5;
  }
  get rrwebRecord() {
    var e, t;
    return null == _ || null === (e = _.__PosthogExtensions__) || void 0 === e || null === (t = e.rrweb) || void 0 === t ? void 0 : t.record;
  }
  get started() {
    return this._captureStarted;
  }
  get sessionManager() {
    if (!this.instance.sessionManager) throw new Error(ts + " must be started with a valid sessionManager.");
    return this.instance.sessionManager;
  }
  get fullSnapshotIntervalMillis() {
    var e, t;
    return "trigger_pending" === this.triggerStatus ? 6e4 : null !== (e = null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.full_snapshot_interval_millis) && void 0 !== e ? e : rs;
  }
  get isSampled() {
    var e = this.instance.get_property(xe);
    return K(e) ? e : null;
  }
  get sessionDuration() {
    var e,
      t,
      i = null === (e = this.buffer) || void 0 === e ? void 0 : e.data[(null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) - 1],
      {
        sessionStartTimestamp: r
      } = this.sessionManager.checkAndGetSessionAndWindowId(!0);
    return i ? i.timestamp - r : null;
  }
  get isRecordingEnabled() {
    var e = !!this.instance.get_property(fe),
      i = !this.instance.config.disable_session_recording;
    return t && e && i;
  }
  get isConsoleLogCaptureEnabled() {
    var e = !!this.instance.get_property(me),
      t = this.instance.config.enable_recording_console_log;
    return null != t ? t : e;
  }
  get canvasRecording() {
    var e,
      t,
      i,
      r,
      s,
      n,
      o = this.instance.config.session_recording.captureCanvas,
      a = this.instance.get_property(ye),
      l = null !== (e = null !== (t = null == o ? void 0 : o.recordCanvas) && void 0 !== t ? t : null == a ? void 0 : a.enabled) && void 0 !== e && e,
      u = null !== (i = null !== (r = null == o ? void 0 : o.canvasFps) && void 0 !== r ? r : null == a ? void 0 : a.fps) && void 0 !== i ? i : 0,
      c = null !== (s = null !== (n = null == o ? void 0 : o.canvasQuality) && void 0 !== n ? n : null == a ? void 0 : a.quality) && void 0 !== s ? s : 0;
    return {
      enabled: l,
      fps: pr(u, 0, 12, "canvas recording fps"),
      quality: pr(c, 0, 1, "canvas recording quality")
    };
  }
  get networkPayloadCapture() {
    var e,
      t,
      i = this.instance.get_property(be),
      r = {
        recordHeaders: null === (e = this.instance.config.session_recording) || void 0 === e ? void 0 : e.recordHeaders,
        recordBody: null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.recordBody
      },
      s = (null == r ? void 0 : r.recordHeaders) || (null == i ? void 0 : i.recordHeaders),
      n = (null == r ? void 0 : r.recordBody) || (null == i ? void 0 : i.recordBody),
      o = U(this.instance.config.capture_performance) ? this.instance.config.capture_performance.network_timing : this.instance.config.capture_performance,
      a = !!(K(o) ? o : null == i ? void 0 : i.capturePerformance);
    return s || n || a ? {
      recordHeaders: s,
      recordBody: n,
      recordPerformance: a
    } : void 0;
  }
  get sampleRate() {
    var e = this.instance.get_property(we);
    return Y(e) ? e : null;
  }
  get minimumDuration() {
    var e = this.instance.get_property(Se);
    return Y(e) ? e : null;
  }
  get status() {
    return this.receivedDecide ? this.isRecordingEnabled ? !1 === this.isSampled ? "disabled" : this._urlBlocked ? "paused" : J(this._linkedFlag) || this._linkedFlagSeen ? "trigger_pending" === this.triggerStatus ? "buffering" : K(this.isSampled) ? this.isSampled ? "sampled" : "disabled" : "active" : "buffering" : "disabled" : "buffering";
  }
  get urlTriggerStatus() {
    var e;
    return 0 === this._urlTriggers.length ? "trigger_disabled" : (null === (e = this.instance) || void 0 === e ? void 0 : e.get_property(Ie)) === this.sessionId ? "trigger_activated" : "trigger_pending";
  }
  get eventTriggerStatus() {
    var e;
    return 0 === this._eventTriggers.length ? "trigger_disabled" : (null === (e = this.instance) || void 0 === e ? void 0 : e.get_property(Ce)) === this.sessionId ? "trigger_activated" : "trigger_pending";
  }
  get triggerStatus() {
    var e = "trigger_activated" === this.eventTriggerStatus || "trigger_activated" === this.urlTriggerStatus,
      t = "trigger_pending" === this.eventTriggerStatus || "trigger_pending" === this.urlTriggerStatus;
    return e ? "trigger_activated" : t ? "trigger_pending" : "trigger_disabled";
  }
  constructor(e) {
    if (oe(this, "queuedRRWebEvents", []), oe(this, "isIdle", !1), oe(this, "_linkedFlagSeen", !1), oe(this, "_lastActivityTimestamp", Date.now()), oe(this, "_linkedFlag", null), oe(this, "_removePageViewCaptureHook", void 0), oe(this, "_onSessionIdListener", void 0), oe(this, "_persistDecideOnSessionListener", void 0), oe(this, "_samplingSessionListener", void 0), oe(this, "_urlTriggers", []), oe(this, "_urlBlocklist", []), oe(this, "_urlBlocked", !1), oe(this, "_eventTriggers", []), oe(this, "_removeEventTriggerCaptureHook", void 0), oe(this, "_forceAllowLocalhostNetworkCapture", !1), oe(this, "_onBeforeUnload", () => {
      this._flushBuffer();
    }), oe(this, "_onOffline", () => {
      this._tryAddCustomEvent("browser offline", {});
    }), oe(this, "_onOnline", () => {
      this._tryAddCustomEvent("browser online", {});
    }), oe(this, "_onVisibilityChange", () => {
      if (null != a && a.visibilityState) {
        var e = "window " + a.visibilityState;
        this._tryAddCustomEvent(e, {});
      }
    }), this.instance = e, this._captureStarted = !1, this._endpoint = "/s/", this.stopRrweb = void 0, this.receivedDecide = !1, !this.instance.sessionManager) throw is.error("started without valid sessionManager"), new Error(ts + " started without valid sessionManager. This is a bug.");
    if (this.instance.config.__preview_experimental_cookieless_mode) throw new Error(ts + " cannot be used with __preview_experimental_cookieless_mode.");
    var {
      sessionId: t,
      windowId: i
    } = this.sessionManager.checkAndGetSessionAndWindowId();
    this.sessionId = t, this.windowId = i, this.buffer = this.clearBuffer(), this.sessionIdleThresholdMilliseconds >= this.sessionManager.sessionTimeoutMs && is.warn("session_idle_threshold_ms (".concat(this.sessionIdleThresholdMilliseconds, ") is greater than the session timeout (").concat(this.sessionManager.sessionTimeoutMs, "). Session will never be detected as idle"));
  }
  startIfEnabledOrStop(e) {
    this.isRecordingEnabled ? (this._startCapture(e), R(t, "beforeunload", this._onBeforeUnload), R(t, "offline", this._onOffline), R(t, "online", this._onOnline), R(t, "visibilitychange", this._onVisibilityChange), this._setupSampling(), this._addEventTriggerListener(), J(this._removePageViewCaptureHook) && (this._removePageViewCaptureHook = this.instance.on("eventCaptured", e => {
      try {
        if ("$pageview" === e.event) {
          var t = null != e && e.properties.$current_url ? this._maskUrl(null == e ? void 0 : e.properties.$current_url) : "";
          if (!t) return;
          this._tryAddCustomEvent("$pageview", {
            href: t
          });
        }
      } catch (e) {
        is.error("Could not add $pageview to rrweb session", e);
      }
    })), this._onSessionIdListener || (this._onSessionIdListener = this.sessionManager.onSessionId((e, t, i) => {
      var r, s, n, o;
      i && (this._tryAddCustomEvent("$session_id_change", {
        sessionId: e,
        windowId: t,
        changeReason: i
      }), null === (r = this.instance) || void 0 === r || null === (s = r.persistence) || void 0 === s || s.unregister(Ce), null === (n = this.instance) || void 0 === n || null === (o = n.persistence) || void 0 === o || o.unregister(Ie));
    }))) : this.stopRecording();
  }
  stopRecording() {
    var e, i, r, s;
    this._captureStarted && this.stopRrweb && (this.stopRrweb(), this.stopRrweb = void 0, this._captureStarted = !1, null == t || t.removeEventListener("beforeunload", this._onBeforeUnload), null == t || t.removeEventListener("offline", this._onOffline), null == t || t.removeEventListener("online", this._onOnline), null == t || t.removeEventListener("visibilitychange", this._onVisibilityChange), this.clearBuffer(), clearInterval(this._fullSnapshotTimer), null === (e = this._removePageViewCaptureHook) || void 0 === e || e.call(this), this._removePageViewCaptureHook = void 0, null === (i = this._removeEventTriggerCaptureHook) || void 0 === i || i.call(this), this._removeEventTriggerCaptureHook = void 0, null === (r = this._onSessionIdListener) || void 0 === r || r.call(this), this._onSessionIdListener = void 0, null === (s = this._samplingSessionListener) || void 0 === s || s.call(this), this._samplingSessionListener = void 0, is.info("stopped"));
  }
  makeSamplingDecision(e) {
    var t,
      i = this.sessionId !== e,
      r = this.sampleRate;
    if (Y(r)) {
      var s = this.isSampled,
        n = i || !K(s),
        o = n ? es(e, r) : s;
      n && (o ? this._reportStarted("sampled") : is.warn("Sample rate (".concat(r, ") has determined that this sessionId (").concat(e, ") will not be sent to the server.")), this._tryAddCustomEvent("samplingDecisionMade", {
        sampleRate: r,
        isSampled: o
      })), null === (t = this.instance.persistence) || void 0 === t || t.register({
        [xe]: o
      });
    } else {
      var a;
      null === (a = this.instance.persistence) || void 0 === a || a.register({
        [xe]: null
      });
    }
  }
  onRemoteConfig(e) {
    var t, i, r, s, n, o;
    (this._tryAddCustomEvent("$remote_config_received", e), this._persistRemoteConfig(e), this._linkedFlag = (null === (t = e.sessionRecording) || void 0 === t ? void 0 : t.linkedFlag) || null, null !== (i = e.sessionRecording) && void 0 !== i && i.endpoint) && (this._endpoint = null === (o = e.sessionRecording) || void 0 === o ? void 0 : o.endpoint);
    if (this._setupSampling(), !J(this._linkedFlag) && !this._linkedFlagSeen) {
      var a = W(this._linkedFlag) ? this._linkedFlag : this._linkedFlag.flag,
        l = W(this._linkedFlag) ? null : this._linkedFlag.variant;
      this.instance.onFeatureFlags((e, t) => {
        var i = U(t) && a in t,
          r = l ? t[a] === l : i;
        r && this._reportStarted("linked_flag_matched", {
          linkedFlag: a,
          linkedVariant: l
        }), this._linkedFlagSeen = r;
      });
    }
    null !== (r = e.sessionRecording) && void 0 !== r && r.urlTriggers && (this._urlTriggers = e.sessionRecording.urlTriggers), null !== (s = e.sessionRecording) && void 0 !== s && s.urlBlocklist && (this._urlBlocklist = e.sessionRecording.urlBlocklist), null !== (n = e.sessionRecording) && void 0 !== n && n.eventTriggers && (this._eventTriggers = e.sessionRecording.eventTriggers), this.receivedDecide = !0, this.startIfEnabledOrStop();
  }
  _setupSampling() {
    Y(this.sampleRate) && J(this._samplingSessionListener) && (this._samplingSessionListener = this.sessionManager.onSessionId(e => {
      this.makeSamplingDecision(e);
    }));
  }
  _persistRemoteConfig(e) {
    if (this.instance.persistence) {
      var t,
        i = this.instance.persistence,
        r = () => {
          var t,
            r,
            s,
            n,
            o,
            a,
            l,
            u,
            c = null === (t = e.sessionRecording) || void 0 === t ? void 0 : t.sampleRate,
            d = J(c) ? null : parseFloat(c),
            h = null === (r = e.sessionRecording) || void 0 === r ? void 0 : r.minimumDurationMilliseconds;
          i.register({
            [fe]: !!e.sessionRecording,
            [me]: null === (s = e.sessionRecording) || void 0 === s ? void 0 : s.consoleLogRecordingEnabled,
            [be]: ne({
              capturePerformance: e.capturePerformance
            }, null === (n = e.sessionRecording) || void 0 === n ? void 0 : n.networkPayloadCapture),
            [ye]: {
              enabled: null === (o = e.sessionRecording) || void 0 === o ? void 0 : o.recordCanvas,
              fps: null === (a = e.sessionRecording) || void 0 === a ? void 0 : a.canvasFps,
              quality: null === (l = e.sessionRecording) || void 0 === l ? void 0 : l.canvasQuality
            },
            [we]: d,
            [Se]: z(h) ? null : h,
            [ke]: null === (u = e.sessionRecording) || void 0 === u ? void 0 : u.scriptConfig
          });
        };
      r(), null === (t = this._persistDecideOnSessionListener) || void 0 === t || t.call(this), this._persistDecideOnSessionListener = this.sessionManager.onSessionId(r);
    }
  }
  log(e) {
    var t,
      i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "log";
    null === (t = this.instance.sessionRecording) || void 0 === t || t.onRRwebEmit({
      type: 6,
      data: {
        plugin: "rrweb/console@1",
        payload: {
          level: i,
          trace: [],
          payload: [JSON.stringify(e)]
        }
      },
      timestamp: Date.now()
    });
  }
  _startCapture(e) {
    if (!z(Object.assign) && !z(Array.from) && !(this._captureStarted || this.instance.config.disable_session_recording || this.instance.consent.isOptedOut())) {
      var t, i;
      if (this._captureStarted = !0, this.sessionManager.checkAndGetSessionAndWindowId(), this.rrwebRecord) this._onScriptLoaded();else null === (t = _.__PosthogExtensions__) || void 0 === t || null === (i = t.loadExternalDependency) || void 0 === i || i.call(t, this.instance, this.scriptName, e => {
        if (e) return is.error("could not load recorder", e);
        this._onScriptLoaded();
      });
      is.info("starting"), "active" === this.status && this._reportStarted(e || "recording_initialized");
    }
  }
  get scriptName() {
    var e, t, i;
    return (null === (e = this.instance) || void 0 === e || null === (t = e.persistence) || void 0 === t || null === (i = t.get_property(ke)) || void 0 === i ? void 0 : i.script) || "recorder";
  }
  isInteractiveEvent(e) {
    var t;
    return 3 === e.type && -1 !== ss.indexOf(null === (t = e.data) || void 0 === t ? void 0 : t.source);
  }
  _updateWindowAndSessionIds(e) {
    var t = this.isInteractiveEvent(e);
    t || this.isIdle || e.timestamp - this._lastActivityTimestamp > this.sessionIdleThresholdMilliseconds && (this.isIdle = !0, clearInterval(this._fullSnapshotTimer), this._tryAddCustomEvent("sessionIdle", {
      eventTimestamp: e.timestamp,
      lastActivityTimestamp: this._lastActivityTimestamp,
      threshold: this.sessionIdleThresholdMilliseconds,
      bufferLength: this.buffer.data.length,
      bufferSize: this.buffer.size
    }), this._flushBuffer());
    var i = !1;
    if (t && (this._lastActivityTimestamp = e.timestamp, this.isIdle && (this.isIdle = !1, this._tryAddCustomEvent("sessionNoLongerIdle", {
      reason: "user activity",
      type: e.type
    }), i = !0)), !this.isIdle) {
      var {
          windowId: r,
          sessionId: s
        } = this.sessionManager.checkAndGetSessionAndWindowId(!t, e.timestamp),
        n = this.sessionId !== s,
        o = this.windowId !== r;
      this.windowId = r, this.sessionId = s, n || o ? (this.stopRecording(), this.startIfEnabledOrStop("session_id_changed")) : i && this._scheduleFullSnapshot();
    }
  }
  _tryRRWebMethod(e) {
    try {
      return e.rrwebMethod(), !0;
    } catch (t) {
      return this.queuedRRWebEvents.length < 10 ? this.queuedRRWebEvents.push({
        enqueuedAt: e.enqueuedAt || Date.now(),
        attempt: e.attempt++,
        rrwebMethod: e.rrwebMethod
      }) : is.warn("could not emit queued rrweb event.", t, e), !1;
    }
  }
  _tryAddCustomEvent(e, t) {
    return this._tryRRWebMethod(ns(() => this.rrwebRecord.addCustomEvent(e, t)));
  }
  _tryTakeFullSnapshot() {
    return this._tryRRWebMethod(ns(() => this.rrwebRecord.takeFullSnapshot()));
  }
  _onScriptLoaded() {
    var e,
      t = {
        blockClass: "ph-no-capture",
        blockSelector: void 0,
        ignoreClass: "ph-ignore-input",
        maskTextClass: "ph-mask",
        maskTextSelector: void 0,
        maskTextFn: void 0,
        maskAllInputs: !0,
        maskInputOptions: {
          password: !0
        },
        maskInputFn: void 0,
        slimDOMOptions: {},
        collectFonts: !1,
        inlineStylesheet: !0,
        recordCrossOriginIframes: !1
      },
      i = this.instance.config.session_recording;
    for (var [r, s] of Object.entries(i || {})) r in t && ("maskInputOptions" === r ? t.maskInputOptions = ne({
      password: !0
    }, s) : t[r] = s);
    if (this.canvasRecording && this.canvasRecording.enabled && (t.recordCanvas = !0, t.sampling = {
      canvas: this.canvasRecording.fps
    }, t.dataURLOptions = {
      type: "image/webp",
      quality: this.canvasRecording.quality
    }), this.rrwebRecord) {
      this.mutationRateLimiter = null !== (e = this.mutationRateLimiter) && void 0 !== e ? e : new vr(this.rrwebRecord, {
        refillRate: this.instance.config.session_recording.__mutationRateLimiterRefillRate,
        bucketSize: this.instance.config.session_recording.__mutationRateLimiterBucketSize,
        onBlockedNode: (e, t) => {
          var i = "Too many mutations on node '".concat(e, "'. Rate limiting. This could be due to SVG animations or something similar");
          is.info(i, {
            node: t
          }), this.log(ts + " " + i, "warn");
        }
      });
      var n = this._gatherRRWebPlugins();
      this.stopRrweb = this.rrwebRecord(ne({
        emit: e => {
          this.onRRwebEmit(e);
        },
        plugins: n
      }, t)), this._lastActivityTimestamp = Date.now(), this.isIdle = !1, this._tryAddCustomEvent("$session_options", {
        sessionRecordingOptions: t,
        activePlugins: n.map(e => null == e ? void 0 : e.name)
      }), this._tryAddCustomEvent("$posthog_config", {
        config: this.instance.config
      });
    } else is.error("onScriptLoaded was called but rrwebRecord is not available. This indicates something has gone wrong.");
  }
  _scheduleFullSnapshot() {
    if (this._fullSnapshotTimer && clearInterval(this._fullSnapshotTimer), !this.isIdle) {
      var e = this.fullSnapshotIntervalMillis;
      e && (this._fullSnapshotTimer = setInterval(() => {
        this._tryTakeFullSnapshot();
      }, e));
    }
  }
  _gatherRRWebPlugins() {
    var e,
      t,
      i,
      r,
      s = [],
      n = null === (e = _.__PosthogExtensions__) || void 0 === e || null === (t = e.rrwebPlugins) || void 0 === t ? void 0 : t.getRecordConsolePlugin;
    n && this.isConsoleLogCaptureEnabled && s.push(n());
    var o = null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.rrwebPlugins) || void 0 === r ? void 0 : r.getRecordNetworkPlugin;
    this.networkPayloadCapture && H(o) && (!bt.includes(location.hostname) || this._forceAllowLocalhostNetworkCapture ? s.push(o(_r(this.instance.config, this.networkPayloadCapture))) : is.info("NetworkCapture not started because we are on localhost."));
    return s;
  }
  onRRwebEmit(e) {
    var t;
    if (this._processQueuedEvents(), e && U(e)) {
      if (e.type === Fi.Meta) {
        var i = this._maskUrl(e.data.href);
        if (this._lastHref = i, !i) return;
        e.data.href = i;
      } else this._pageViewFallBack();
      if (this._checkUrlTriggerConditions(), "paused" !== this.status || function (e) {
        return e.type === Fi.Custom && "recording paused" === e.data.tag;
      }(e)) {
        e.type === Fi.FullSnapshot && this._scheduleFullSnapshot(), e.type === Fi.FullSnapshot && "trigger_pending" === this.triggerStatus && this.clearBuffer();
        var r = this.mutationRateLimiter ? this.mutationRateLimiter.throttleMutations(e) : e;
        if (r) {
          var s = function (e) {
            var t = e;
            if (t && U(t) && 6 === t.type && U(t.data) && "rrweb/console@1" === t.data.plugin) {
              t.data.payload.payload.length > 10 && (t.data.payload.payload = t.data.payload.payload.slice(0, 10), t.data.payload.payload.push("...[truncated]"));
              for (var i = [], r = 0; r < t.data.payload.payload.length; r++) t.data.payload.payload[r] && t.data.payload.payload[r].length > 2e3 ? i.push(t.data.payload.payload[r].slice(0, 2e3) + "...[truncated]") : i.push(t.data.payload.payload[r]);
              return t.data.payload.payload = i, e;
            }
            return e;
          }(r);
          if (this._updateWindowAndSessionIds(s), !this.isIdle || as(s)) {
            if (as(s)) {
              var n = s.data.payload;
              if (n) {
                var o = n.lastActivityTimestamp,
                  a = n.threshold;
                s.timestamp = o + a;
              }
            }
            var l = null === (t = this.instance.config.session_recording.compress_events) || void 0 === t || t ? function (e) {
                if (Ci(e) < 1024) return e;
                try {
                  if (e.type === Fi.FullSnapshot) return ne(ne({}, e), {}, {
                    data: os(e.data),
                    cv: "2024-10"
                  });
                  if (e.type === Fi.IncrementalSnapshot && e.data.source === Ti.Mutation) return ne(ne({}, e), {}, {
                    cv: "2024-10",
                    data: ne(ne({}, e.data), {}, {
                      texts: os(e.data.texts),
                      attributes: os(e.data.attributes),
                      removes: os(e.data.removes),
                      adds: os(e.data.adds)
                    })
                  });
                  if (e.type === Fi.IncrementalSnapshot && e.data.source === Ti.StyleSheetRule) return ne(ne({}, e), {}, {
                    cv: "2024-10",
                    data: ne(ne({}, e.data), {}, {
                      adds: os(e.data.adds),
                      removes: os(e.data.removes)
                    })
                  });
                } catch (e) {
                  is.error("could not compress event - will use uncompressed event", e);
                }
                return e;
              }(s) : s,
              u = {
                $snapshot_bytes: Ci(l),
                $snapshot_data: l,
                $session_id: this.sessionId,
                $window_id: this.windowId
              };
            "disabled" !== this.status ? this._captureSnapshotBuffered(u) : this.clearBuffer();
          }
        }
      }
    }
  }
  _pageViewFallBack() {
    if (!this.instance.config.capture_pageview && t) {
      var e = this._maskUrl(t.location.href);
      this._lastHref !== e && (this._tryAddCustomEvent("$url_changed", {
        href: e
      }), this._lastHref = e);
    }
  }
  _processQueuedEvents() {
    if (this.queuedRRWebEvents.length) {
      var e = [...this.queuedRRWebEvents];
      this.queuedRRWebEvents = [], e.forEach(e => {
        Date.now() - e.enqueuedAt <= 2e3 && this._tryRRWebMethod(e);
      });
    }
  }
  _maskUrl(e) {
    var t = this.instance.config.session_recording;
    if (t.maskNetworkRequestFn) {
      var i,
        r = {
          url: e
        };
      return null === (i = r = t.maskNetworkRequestFn(r)) || void 0 === i ? void 0 : i.url;
    }
    return e;
  }
  clearBuffer() {
    return this.buffer = {
      size: 0,
      data: [],
      sessionId: this.sessionId,
      windowId: this.windowId
    }, this.buffer;
  }
  _flushBuffer() {
    this.flushBufferTimer && (clearTimeout(this.flushBufferTimer), this.flushBufferTimer = void 0);
    var e = this.minimumDuration,
      t = this.sessionDuration,
      i = Y(t) && t >= 0,
      r = Y(e) && i && t < e;
    if ("buffering" === this.status || "paused" === this.status || r) return this.flushBufferTimer = setTimeout(() => {
      this._flushBuffer();
    }, 2e3), this.buffer;
    this.buffer.data.length > 0 && Pi(this.buffer).forEach(e => {
      this._captureSnapshot({
        $snapshot_bytes: e.size,
        $snapshot_data: e.data,
        $session_id: e.sessionId,
        $window_id: e.windowId,
        $lib: "web",
        $lib_version: p.LIB_VERSION
      });
    });
    return this.clearBuffer();
  }
  _captureSnapshotBuffered(e) {
    var t,
      i = 2 + ((null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) || 0);
    !this.isIdle && (this.buffer.size + e.$snapshot_bytes + i > 943718.4 || this.buffer.sessionId !== this.sessionId) && (this.buffer = this._flushBuffer()), this.buffer.size += e.$snapshot_bytes, this.buffer.data.push(e.$snapshot_data), this.flushBufferTimer || this.isIdle || (this.flushBufferTimer = setTimeout(() => {
      this._flushBuffer();
    }, 2e3));
  }
  _captureSnapshot(e) {
    this.instance.capture("$snapshot", e, {
      _url: this.instance.requestRouter.endpointFor("api", this._endpoint),
      _noTruncate: !0,
      _batchKey: "recordings",
      skip_client_rate_limiting: !0
    });
  }
  _checkUrlTriggerConditions() {
    if (void 0 !== t && t.location.href) {
      var e = t.location.href,
        i = "paused" === this.status,
        r = ls(e, this._urlBlocklist);
      r && !i ? this._pauseRecording() : !r && i && this._resumeRecording(), ls(e, this._urlTriggers) && this._activateTrigger("url");
    }
  }
  _activateTrigger(e) {
    var t, i;
    "trigger_pending" === this.triggerStatus && (null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i || i.register({
      ["url" === e ? Ie : Ce]: this.sessionId
    }), this._flushBuffer(), this._reportStarted(e + "_trigger_matched"));
  }
  _pauseRecording() {
    "paused" !== this.status && (this._urlBlocked = !0, clearInterval(this._fullSnapshotTimer), is.info("recording paused due to URL blocker"), this._tryAddCustomEvent("recording paused", {
      reason: "url blocker"
    }));
  }
  _resumeRecording() {
    "paused" === this.status && (this._urlBlocked = !1, this._tryTakeFullSnapshot(), this._scheduleFullSnapshot(), this._tryAddCustomEvent("recording resumed", {
      reason: "left blocked url"
    }), is.info("recording resumed"));
  }
  _addEventTriggerListener() {
    0 !== this._eventTriggers.length && J(this._removeEventTriggerCaptureHook) && (this._removeEventTriggerCaptureHook = this.instance.on("eventCaptured", e => {
      try {
        this._eventTriggers.includes(e.event) && this._activateTrigger("event");
      } catch (e) {
        is.error("Could not activate event trigger", e);
      }
    }));
  }
  overrideLinkedFlag() {
    this._linkedFlagSeen = !0, this._tryTakeFullSnapshot(), this._reportStarted("linked_flag_overridden");
  }
  overrideSampling() {
    var e;
    null === (e = this.instance.persistence) || void 0 === e || e.register({
      [xe]: !0
    }), this._tryTakeFullSnapshot(), this._reportStarted("sampling_overridden");
  }
  overrideTrigger(e) {
    this._activateTrigger(e);
  }
  _reportStarted(e, t) {
    this.instance.register_for_session({
      $session_recording_start_reason: e
    }), is.info(e.replace("_", " "), t), $(["recording_initialized", "session_id_changed"], e) || this._tryAddCustomEvent(e, t);
  }
}
var cs = te("[RemoteConfig]");
class ds {
  constructor(e) {
    this.instance = e;
  }
  get remoteConfig() {
    var e, t;
    return null === (e = _._POSTHOG_REMOTE_CONFIG) || void 0 === e || null === (t = e[this.instance.config.token]) || void 0 === t ? void 0 : t.config;
  }
  _loadRemoteConfigJs(e) {
    var t, i, r;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.loadExternalDependency ? null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "remote-config", () => e(this.remoteConfig)) : (cs.error("PostHog Extensions not found. Cannot load remote config."), e());
  }
  _loadRemoteConfigJSON(e) {
    this.instance._send_request({
      method: "GET",
      url: this.instance.requestRouter.endpointFor("assets", "/array/".concat(this.instance.config.token, "/config")),
      callback: t => {
        e(t.json);
      }
    });
  }
  load() {
    try {
      if (this.remoteConfig) return cs.info("Using preloaded remote config", this.remoteConfig), void this.onRemoteConfig(this.remoteConfig);
      if (this.instance.config.advanced_disable_decide) return void cs.warn("Remote config is disabled. Falling back to local config.");
      this._loadRemoteConfigJs(e => {
        if (!e) return cs.info("No config found after loading remote JS config. Falling back to JSON."), void this._loadRemoteConfigJSON(e => {
          this.onRemoteConfig(e);
        });
        this.onRemoteConfig(e);
      });
    } catch (e) {
      cs.error("Error loading remote config", e);
    }
  }
  onRemoteConfig(e) {
    e ? this.instance.config.__preview_remote_config ? (this.instance._onRemoteConfig(e), !1 !== e.hasFeatureFlags && this.instance.featureFlags.ensureFlagsLoaded()) : cs.info("__preview_remote_config is disabled. Logging config instead", e) : cs.error("Failed to fetch remote config from PostHog.");
  }
}
var hs,
  _s = null != t && t.location ? Et(t.location.hash, "__posthog") || Et(location.hash, "state") : null,
  ps = "_postHogToolbarParams",
  vs = te("[Toolbar]");
!function (e) {
  e[e.UNINITIALIZED = 0] = "UNINITIALIZED", e[e.LOADING = 1] = "LOADING", e[e.LOADED = 2] = "LOADED";
}(hs || (hs = {}));
class gs {
  constructor(e) {
    this.instance = e;
  }
  setToolbarState(e) {
    _.ph_toolbar_state = e;
  }
  getToolbarState() {
    var e;
    return null !== (e = _.ph_toolbar_state) && void 0 !== e ? e : hs.UNINITIALIZED;
  }
  maybeLoadToolbar() {
    var e,
      i,
      r = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
      s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
      n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
    if (!t || !a) return !1;
    r = null !== (e = r) && void 0 !== e ? e : t.location, n = null !== (i = n) && void 0 !== i ? i : t.history;
    try {
      if (!s) {
        try {
          t.localStorage.setItem("test", "test"), t.localStorage.removeItem("test");
        } catch (e) {
          return !1;
        }
        s = null == t ? void 0 : t.localStorage;
      }
      var o,
        l = _s || Et(r.hash, "__posthog") || Et(r.hash, "state"),
        u = l ? E(() => JSON.parse(atob(decodeURIComponent(l)))) || E(() => JSON.parse(decodeURIComponent(l))) : null;
      return u && "ph_authorize" === u.action ? ((o = u).source = "url", o && Object.keys(o).length > 0 && (u.desiredHash ? r.hash = u.desiredHash : n ? n.replaceState(n.state, "", r.pathname + r.search) : r.hash = "")) : ((o = JSON.parse(s.getItem(ps) || "{}")).source = "localstorage", delete o.userIntent), !(!o.token || this.instance.config.token !== o.token) && (this.loadToolbar(o), !0);
    } catch (e) {
      return !1;
    }
  }
  _callLoadToolbar(e) {
    var t = _.ph_load_toolbar || _.ph_load_editor;
    !J(t) && H(t) ? t(e, this.instance) : vs.warn("No toolbar load function found");
  }
  loadToolbar(e) {
    var i = !(null == a || !a.getElementById(Ue));
    if (!t || i) return !1;
    var r = "custom" === this.instance.requestRouter.region && this.instance.config.advanced_disable_toolbar_metrics,
      s = ne(ne({
        token: this.instance.config.token
      }, e), {}, {
        apiURL: this.instance.requestRouter.endpointFor("ui")
      }, r ? {
        instrument: !1
      } : {});
    if (t.localStorage.setItem(ps, JSON.stringify(ne(ne({}, s), {}, {
      source: void 0
    }))), this.getToolbarState() === hs.LOADED) this._callLoadToolbar(s);else if (this.getToolbarState() === hs.UNINITIALIZED) {
      var n, o;
      this.setToolbarState(hs.LOADING), null === (n = _.__PosthogExtensions__) || void 0 === n || null === (o = n.loadExternalDependency) || void 0 === o || o.call(n, this.instance, "toolbar", e => {
        if (e) return vs.error("[Toolbar] Failed to load", e), void this.setToolbarState(hs.UNINITIALIZED);
        this.setToolbarState(hs.LOADED), this._callLoadToolbar(s);
      }), R(t, "turbolinks:load", () => {
        this.setToolbarState(hs.UNINITIALIZED), this.loadToolbar(s);
      });
    }
    return !0;
  }
  _loadEditor(e) {
    return this.loadToolbar(e);
  }
  maybeLoadEditor() {
    var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
      t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
      i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
    return this.maybeLoadToolbar(e, t, i);
  }
}
var fs = 3e3;
class ms {
  constructor(e, t) {
    oe(this, "isPaused", !0), oe(this, "queue", []), this.flushTimeoutMs = pr((null == t ? void 0 : t.flush_interval_ms) || fs, 250, 5e3, "flush interval", fs), this.sendRequest = e;
  }
  enqueue(e) {
    this.queue.push(e), this.flushTimeout || this.setFlushTimeout();
  }
  unload() {
    this.clearFlushTimeout();
    var e = this.queue.length > 0 ? this.formatQueue() : {},
      t = Object.values(e),
      i = [...t.filter(e => 0 === e.url.indexOf("/e")), ...t.filter(e => 0 !== e.url.indexOf("/e"))];
    i.map(e => {
      this.sendRequest(ne(ne({}, e), {}, {
        transport: "sendBeacon"
      }));
    });
  }
  enable() {
    this.isPaused = !1, this.setFlushTimeout();
  }
  setFlushTimeout() {
    var e = this;
    this.isPaused || (this.flushTimeout = setTimeout(() => {
      if (this.clearFlushTimeout(), this.queue.length > 0) {
        var t = this.formatQueue(),
          i = function (i) {
            var r = t[i],
              s = new Date().getTime();
            r.data && B(r.data) && y(r.data, e => {
              e.offset = Math.abs(e.timestamp - s), delete e.timestamp;
            }), e.sendRequest(r);
          };
        for (var r in t) i(r);
      }
    }, this.flushTimeoutMs));
  }
  clearFlushTimeout() {
    clearTimeout(this.flushTimeout), this.flushTimeout = void 0;
  }
  formatQueue() {
    var e = {};
    return y(this.queue, t => {
      var i,
        r = t,
        s = (r ? r.batchKey : null) || r.url;
      z(e[s]) && (e[s] = ne(ne({}, r), {}, {
        data: []
      })), null === (i = e[s].data) || void 0 === i || i.push(r.data);
    }), this.queue = [], e;
  }
}
var bs = function (e) {
    var t,
      i,
      r,
      s,
      n = "";
    for (t = i = 0, r = (e = (e + "").replace(/\r\n/g, "\n").replace(/\r/g, "\n")).length, s = 0; s < r; s++) {
      var o = e.charCodeAt(s),
        a = null;
      o < 128 ? i++ : a = o > 127 && o < 2048 ? String.fromCharCode(o >> 6 | 192, 63 & o | 128) : String.fromCharCode(o >> 12 | 224, o >> 6 & 63 | 128, 63 & o | 128), G(a) || (i > t && (n += e.substring(t, i)), n += a, t = i = s + 1);
    }
    return i > t && (n += e.substring(t, e.length)), n;
  },
  ys = !!c || !!u,
  ws = "text/plain",
  Ss = (e, t) => {
    var [i, r] = e.split("?"),
      s = ne({}, t);
    null == r || r.split("&").forEach(e => {
      var [t] = e.split("=");
      delete s[t];
    });
    var n = wt(s);
    return n = n ? (r ? r + "&" : "") + n : r, "".concat(i, "?").concat(n);
  },
  ks = (e, t) => JSON.stringify(e, (e, t) => "bigint" == typeof t ? t.toString() : t, t),
  Es = t => {
    var {
      data: i,
      compression: r
    } = t;
    if (i) {
      if (r === e.GZipJS) {
        var s = Qr(Zr(ks(i)), {
            mtime: 0
          }),
          n = new Blob([s], {
            type: ws
          });
        return {
          contentType: ws,
          body: n,
          estimatedSize: n.size
        };
      }
      if (r === e.Base64) {
        var o = function (e) {
            var t,
              i,
              r,
              s,
              n,
              o = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
              a = 0,
              l = 0,
              u = "",
              c = [];
            if (!e) return e;
            e = bs(e);
            do {
              t = (n = e.charCodeAt(a++) << 16 | e.charCodeAt(a++) << 8 | e.charCodeAt(a++)) >> 18 & 63, i = n >> 12 & 63, r = n >> 6 & 63, s = 63 & n, c[l++] = o.charAt(t) + o.charAt(i) + o.charAt(r) + o.charAt(s);
            } while (a < e.length);
            switch (u = c.join(""), e.length % 3) {
              case 1:
                u = u.slice(0, -2) + "==";
                break;
              case 2:
                u = u.slice(0, -1) + "=";
            }
            return u;
          }(ks(i)),
          a = (e => "data=" + encodeURIComponent("string" == typeof e ? e : ks(e)))(o);
        return {
          contentType: "application/x-www-form-urlencoded",
          body: a,
          estimatedSize: new Blob([a]).size
        };
      }
      var l = ks(i);
      return {
        contentType: "application/json",
        body: l,
        estimatedSize: new Blob([l]).size
      };
    }
  },
  xs = [];
u && xs.push({
  transport: "fetch",
  method: e => {
    var t,
      i,
      {
        contentType: r,
        body: s,
        estimatedSize: n
      } = null !== (t = Es(e)) && void 0 !== t ? t : {},
      o = new Headers();
    y(e.headers, function (e, t) {
      o.append(t, e);
    }), r && o.append("Content-Type", r);
    var a = e.url,
      l = null;
    if (d) {
      var c = new d();
      l = {
        signal: c.signal,
        timeout: setTimeout(() => c.abort(), e.timeout)
      };
    }
    u(a, ne({
      method: (null == e ? void 0 : e.method) || "GET",
      headers: o,
      keepalive: "POST" === e.method && (n || 0) < 52428.8,
      body: s,
      signal: null === (i = l) || void 0 === i ? void 0 : i.signal
    }, e.fetchOptions)).then(t => t.text().then(i => {
      var r,
        s = {
          statusCode: t.status,
          text: i
        };
      if (200 === t.status) try {
        s.json = JSON.parse(i);
      } catch (e) {
        ee.error(e);
      }
      null === (r = e.callback) || void 0 === r || r.call(e, s);
    })).catch(t => {
      var i;
      ee.error(t), null === (i = e.callback) || void 0 === i || i.call(e, {
        statusCode: 0,
        text: t
      });
    }).finally(() => l ? clearTimeout(l.timeout) : null);
  }
}), c && xs.push({
  transport: "XHR",
  method: e => {
    var t,
      i = new c();
    i.open(e.method || "GET", e.url, !0);
    var {
      contentType: r,
      body: s
    } = null !== (t = Es(e)) && void 0 !== t ? t : {};
    y(e.headers, function (e, t) {
      i.setRequestHeader(t, e);
    }), r && i.setRequestHeader("Content-Type", r), e.timeout && (i.timeout = e.timeout), i.withCredentials = !0, i.onreadystatechange = () => {
      if (4 === i.readyState) {
        var t,
          r = {
            statusCode: i.status,
            text: i.responseText
          };
        if (200 === i.status) try {
          r.json = JSON.parse(i.responseText);
        } catch (e) {}
        null === (t = e.callback) || void 0 === t || t.call(e, r);
      }
    }, i.send(s);
  }
}), null != o && o.sendBeacon && xs.push({
  transport: "sendBeacon",
  method: e => {
    var t = Ss(e.url, {
      beacon: "1"
    });
    try {
      var i,
        {
          contentType: r,
          body: s
        } = null !== (i = Es(e)) && void 0 !== i ? i : {},
        n = "string" == typeof s ? new Blob([s], {
          type: r
        }) : s;
      o.sendBeacon(t, n);
    } catch (e) {}
  }
});
var Is = ["retriesPerformedSoFar"];
class Cs {
  constructor(e) {
    oe(this, "isPolling", !1), oe(this, "pollIntervalMs", 3e3), oe(this, "queue", []), this.instance = e, this.queue = [], this.areWeOnline = !0, !z(t) && "onLine" in t.navigator && (this.areWeOnline = t.navigator.onLine, R(t, "online", () => {
      this.areWeOnline = !0, this.flush();
    }), R(t, "offline", () => {
      this.areWeOnline = !1;
    }));
  }
  retriableRequest(e) {
    var {
        retriesPerformedSoFar: t
      } = e,
      i = ae(e, Is);
    Y(t) && t > 0 && (i.url = Ss(i.url, {
      retry_count: t
    })), this.instance._send_request(ne(ne({}, i), {}, {
      callback: e => {
        var r;
        200 !== e.statusCode && (e.statusCode < 400 || e.statusCode >= 500) && (null != t ? t : 0) < 10 ? this.enqueue(ne({
          retriesPerformedSoFar: t
        }, i)) : null === (r = i.callback) || void 0 === r || r.call(i, e);
      }
    }));
  }
  enqueue(e) {
    var t = e.retriesPerformedSoFar || 0;
    e.retriesPerformedSoFar = t + 1;
    var i = function (e) {
        var t = 3e3 * Math.pow(2, e),
          i = t / 2,
          r = Math.min(18e5, t),
          s = (Math.random() - .5) * (r - i);
        return Math.ceil(r + s);
      }(t),
      r = Date.now() + i;
    this.queue.push({
      retryAt: r,
      requestOptions: e
    });
    var s = "Enqueued failed request for retry in ".concat(i);
    navigator.onLine || (s += " (Browser is offline)"), ee.warn(s), this.isPolling || (this.isPolling = !0, this.poll());
  }
  poll() {
    this.poller && clearTimeout(this.poller), this.poller = setTimeout(() => {
      this.areWeOnline && this.queue.length > 0 && this.flush(), this.poll();
    }, this.pollIntervalMs);
  }
  flush() {
    var e = Date.now(),
      t = [],
      i = this.queue.filter(i => i.retryAt < e || (t.push(i), !1));
    if (this.queue = t, i.length > 0) for (var {
      requestOptions: r
    } of i) this.retriableRequest(r);
  }
  unload() {
    for (var {
      requestOptions: e
    } of (this.poller && (clearTimeout(this.poller), this.poller = void 0), this.queue)) try {
      this.instance._send_request(ne(ne({}, e), {}, {
        transport: "sendBeacon"
      }));
    } catch (e) {
      ee.error(e);
    }
    this.queue = [];
  }
}
var Ps,
  Fs = te("[SessionId]");
class Ts {
  constructor(e, t, i) {
    var r;
    if (oe(this, "_sessionIdChangedHandlers", []), !e.persistence) throw new Error("SessionIdManager requires a PostHogPersistence instance");
    if (e.config.__preview_experimental_cookieless_mode) throw new Error("SessionIdManager cannot be used with __preview_experimental_cookieless_mode");
    this.config = e.config, this.persistence = e.persistence, this._windowId = void 0, this._sessionId = void 0, this._sessionStartTimestamp = null, this._sessionActivityTimestamp = null, this._sessionIdGenerator = t || st, this._windowIdGenerator = i || st;
    var s = this.config.persistence_name || this.config.token,
      n = this.config.session_idle_timeout_seconds || 1800;
    if (this._sessionTimeoutMs = 1e3 * pr(n, 60, 36e3, "session_idle_timeout_seconds", 1800), e.register({
      $configured_session_timeout_ms: this._sessionTimeoutMs
    }), this.resetIdleTimer(), this._window_id_storage_key = "ph_" + s + "_window_id", this._primary_window_exists_storage_key = "ph_" + s + "_primary_window_exists", this._canUseSessionStorage()) {
      var o = mt.parse(this._window_id_storage_key),
        a = mt.parse(this._primary_window_exists_storage_key);
      o && !a ? this._windowId = o : mt.remove(this._window_id_storage_key), mt.set(this._primary_window_exists_storage_key, !0);
    }
    if (null !== (r = this.config.bootstrap) && void 0 !== r && r.sessionID) try {
      var l = (e => {
        var t = e.replace(/-/g, "");
        if (32 !== t.length) throw new Error("Not a valid UUID");
        if ("7" !== t[12]) throw new Error("Not a UUIDv7");
        return parseInt(t.substring(0, 12), 16);
      })(this.config.bootstrap.sessionID);
      this._setSessionId(this.config.bootstrap.sessionID, new Date().getTime(), l);
    } catch (e) {
      Fs.error("Invalid sessionID in bootstrap", e);
    }
    this._listenToReloadWindow();
  }
  get sessionTimeoutMs() {
    return this._sessionTimeoutMs;
  }
  onSessionId(e) {
    return z(this._sessionIdChangedHandlers) && (this._sessionIdChangedHandlers = []), this._sessionIdChangedHandlers.push(e), this._sessionId && e(this._sessionId, this._windowId), () => {
      this._sessionIdChangedHandlers = this._sessionIdChangedHandlers.filter(t => t !== e);
    };
  }
  _canUseSessionStorage() {
    return "memory" !== this.config.persistence && !this.persistence.disabled && mt.is_supported();
  }
  _setWindowId(e) {
    e !== this._windowId && (this._windowId = e, this._canUseSessionStorage() && mt.set(this._window_id_storage_key, e));
  }
  _getWindowId() {
    return this._windowId ? this._windowId : this._canUseSessionStorage() ? mt.parse(this._window_id_storage_key) : null;
  }
  _setSessionId(e, t, i) {
    e === this._sessionId && t === this._sessionActivityTimestamp && i === this._sessionStartTimestamp || (this._sessionStartTimestamp = i, this._sessionActivityTimestamp = t, this._sessionId = e, this.persistence.register({
      [Ee]: [t, e, i]
    }));
  }
  _getSessionId() {
    if (this._sessionId && this._sessionActivityTimestamp && this._sessionStartTimestamp) return [this._sessionActivityTimestamp, this._sessionId, this._sessionStartTimestamp];
    var e = this.persistence.props[Ee];
    return B(e) && 2 === e.length && e.push(e[0]), e || [0, null, 0];
  }
  resetSessionId() {
    this._setSessionId(null, null, null);
  }
  _listenToReloadWindow() {
    R(t, "beforeunload", () => {
      this._canUseSessionStorage() && mt.remove(this._primary_window_exists_storage_key);
    }, {
      capture: !1
    });
  }
  checkAndGetSessionAndWindowId() {
    var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null;
    if (this.config.__preview_experimental_cookieless_mode) throw new Error("checkAndGetSessionAndWindowId should not be called in __preview_experimental_cookieless_mode");
    var i = t || new Date().getTime(),
      [r, s, n] = this._getSessionId(),
      o = this._getWindowId(),
      a = Y(n) && n > 0 && Math.abs(i - n) > 864e5,
      l = !1,
      u = !s,
      c = !e && Math.abs(i - r) > this.sessionTimeoutMs;
    u || c || a ? (s = this._sessionIdGenerator(), o = this._windowIdGenerator(), Fs.info("new session ID generated", {
      sessionId: s,
      windowId: o,
      changeReason: {
        noSessionId: u,
        activityTimeout: c,
        sessionPastMaximumLength: a
      }
    }), n = i, l = !0) : o || (o = this._windowIdGenerator(), l = !0);
    var d = 0 === r || !e || a ? i : r,
      h = 0 === n ? new Date().getTime() : n;
    return this._setWindowId(o), this._setSessionId(s, d, h), e || this.resetIdleTimer(), l && this._sessionIdChangedHandlers.forEach(e => e(s, o, l ? {
      noSessionId: u,
      activityTimeout: c,
      sessionPastMaximumLength: a
    } : void 0)), {
      sessionId: s,
      windowId: o,
      sessionStartTimestamp: h,
      changeReason: l ? {
        noSessionId: u,
        activityTimeout: c,
        sessionPastMaximumLength: a
      } : void 0,
      lastActivityTimestamp: r
    };
  }
  resetIdleTimer() {
    clearTimeout(this._enforceIdleTimeout), this._enforceIdleTimeout = setTimeout(() => {
      this.resetSessionId();
    }, 1.1 * this.sessionTimeoutMs);
  }
}
!function (e) {
  e.US = "us", e.EU = "eu", e.CUSTOM = "custom";
}(Ps || (Ps = {}));
var Rs = "i.posthog.com";
class $s {
  constructor(e) {
    oe(this, "_regionCache", {}), this.instance = e;
  }
  get apiHost() {
    var e = this.instance.config.api_host.trim().replace(/\/$/, "");
    return "https://app.posthog.com" === e ? "https://us.i.posthog.com" : e;
  }
  get uiHost() {
    var e,
      t = null === (e = this.instance.config.ui_host) || void 0 === e ? void 0 : e.replace(/\/$/, "");
    return t || (t = this.apiHost.replace(".".concat(Rs), ".posthog.com")), "https://app.posthog.com" === t ? "https://us.posthog.com" : t;
  }
  get region() {
    return this._regionCache[this.apiHost] || (/https:\/\/(app|us|us-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = Ps.US : /https:\/\/(eu|eu-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = Ps.EU : this._regionCache[this.apiHost] = Ps.CUSTOM), this._regionCache[this.apiHost];
  }
  endpointFor(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "";
    if (t && (t = "/" === t[0] ? t : "/".concat(t)), "ui" === e) return this.uiHost + t;
    if (this.region === Ps.CUSTOM) return this.apiHost + t;
    var i = Rs + t;
    switch (e) {
      case "assets":
        return "https://".concat(this.region, "-assets.").concat(i);
      case "api":
        return "https://".concat(this.region, ".").concat(i);
    }
  }
}
var Os = "posthog-js";
function Ms(e) {
  var {
    organization: t,
    projectId: i,
    prefix: r,
    severityAllowList: s = ["error"]
  } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
  return n => {
    var o, a, l, u, c;
    if (!("*" === s || s.includes(n.level)) || !e.__loaded) return n;
    n.tags || (n.tags = {});
    var d = e.requestRouter.endpointFor("ui", "/project/".concat(e.config.token, "/person/").concat(e.get_distinct_id()));
    n.tags["PostHog Person URL"] = d, e.sessionRecordingStarted() && (n.tags["PostHog Recording URL"] = e.get_session_replay_url({
      withTimestamp: !0
    }));
    var h = (null === (o = n.exception) || void 0 === o ? void 0 : o.values) || [];
    h.forEach(e => {
      e.stacktrace && (e.stacktrace.type = "raw", e.stacktrace.frames.forEach(e => {
        e.platform = "web:javascript";
      }));
    });
    var _ = {
      $exception_message: (null === (a = h[0]) || void 0 === a ? void 0 : a.value) || n.message,
      $exception_type: null === (l = h[0]) || void 0 === l ? void 0 : l.type,
      $exception_personURL: d,
      $exception_level: n.level,
      $exception_list: h,
      $sentry_event_id: n.event_id,
      $sentry_exception: n.exception,
      $sentry_exception_message: (null === (u = h[0]) || void 0 === u ? void 0 : u.value) || n.message,
      $sentry_exception_type: null === (c = h[0]) || void 0 === c ? void 0 : c.type,
      $sentry_tags: n.tags
    };
    return t && i && (_.$sentry_url = (r || "https://sentry.io/organizations/") + t + "/issues/?project=" + i + "&query=" + n.event_id), e.exceptions.sendExceptionEvent(_), n;
  };
}
class As {
  constructor(e, t, i, r, s) {
    this.name = Os, this.setupOnce = function (n) {
      n(Ms(e, {
        organization: t,
        projectId: i,
        prefix: r,
        severityAllowList: s
      }));
    };
  }
}
var Ls = te("[SegmentIntegration]");
function Ds(e, t) {
  var i = e.config.segment;
  if (!i) return t();
  !function (e, t) {
    var i = e.config.segment;
    if (!i) return t();
    var r = i => {
        var r = () => i.anonymousId() || st();
        e.config.get_device_id = r, i.id() && (e.register({
          distinct_id: i.id(),
          $device_id: r()
        }), e.persistence.set_property(Ae, "identified")), t();
      },
      s = i.user();
    "then" in s && H(s.then) ? s.then(e => r(e)) : r(s);
  }(e, () => {
    i.register((e => {
      Promise && Promise.resolve || Ls.warn("This browser does not have Promise support, and can not use the segment integration");
      var t = (t, i) => {
        var r;
        if (!i) return t;
        t.event.userId || t.event.anonymousId === e.get_distinct_id() || (Ls.info("No userId set, resetting PostHog"), e.reset()), t.event.userId && t.event.userId !== e.get_distinct_id() && (Ls.info("UserId set, identifying with PostHog"), e.identify(t.event.userId));
        var s = e._calculate_event_properties(i, null !== (r = t.event.properties) && void 0 !== r ? r : {}, new Date());
        return t.event.properties = Object.assign({}, s, t.event.properties), t;
      };
      return {
        name: "PostHog JS",
        type: "enrichment",
        version: "1.0.0",
        isLoaded: () => !0,
        load: () => Promise.resolve(),
        track: e => t(e, e.event.event),
        page: e => t(e, "$pageview"),
        identify: e => t(e, "$identify"),
        screen: e => t(e, "$screen")
      };
    })(e)).then(() => {
      t();
    });
  });
}
class Ns {
  constructor(e) {
    this._instance = e;
  }
  doPageView(e, i) {
    var r,
      s = this._previousPageViewProperties(e, i);
    return this._currentPageview = {
      pathname: null !== (r = null == t ? void 0 : t.location.pathname) && void 0 !== r ? r : "",
      pageViewId: i,
      timestamp: e
    }, this._instance.scrollManager.resetContext(), s;
  }
  doPageLeave(e) {
    var t;
    return this._previousPageViewProperties(e, null === (t = this._currentPageview) || void 0 === t ? void 0 : t.pageViewId);
  }
  doEvent() {
    var e;
    return {
      $pageview_id: null === (e = this._currentPageview) || void 0 === e ? void 0 : e.pageViewId
    };
  }
  _previousPageViewProperties(e, t) {
    var i = this._currentPageview;
    if (!i) return {
      $pageview_id: t
    };
    var r = {
        $pageview_id: t,
        $prev_pageview_id: i.pageViewId
      },
      s = this._instance.scrollManager.getContext();
    if (s && !this._instance.config.disable_scroll_properties) {
      var {
        maxScrollHeight: n,
        lastScrollY: o,
        maxScrollY: a,
        maxContentHeight: l,
        lastContentY: u,
        maxContentY: c
      } = s;
      if (!(z(n) || z(o) || z(a) || z(l) || z(u) || z(c))) {
        n = Math.ceil(n), o = Math.ceil(o), a = Math.ceil(a), l = Math.ceil(l), u = Math.ceil(u), c = Math.ceil(c);
        var d = n <= 1 ? 1 : pr(o / n, 0, 1),
          h = n <= 1 ? 1 : pr(a / n, 0, 1),
          _ = l <= 1 ? 1 : pr(u / l, 0, 1),
          p = l <= 1 ? 1 : pr(c / l, 0, 1);
        r = w(r, {
          $prev_pageview_last_scroll: o,
          $prev_pageview_last_scroll_percentage: d,
          $prev_pageview_max_scroll: a,
          $prev_pageview_max_scroll_percentage: h,
          $prev_pageview_last_content: u,
          $prev_pageview_last_content_percentage: _,
          $prev_pageview_max_content: c,
          $prev_pageview_max_content_percentage: p
        });
      }
    }
    return i.pathname && (r.$prev_pageview_pathname = i.pathname), i.timestamp && (r.$prev_pageview_duration = (e.getTime() - i.timestamp.getTime()) / 1e3), r;
  }
}
var qs,
  Bs,
  Hs,
  Us,
  js,
  zs,
  Ws,
  Vs,
  Gs = {},
  Js = [],
  Ys = /acit|ex(?:s|g|n|p|$)|rph|grid|ows|mnc|ntw|ine[ch]|zoo|^ord|itera/i,
  Ks = Array.isArray;
function Xs(e, t) {
  for (var i in t) e[i] = t[i];
  return e;
}
function Qs(e) {
  var t = e.parentNode;
  t && t.removeChild(e);
}
function Zs(e, t, i, r, s) {
  var n = {
    type: e,
    props: t,
    key: i,
    ref: r,
    __k: null,
    __: null,
    __b: 0,
    __e: null,
    __d: void 0,
    __c: null,
    constructor: void 0,
    __v: null == s ? ++Hs : s,
    __i: -1,
    __u: 0
  };
  return null == s && null != Bs.vnode && Bs.vnode(n), n;
}
function en(e) {
  return e.children;
}
function tn(e, t) {
  this.props = e, this.context = t;
}
function rn(e, t) {
  if (null == t) return e.__ ? rn(e.__, e.__i + 1) : null;
  for (var i; t < e.__k.length; t++) if (null != (i = e.__k[t]) && null != i.__e) return i.__e;
  return "function" == typeof e.type ? rn(e) : null;
}
function sn(e) {
  var t, i;
  if (null != (e = e.__) && null != e.__c) {
    for (e.__e = e.__c.base = null, t = 0; t < e.__k.length; t++) if (null != (i = e.__k[t]) && null != i.__e) {
      e.__e = e.__c.base = i.__e;
      break;
    }
    return sn(e);
  }
}
function nn(e) {
  (!e.__d && (e.__d = !0) && Us.push(e) && !on.__r++ || js !== Bs.debounceRendering) && ((js = Bs.debounceRendering) || zs)(on);
}
function on() {
  var e, t, i, r, s, n, o, a, l;
  for (Us.sort(Ws); e = Us.shift();) e.__d && (t = Us.length, r = void 0, n = (s = (i = e).__v).__e, a = [], l = [], (o = i.__P) && ((r = Xs({}, s)).__v = s.__v + 1, Bs.vnode && Bs.vnode(r), vn(o, r, s, i.__n, void 0 !== o.ownerSVGElement, 32 & s.__u ? [n] : null, a, null == n ? rn(s) : n, !!(32 & s.__u), l), r.__.__k[r.__i] = r, gn(a, r, l), r.__e != n && sn(r)), Us.length > t && Us.sort(Ws));
  on.__r = 0;
}
function an(e, t, i, r, s, n, o, a, l, u, c) {
  var d,
    h,
    _,
    p,
    v,
    g = r && r.__k || Js,
    f = t.length;
  for (i.__d = l, ln(i, t, g), l = i.__d, d = 0; d < f; d++) null != (_ = i.__k[d]) && "boolean" != typeof _ && "function" != typeof _ && (h = -1 === _.__i ? Gs : g[_.__i] || Gs, _.__i = d, vn(e, _, h, s, n, o, a, l, u, c), p = _.__e, _.ref && h.ref != _.ref && (h.ref && mn(h.ref, null, _), c.push(_.ref, _.__c || p, _)), null == v && null != p && (v = p), 65536 & _.__u || h.__k === _.__k ? l = un(_, l, e) : "function" == typeof _.type && void 0 !== _.__d ? l = _.__d : p && (l = p.nextSibling), _.__d = void 0, _.__u &= -196609);
  i.__d = l, i.__e = v;
}
function ln(e, t, i) {
  var r,
    s,
    n,
    o,
    a,
    l = t.length,
    u = i.length,
    c = u,
    d = 0;
  for (e.__k = [], r = 0; r < l; r++) null != (s = e.__k[r] = null == (s = t[r]) || "boolean" == typeof s || "function" == typeof s ? null : "string" == typeof s || "number" == typeof s || "bigint" == typeof s || s.constructor == String ? Zs(null, s, null, null, s) : Ks(s) ? Zs(en, {
    children: s
  }, null, null, null) : void 0 === s.constructor && s.__b > 0 ? Zs(s.type, s.props, s.key, s.ref ? s.ref : null, s.__v) : s) ? (s.__ = e, s.__b = e.__b + 1, a = cn(s, i, o = r + d, c), s.__i = a, n = null, -1 !== a && (c--, (n = i[a]) && (n.__u |= 131072)), null == n || null === n.__v ? (-1 == a && d--, "function" != typeof s.type && (s.__u |= 65536)) : a !== o && (a === o + 1 ? d++ : a > o ? c > l - o ? d += a - o : d-- : d = a < o && a == o - 1 ? a - o : 0, a !== r + d && (s.__u |= 65536))) : (n = i[r]) && null == n.key && n.__e && (n.__e == e.__d && (e.__d = rn(n)), bn(n, n, !1), i[r] = null, c--);
  if (c) for (r = 0; r < u; r++) null != (n = i[r]) && 0 == (131072 & n.__u) && (n.__e == e.__d && (e.__d = rn(n)), bn(n, n));
}
function un(e, t, i) {
  var r, s;
  if ("function" == typeof e.type) {
    for (r = e.__k, s = 0; r && s < r.length; s++) r[s] && (r[s].__ = e, t = un(r[s], t, i));
    return t;
  }
  return e.__e != t && (i.insertBefore(e.__e, t || null), t = e.__e), t && t.nextSibling;
}
function cn(e, t, i, r) {
  var s = e.key,
    n = e.type,
    o = i - 1,
    a = i + 1,
    l = t[i];
  if (null === l || l && s == l.key && n === l.type) return i;
  if (r > (null != l && 0 == (131072 & l.__u) ? 1 : 0)) for (; o >= 0 || a < t.length;) {
    if (o >= 0) {
      if ((l = t[o]) && 0 == (131072 & l.__u) && s == l.key && n === l.type) return o;
      o--;
    }
    if (a < t.length) {
      if ((l = t[a]) && 0 == (131072 & l.__u) && s == l.key && n === l.type) return a;
      a++;
    }
  }
  return -1;
}
function dn(e, t, i) {
  "-" === t[0] ? e.setProperty(t, null == i ? "" : i) : e[t] = null == i ? "" : "number" != typeof i || Ys.test(t) ? i : i + "px";
}
function hn(e, t, i, r, s) {
  var n;
  e: if ("style" === t) {
    if ("string" == typeof i) e.style.cssText = i;else {
      if ("string" == typeof r && (e.style.cssText = r = ""), r) for (t in r) i && t in i || dn(e.style, t, "");
      if (i) for (t in i) r && i[t] === r[t] || dn(e.style, t, i[t]);
    }
  } else if ("o" === t[0] && "n" === t[1]) n = t !== (t = t.replace(/(PointerCapture)$|Capture$/, "$1")), t = t.toLowerCase() in e ? t.toLowerCase().slice(2) : t.slice(2), e.l || (e.l = {}), e.l[t + n] = i, i ? r ? i.u = r.u : (i.u = Date.now(), e.addEventListener(t, n ? pn : _n, n)) : e.removeEventListener(t, n ? pn : _n, n);else {
    if (s) t = t.replace(/xlink(H|:h)/, "h").replace(/sName$/, "s");else if ("width" !== t && "height" !== t && "href" !== t && "list" !== t && "form" !== t && "tabIndex" !== t && "download" !== t && "rowSpan" !== t && "colSpan" !== t && "role" !== t && t in e) try {
      e[t] = null == i ? "" : i;
      break e;
    } catch (e) {}
    "function" == typeof i || (null == i || !1 === i && "-" !== t[4] ? e.removeAttribute(t) : e.setAttribute(t, i));
  }
}
function _n(e) {
  var t = this.l[e.type + !1];
  if (e.t) {
    if (e.t <= t.u) return;
  } else e.t = Date.now();
  return t(Bs.event ? Bs.event(e) : e);
}
function pn(e) {
  return this.l[e.type + !0](Bs.event ? Bs.event(e) : e);
}
function vn(e, t, i, r, s, n, o, a, l, u) {
  var c,
    d,
    h,
    _,
    p,
    v,
    g,
    f,
    m,
    b,
    y,
    w,
    S,
    k,
    E,
    x = t.type;
  if (void 0 !== t.constructor) return null;
  128 & i.__u && (l = !!(32 & i.__u), n = [a = t.__e = i.__e]), (c = Bs.__b) && c(t);
  e: if ("function" == typeof x) try {
    if (f = t.props, m = (c = x.contextType) && r[c.__c], b = c ? m ? m.props.value : c.__ : r, i.__c ? g = (d = t.__c = i.__c).__ = d.__E : ("prototype" in x && x.prototype.render ? t.__c = d = new x(f, b) : (t.__c = d = new tn(f, b), d.constructor = x, d.render = yn), m && m.sub(d), d.props = f, d.state || (d.state = {}), d.context = b, d.__n = r, h = d.__d = !0, d.__h = [], d._sb = []), null == d.__s && (d.__s = d.state), null != x.getDerivedStateFromProps && (d.__s == d.state && (d.__s = Xs({}, d.__s)), Xs(d.__s, x.getDerivedStateFromProps(f, d.__s))), _ = d.props, p = d.state, d.__v = t, h) null == x.getDerivedStateFromProps && null != d.componentWillMount && d.componentWillMount(), null != d.componentDidMount && d.__h.push(d.componentDidMount);else {
      if (null == x.getDerivedStateFromProps && f !== _ && null != d.componentWillReceiveProps && d.componentWillReceiveProps(f, b), !d.__e && (null != d.shouldComponentUpdate && !1 === d.shouldComponentUpdate(f, d.__s, b) || t.__v === i.__v)) {
        for (t.__v !== i.__v && (d.props = f, d.state = d.__s, d.__d = !1), t.__e = i.__e, t.__k = i.__k, t.__k.forEach(function (e) {
          e && (e.__ = t);
        }), y = 0; y < d._sb.length; y++) d.__h.push(d._sb[y]);
        d._sb = [], d.__h.length && o.push(d);
        break e;
      }
      null != d.componentWillUpdate && d.componentWillUpdate(f, d.__s, b), null != d.componentDidUpdate && d.__h.push(function () {
        d.componentDidUpdate(_, p, v);
      });
    }
    if (d.context = b, d.props = f, d.__P = e, d.__e = !1, w = Bs.__r, S = 0, "prototype" in x && x.prototype.render) {
      for (d.state = d.__s, d.__d = !1, w && w(t), c = d.render(d.props, d.state, d.context), k = 0; k < d._sb.length; k++) d.__h.push(d._sb[k]);
      d._sb = [];
    } else do {
      d.__d = !1, w && w(t), c = d.render(d.props, d.state, d.context), d.state = d.__s;
    } while (d.__d && ++S < 25);
    d.state = d.__s, null != d.getChildContext && (r = Xs(Xs({}, r), d.getChildContext())), h || null == d.getSnapshotBeforeUpdate || (v = d.getSnapshotBeforeUpdate(_, p)), an(e, Ks(E = null != c && c.type === en && null == c.key ? c.props.children : c) ? E : [E], t, i, r, s, n, o, a, l, u), d.base = t.__e, t.__u &= -161, d.__h.length && o.push(d), g && (d.__E = d.__ = null);
  } catch (e) {
    t.__v = null, l || null != n ? (t.__e = a, t.__u |= l ? 160 : 32, n[n.indexOf(a)] = null) : (t.__e = i.__e, t.__k = i.__k), Bs.__e(e, t, i);
  } else null == n && t.__v === i.__v ? (t.__k = i.__k, t.__e = i.__e) : t.__e = fn(i.__e, t, i, r, s, n, o, l, u);
  (c = Bs.diffed) && c(t);
}
function gn(e, t, i) {
  t.__d = void 0;
  for (var r = 0; r < i.length; r++) mn(i[r], i[++r], i[++r]);
  Bs.__c && Bs.__c(t, e), e.some(function (t) {
    try {
      e = t.__h, t.__h = [], e.some(function (e) {
        e.call(t);
      });
    } catch (e) {
      Bs.__e(e, t.__v);
    }
  });
}
function fn(e, t, i, r, s, n, o, a, l) {
  var u,
    c,
    d,
    h,
    _,
    p,
    v,
    g = i.props,
    f = t.props,
    m = t.type;
  if ("svg" === m && (s = !0), null != n) for (u = 0; u < n.length; u++) if ((_ = n[u]) && "setAttribute" in _ == !!m && (m ? _.localName === m : 3 === _.nodeType)) {
    e = _, n[u] = null;
    break;
  }
  if (null == e) {
    if (null === m) return document.createTextNode(f);
    e = s ? document.createElementNS("http://www.w3.org/2000/svg", m) : document.createElement(m, f.is && f), n = null, a = !1;
  }
  if (null === m) g === f || a && e.data === f || (e.data = f);else {
    if (n = n && qs.call(e.childNodes), g = i.props || Gs, !a && null != n) for (g = {}, u = 0; u < e.attributes.length; u++) g[(_ = e.attributes[u]).name] = _.value;
    for (u in g) _ = g[u], "children" == u || ("dangerouslySetInnerHTML" == u ? d = _ : "key" === u || u in f || hn(e, u, null, _, s));
    for (u in f) _ = f[u], "children" == u ? h = _ : "dangerouslySetInnerHTML" == u ? c = _ : "value" == u ? p = _ : "checked" == u ? v = _ : "key" === u || a && "function" != typeof _ || g[u] === _ || hn(e, u, _, g[u], s);
    if (c) a || d && (c.__html === d.__html || c.__html === e.innerHTML) || (e.innerHTML = c.__html), t.__k = [];else if (d && (e.innerHTML = ""), an(e, Ks(h) ? h : [h], t, i, r, s && "foreignObject" !== m, n, o, n ? n[0] : i.__k && rn(i, 0), a, l), null != n) for (u = n.length; u--;) null != n[u] && Qs(n[u]);
    a || (u = "value", void 0 !== p && (p !== e[u] || "progress" === m && !p || "option" === m && p !== g[u]) && hn(e, u, p, g[u], !1), u = "checked", void 0 !== v && v !== e[u] && hn(e, u, v, g[u], !1));
  }
  return e;
}
function mn(e, t, i) {
  try {
    "function" == typeof e ? e(t) : e.current = t;
  } catch (e) {
    Bs.__e(e, i);
  }
}
function bn(e, t, i) {
  var r, s;
  if (Bs.unmount && Bs.unmount(e), (r = e.ref) && (r.current && r.current !== e.__e || mn(r, null, t)), null != (r = e.__c)) {
    if (r.componentWillUnmount) try {
      r.componentWillUnmount();
    } catch (e) {
      Bs.__e(e, t);
    }
    r.base = r.__P = null, e.__c = void 0;
  }
  if (r = e.__k) for (s = 0; s < r.length; s++) r[s] && bn(r[s], t, i || "function" != typeof e.type);
  i || null == e.__e || Qs(e.__e), e.__ = e.__e = e.__d = void 0;
}
function yn(e, t, i) {
  return this.constructor(e, i);
}
qs = Js.slice, Bs = {
  __e: function (e, t, i, r) {
    for (var s, n, o; t = t.__;) if ((s = t.__c) && !s.__) try {
      if ((n = s.constructor) && null != n.getDerivedStateFromError && (s.setState(n.getDerivedStateFromError(e)), o = s.__d), null != s.componentDidCatch && (s.componentDidCatch(e, r || {}), o = s.__d), o) return s.__E = s;
    } catch (t) {
      e = t;
    }
    throw e;
  }
}, Hs = 0, tn.prototype.setState = function (e, t) {
  var i;
  i = null != this.__s && this.__s !== this.state ? this.__s : this.__s = Xs({}, this.state), "function" == typeof e && (e = e(Xs({}, i), this.props)), e && Xs(i, e), null != e && this.__v && (t && this._sb.push(t), nn(this));
}, tn.prototype.forceUpdate = function (e) {
  this.__v && (this.__e = !0, e && this.__h.push(e), nn(this));
}, tn.prototype.render = en, Us = [], zs = "function" == typeof Promise ? Promise.prototype.then.bind(Promise.resolve()) : setTimeout, Ws = function (e, t) {
  return e.__v.__b - t.__v.__b;
}, on.__r = 0, Vs = 0;
var wn, Sn, kn;
!function (e, t) {
  var i = {
    __c: t = "__cC" + Vs++,
    __: e,
    Consumer: function (e, t) {
      return e.children(t);
    },
    Provider: function (e) {
      var i, r;
      return this.getChildContext || (i = [], (r = {})[t] = this, this.getChildContext = function () {
        return r;
      }, this.shouldComponentUpdate = function (e) {
        this.props.value !== e.value && i.some(function (e) {
          e.__e = !0, nn(e);
        });
      }, this.sub = function (e) {
        i.push(e);
        var t = e.componentWillUnmount;
        e.componentWillUnmount = function () {
          i.splice(i.indexOf(e), 1), t && t.call(e);
        };
      }), e.children;
    }
  };
  i.Provider.__ = i.Consumer.contextType = i;
}({
  isPreviewMode: !1,
  previewPageIndex: 0,
  handleCloseSurveyPopup: () => {},
  isPopup: !0,
  onPreviewSubmit: () => {}
}), function (e) {
  e.Popover = "popover", e.API = "api", e.Widget = "widget";
}(wn || (wn = {})), function (e) {
  e.Open = "open", e.MultipleChoice = "multiple_choice", e.SingleChoice = "single_choice", e.Rating = "rating", e.Link = "link";
}(Sn || (Sn = {})), function (e) {
  e.NextQuestion = "next_question", e.End = "end", e.ResponseBased = "response_based", e.SpecificQuestion = "specific_question";
}(kn || (kn = {}));
class En {
  constructor() {
    oe(this, "events", {}), this.events = {};
  }
  on(e, t) {
    return this.events[e] || (this.events[e] = []), this.events[e].push(t), () => {
      this.events[e] = this.events[e].filter(e => e !== t);
    };
  }
  emit(e, t) {
    for (var i of this.events[e] || []) i(t);
    for (var r of this.events["*"] || []) r(e, t);
  }
}
class xn {
  constructor(e) {
    oe(this, "_debugEventEmitter", new En()), oe(this, "checkStep", (e, t) => this.checkStepEvent(e, t) && this.checkStepUrl(e, t) && this.checkStepElement(e, t)), oe(this, "checkStepEvent", (e, t) => null == t || !t.event || (null == e ? void 0 : e.event) === (null == t ? void 0 : t.event)), this.instance = e, this.actionEvents = new Set(), this.actionRegistry = new Set();
  }
  init() {
    var e;
    if (!z(null === (e = this.instance) || void 0 === e ? void 0 : e._addCaptureHook)) {
      var t;
      null === (t = this.instance) || void 0 === t || t._addCaptureHook((e, t) => {
        this.on(e, t);
      });
    }
  }
  register(e) {
    var t, i;
    if (!z(null === (t = this.instance) || void 0 === t ? void 0 : t._addCaptureHook) && (e.forEach(e => {
      var t, i;
      null === (t = this.actionRegistry) || void 0 === t || t.add(e), null === (i = e.steps) || void 0 === i || i.forEach(e => {
        var t;
        null === (t = this.actionEvents) || void 0 === t || t.add((null == e ? void 0 : e.event) || "");
      });
    }), null !== (i = this.instance) && void 0 !== i && i.autocapture)) {
      var r,
        s = new Set();
      e.forEach(e => {
        var t;
        null === (t = e.steps) || void 0 === t || t.forEach(e => {
          null != e && e.selector && s.add(null == e ? void 0 : e.selector);
        });
      }), null === (r = this.instance) || void 0 === r || r.autocapture.setElementSelectors(s);
    }
  }
  on(e, t) {
    var i;
    null != t && 0 != e.length && (this.actionEvents.has(e) || this.actionEvents.has(null == t ? void 0 : t.event)) && this.actionRegistry && (null === (i = this.actionRegistry) || void 0 === i ? void 0 : i.size) > 0 && this.actionRegistry.forEach(e => {
      this.checkAction(t, e) && this._debugEventEmitter.emit("actionCaptured", e.name);
    });
  }
  _addActionHook(e) {
    this.onAction("actionCaptured", t => e(t));
  }
  checkAction(e, t) {
    if (null == (null == t ? void 0 : t.steps)) return !1;
    for (var i of t.steps) if (this.checkStep(e, i)) return !0;
    return !1;
  }
  onAction(e, t) {
    return this._debugEventEmitter.on(e, t);
  }
  checkStepUrl(e, t) {
    if (null != t && t.url) {
      var i,
        r = null == e || null === (i = e.properties) || void 0 === i ? void 0 : i.$current_url;
      if (!r || "string" != typeof r) return !1;
      if (!xn.matchString(r, null == t ? void 0 : t.url, (null == t ? void 0 : t.url_matching) || "contains")) return !1;
    }
    return !0;
  }
  static matchString(e, i, r) {
    switch (r) {
      case "regex":
        return !!t && A(e, i);
      case "exact":
        return i === e;
      case "contains":
        var s = xn.escapeStringRegexp(i).replace(/_/g, ".").replace(/%/g, ".*");
        return A(e, s);
      default:
        return !1;
    }
  }
  static escapeStringRegexp(e) {
    return e.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
  }
  checkStepElement(e, t) {
    if ((null != t && t.href || null != t && t.tag_name || null != t && t.text) && !this.getElementsList(e).some(e => !(null != t && t.href && !xn.matchString(e.href || "", null == t ? void 0 : t.href, (null == t ? void 0 : t.href_matching) || "exact")) && (null == t || !t.tag_name || e.tag_name === (null == t ? void 0 : t.tag_name)) && !(null != t && t.text && !xn.matchString(e.text || "", null == t ? void 0 : t.text, (null == t ? void 0 : t.text_matching) || "exact") && !xn.matchString(e.$el_text || "", null == t ? void 0 : t.text, (null == t ? void 0 : t.text_matching) || "exact")))) return !1;
    if (null != t && t.selector) {
      var i,
        r = null == e || null === (i = e.properties) || void 0 === i ? void 0 : i.$element_selectors;
      if (!r) return !1;
      if (!r.includes(null == t ? void 0 : t.selector)) return !1;
    }
    return !0;
  }
  getElementsList(e) {
    return null == (null == e ? void 0 : e.properties.$elements) ? [] : null == e ? void 0 : e.properties.$elements;
  }
}
class In {
  constructor(e) {
    this.instance = e, this.eventToSurveys = new Map(), this.actionToSurveys = new Map();
  }
  register(e) {
    var t;
    z(null === (t = this.instance) || void 0 === t ? void 0 : t._addCaptureHook) || (this.setupEventBasedSurveys(e), this.setupActionBasedSurveys(e));
  }
  setupActionBasedSurveys(e) {
    var t = e.filter(e => {
      var t, i, r, s;
      return (null === (t = e.conditions) || void 0 === t ? void 0 : t.actions) && (null === (i = e.conditions) || void 0 === i || null === (r = i.actions) || void 0 === r || null === (s = r.values) || void 0 === s ? void 0 : s.length) > 0;
    });
    if (0 !== t.length) {
      if (null == this.actionMatcher) {
        this.actionMatcher = new xn(this.instance), this.actionMatcher.init();
        this.actionMatcher._addActionHook(e => {
          this.onAction(e);
        });
      }
      t.forEach(e => {
        var t, i, r, s, n, o, a, l, u, c;
        e.conditions && null !== (t = e.conditions) && void 0 !== t && t.actions && null !== (i = e.conditions) && void 0 !== i && null !== (r = i.actions) && void 0 !== r && r.values && (null === (s = e.conditions) || void 0 === s || null === (n = s.actions) || void 0 === n || null === (o = n.values) || void 0 === o ? void 0 : o.length) > 0 && (null === (a = this.actionMatcher) || void 0 === a || a.register(e.conditions.actions.values), null === (l = e.conditions) || void 0 === l || null === (u = l.actions) || void 0 === u || null === (c = u.values) || void 0 === c || c.forEach(t => {
          if (t && t.name) {
            var i = this.actionToSurveys.get(t.name);
            i && i.push(e.id), this.actionToSurveys.set(t.name, i || [e.id]);
          }
        }));
      });
    }
  }
  setupEventBasedSurveys(e) {
    var t;
    if (0 !== e.filter(e => {
      var t, i, r, s;
      return (null === (t = e.conditions) || void 0 === t ? void 0 : t.events) && (null === (i = e.conditions) || void 0 === i || null === (r = i.events) || void 0 === r || null === (s = r.values) || void 0 === s ? void 0 : s.length) > 0;
    }).length) {
      null === (t = this.instance) || void 0 === t || t._addCaptureHook((e, t) => {
        this.onEvent(e, t);
      }), e.forEach(e => {
        var t, i, r;
        null === (t = e.conditions) || void 0 === t || null === (i = t.events) || void 0 === i || null === (r = i.values) || void 0 === r || r.forEach(t => {
          if (t && t.name) {
            var i = this.eventToSurveys.get(t.name);
            i && i.push(e.id), this.eventToSurveys.set(t.name, i || [e.id]);
          }
        });
      });
    }
  }
  onEvent(e, t) {
    var i,
      r,
      s = (null === (i = this.instance) || void 0 === i || null === (r = i.persistence) || void 0 === r ? void 0 : r.props[Oe]) || [];
    if (In.SURVEY_SHOWN_EVENT_NAME == e && t && s.length > 0) {
      var n,
        o = null == t || null === (n = t.properties) || void 0 === n ? void 0 : n.$survey_id;
      if (o) {
        var a = s.indexOf(o);
        a >= 0 && (s.splice(a, 1), this._updateActivatedSurveys(s));
      }
    } else this.eventToSurveys.has(e) && this._updateActivatedSurveys(s.concat(this.eventToSurveys.get(e) || []));
  }
  onAction(e) {
    var t,
      i,
      r = (null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i ? void 0 : i.props[Oe]) || [];
    this.actionToSurveys.has(e) && this._updateActivatedSurveys(r.concat(this.actionToSurveys.get(e) || []));
  }
  _updateActivatedSurveys(e) {
    var t, i;
    null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i || i.register({
      [Oe]: [...new Set(e)]
    });
  }
  getSurveys() {
    var e,
      t,
      i = null === (e = this.instance) || void 0 === e || null === (t = e.persistence) || void 0 === t ? void 0 : t.props[Oe];
    return i || [];
  }
  getEventToSurveys() {
    return this.eventToSurveys;
  }
  _getActionMatcher() {
    return this.actionMatcher;
  }
}
oe(In, "SURVEY_SHOWN_EVENT_NAME", "survey shown");
var Cn = te("[Surveys]"),
  Pn = {
    icontains: (e, t) => e.some(e => t.toLowerCase().includes(e.toLowerCase())),
    not_icontains: (e, t) => e.every(e => !t.toLowerCase().includes(e.toLowerCase())),
    regex: (e, t) => e.some(e => A(t, e)),
    not_regex: (e, t) => e.every(e => !A(t, e)),
    exact: (e, t) => e.some(e => t === e),
    is_not: (e, t) => e.every(e => t !== e)
  };
function Fn(e, t, i) {
  var r,
    s = e.questions[t],
    n = t + 1;
  if (null === (r = s.branching) || void 0 === r || !r.type) return t === e.questions.length - 1 ? kn.End : n;
  if (s.branching.type === kn.End) return kn.End;
  if (s.branching.type === kn.SpecificQuestion) {
    if (Number.isInteger(s.branching.index)) return s.branching.index;
  } else if (s.branching.type === kn.ResponseBased) {
    if (s.type === Sn.SingleChoice) {
      var o,
        a,
        l = s.choices.indexOf("".concat(i));
      if (null !== (o = s.branching) && void 0 !== o && null !== (a = o.responseValues) && void 0 !== a && a.hasOwnProperty(l)) {
        var u = s.branching.responseValues[l];
        return Number.isInteger(u) ? u : u === kn.End ? kn.End : n;
      }
    } else if (s.type === Sn.Rating) {
      var c, d;
      if ("number" != typeof i || !Number.isInteger(i)) throw new Error("The response type must be an integer");
      var h = function (e, t) {
        if (3 === t) {
          if (e < 1 || e > 3) throw new Error("The response must be in range 1-3");
          return 1 === e ? "negative" : 2 === e ? "neutral" : "positive";
        }
        if (5 === t) {
          if (e < 1 || e > 5) throw new Error("The response must be in range 1-5");
          return e <= 2 ? "negative" : 3 === e ? "neutral" : "positive";
        }
        if (7 === t) {
          if (e < 1 || e > 7) throw new Error("The response must be in range 1-7");
          return e <= 3 ? "negative" : 4 === e ? "neutral" : "positive";
        }
        if (10 === t) {
          if (e < 0 || e > 10) throw new Error("The response must be in range 0-10");
          return e <= 6 ? "detractors" : e <= 8 ? "passives" : "promoters";
        }
        throw new Error("The scale must be one of: 3, 5, 7, 10");
      }(i, s.scale);
      if (null !== (c = s.branching) && void 0 !== c && null !== (d = c.responseValues) && void 0 !== d && d.hasOwnProperty(h)) {
        var _ = s.branching.responseValues[h];
        return Number.isInteger(_) ? _ : _ === kn.End ? kn.End : n;
      }
    }
    return n;
  }
  return Cn.warn("Falling back to next question index due to unexpected branching type"), n;
}
function Tn(e) {
  return null != e ? e : "icontains";
}
class Rn {
  constructor(e) {
    oe(this, "getNextSurveyStep", Fn), this.instance = e, this._surveyEventReceiver = null;
  }
  onRemoteConfig(e) {
    this._decideServerResponse = !!e.surveys, Cn.info("decideServerResponse set to ".concat(this._decideServerResponse)), this.loadIfEnabled();
  }
  reset() {
    localStorage.removeItem("lastSeenSurveyDate");
    var e = (() => {
      for (var e = [], t = 0; t < localStorage.length; t++) {
        var i = localStorage.key(t);
        null != i && i.startsWith("seenSurvey_") && e.push(i);
      }
      return e;
    })();
    e.forEach(e => localStorage.removeItem(e));
  }
  loadIfEnabled() {
    if (!this._surveyManager) if (this.instance.config.disable_surveys) Cn.info("Disabled. Not loading surveys.");else {
      var e = null == _ ? void 0 : _.__PosthogExtensions__;
      if (e) {
        var t = e.generateSurveys;
        if (this._decideServerResponse) {
          if (null == this._surveyEventReceiver && (this._surveyEventReceiver = new In(this.instance)), t) this._surveyManager = t(this.instance);else {
            var i = e.loadExternalDependency;
            i ? i(this.instance, "surveys", t => {
              var i;
              t ? Cn.error("Could not load surveys script", t) : this._surveyManager = null === (i = e.generateSurveys) || void 0 === i ? void 0 : i.call(e, this.instance);
            }) : Cn.error("PostHog loadExternalDependency extension not found. Cannot load remote config.");
          }
        } else Cn.warn("Decide not loaded yet. Not loading surveys.");
      } else Cn.error("PostHog Extensions not found.");
    }
  }
  getSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    if (this.instance.config.disable_surveys) return Cn.info("Disabled. Not loading surveys."), e([]);
    null == this._surveyEventReceiver && (this._surveyEventReceiver = new In(this.instance));
    var i = this.instance.get_property($e);
    if (i && !t) return e(i);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", "/api/surveys/?token=".concat(this.instance.config.token)),
      method: "GET",
      callback: t => {
        var i,
          r = t.statusCode;
        if (200 !== r || !t.json) return Cn.error("Surveys API could not be loaded, status: ".concat(r)), e([]);
        var s,
          n = t.json.surveys || [],
          o = n.filter(e => {
            var t, i, r, s, n, o, a, l, u, c, d, h;
            return (null === (t = e.conditions) || void 0 === t ? void 0 : t.events) && (null === (i = e.conditions) || void 0 === i || null === (r = i.events) || void 0 === r ? void 0 : r.values) && (null === (s = e.conditions) || void 0 === s || null === (n = s.events) || void 0 === n || null === (o = n.values) || void 0 === o ? void 0 : o.length) > 0 || (null === (a = e.conditions) || void 0 === a ? void 0 : a.actions) && (null === (l = e.conditions) || void 0 === l || null === (u = l.actions) || void 0 === u ? void 0 : u.values) && (null === (c = e.conditions) || void 0 === c || null === (d = c.actions) || void 0 === d || null === (h = d.values) || void 0 === h ? void 0 : h.length) > 0;
          });
        o.length > 0 && (null === (s = this._surveyEventReceiver) || void 0 === s || s.register(o));
        return null === (i = this.instance.persistence) || void 0 === i || i.register({
          [$e]: n
        }), e(n);
      }
    });
  }
  getActiveMatchingSurveys(e) {
    var i = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.getSurveys(i => {
      var r,
        s = i.filter(e => !(!e.start_date || e.end_date)).filter(e => {
          var i;
          if (!e.conditions) return !0;
          var r = function (e) {
              var i, r, s;
              if (null === (i = e.conditions) || void 0 === i || !i.url) return !0;
              var n = null == t || null === (r = t.location) || void 0 === r ? void 0 : r.href;
              if (!n) return !1;
              var o = [e.conditions.url];
              return Pn[Tn(null === (s = e.conditions) || void 0 === s ? void 0 : s.urlMatchType)](o, n);
            }(e),
            s = null === (i = e.conditions) || void 0 === i || !i.selector || (null == a ? void 0 : a.querySelector(e.conditions.selector)),
            n = function (e) {
              var t, i;
              if (null === (t = e.conditions) || void 0 === t || !t.deviceTypes) return !0;
              if (!h) return !1;
              var r = Ei.deviceType(h);
              return Pn[Tn(null === (i = e.conditions) || void 0 === i ? void 0 : i.deviceTypesMatchType)](e.conditions.deviceTypes, r);
            }(e);
          return r && s && n;
        }),
        n = null === (r = this._surveyEventReceiver) || void 0 === r ? void 0 : r.getSurveys(),
        o = s.filter(e => {
          var t, i, r, s, o, a, l, u, c, d, h;
          if (!(e.linked_flag_key || e.targeting_flag_key || e.internal_targeting_flag_key || null !== (t = e.feature_flag_keys) && void 0 !== t && t.length)) return !0;
          var _ = !e.linked_flag_key || this.instance.featureFlags.isFeatureEnabled(e.linked_flag_key),
            p = !e.targeting_flag_key || this.instance.featureFlags.isFeatureEnabled(e.targeting_flag_key),
            v = (null === (i = e.conditions) || void 0 === i ? void 0 : i.events) && (null === (r = e.conditions) || void 0 === r || null === (s = r.events) || void 0 === s ? void 0 : s.values) && (null === (o = e.conditions) || void 0 === o || null === (a = o.events) || void 0 === a ? void 0 : a.values.length) > 0,
            g = (null === (l = e.conditions) || void 0 === l ? void 0 : l.actions) && (null === (u = e.conditions) || void 0 === u || null === (c = u.actions) || void 0 === c ? void 0 : c.values) && (null === (d = e.conditions) || void 0 === d || null === (h = d.actions) || void 0 === h ? void 0 : h.values.length) > 0,
            f = !v && !g || (null == n ? void 0 : n.includes(e.id)),
            m = this._canActivateRepeatedly(e),
            b = !(e.internal_targeting_flag_key && !m) || this.instance.featureFlags.isFeatureEnabled(e.internal_targeting_flag_key),
            y = this.checkFlags(e);
          return _ && p && b && f && y;
        });
      return e(o);
    }, i);
  }
  checkFlags(e) {
    var t;
    return null === (t = e.feature_flag_keys) || void 0 === t || !t.length || e.feature_flag_keys.every(e => {
      var {
        key: t,
        value: i
      } = e;
      return !t || !i || this.instance.featureFlags.isFeatureEnabled(i);
    });
  }
  _canActivateRepeatedly(e) {
    var t;
    return J(null === (t = _.__PosthogExtensions__) || void 0 === t ? void 0 : t.canActivateRepeatedly) ? (Cn.warn("init was not called"), !1) : _.__PosthogExtensions__.canActivateRepeatedly(e);
  }
  canRenderSurvey(e) {
    J(this._surveyManager) ? Cn.warn("init was not called") : this.getSurveys(t => {
      var i = t.filter(t => t.id === e)[0];
      this._surveyManager.canRenderSurvey(i);
    });
  }
  renderSurvey(e, t) {
    J(this._surveyManager) ? Cn.warn("init was not called") : this.getSurveys(i => {
      var r = i.filter(t => t.id === e)[0];
      this._surveyManager.renderSurvey(r, null == a ? void 0 : a.querySelector(t));
    });
  }
}
var $n = te("[RateLimiter]");
class On {
  constructor(e) {
    var t, i;
    oe(this, "serverLimits", {}), oe(this, "lastEventRateLimited", !1), oe(this, "checkForLimiting", e => {
      var t = e.text;
      if (t && t.length) try {
        (JSON.parse(t).quota_limited || []).forEach(e => {
          $n.info("".concat(e || "events", " is quota limited.")), this.serverLimits[e] = new Date().getTime() + 6e4;
        });
      } catch (e) {
        return void $n.warn('could not rate limit - continuing. Error: "'.concat(null == e ? void 0 : e.message, '"'), {
          text: t
        });
      }
    }), this.instance = e, this.captureEventsPerSecond = (null === (t = e.config.rate_limiting) || void 0 === t ? void 0 : t.events_per_second) || 10, this.captureEventsBurstLimit = Math.max((null === (i = e.config.rate_limiting) || void 0 === i ? void 0 : i.events_burst_limit) || 10 * this.captureEventsPerSecond, this.captureEventsPerSecond), this.lastEventRateLimited = this.clientRateLimitContext(!0).isRateLimited;
  }
  clientRateLimitContext() {
    var e,
      t,
      i,
      r = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      s = new Date().getTime(),
      n = null !== (e = null === (t = this.instance.persistence) || void 0 === t ? void 0 : t.get_property(De)) && void 0 !== e ? e : {
        tokens: this.captureEventsBurstLimit,
        last: s
      };
    n.tokens += (s - n.last) / 1e3 * this.captureEventsPerSecond, n.last = s, n.tokens > this.captureEventsBurstLimit && (n.tokens = this.captureEventsBurstLimit);
    var o = n.tokens < 1;
    return o || r || (n.tokens = Math.max(0, n.tokens - 1)), !o || this.lastEventRateLimited || r || this.instance.capture("$$client_ingestion_warning", {
      $$client_ingestion_warning_message: "posthog-js client rate limited. Config is set to ".concat(this.captureEventsPerSecond, " events per second and ").concat(this.captureEventsBurstLimit, " events burst limit.")
    }, {
      skip_client_rate_limiting: !0
    }), this.lastEventRateLimited = o, null === (i = this.instance.persistence) || void 0 === i || i.set_property(De, n), {
      isRateLimited: o,
      remainingTokens: n.tokens
    };
  }
  isServerRateLimited(e) {
    var t = this.serverLimits[e || "events"] || !1;
    return !1 !== t && new Date().getTime() < t;
  }
}
var Mn = e => {
  var t = null == e ? void 0 : e.config;
  return ne({
    initialPathName: (null == l ? void 0 : l.pathname) || "",
    referringDomain: Ei.referringDomain()
  }, Ei.campaignParams({
    customTrackedParams: null == t ? void 0 : t.custom_campaign_params,
    maskPersonalDataProperties: null == t ? void 0 : t.mask_personal_data_properties,
    customPersonalDataProperties: null == t ? void 0 : t.custom_personal_data_properties
  }));
};
class An {
  constructor(e, t, i, r) {
    oe(this, "_onSessionIdCallback", e => {
      var t = this._getStoredProps();
      if (!t || t.sessionId !== e) {
        var i = {
          sessionId: e,
          props: this._sessionSourceParamGenerator(this.instance)
        };
        this._persistence.register({
          [Le]: i
        });
      }
    }), this.instance = e, this._sessionIdManager = t, this._persistence = i, this._sessionSourceParamGenerator = r || Mn, this._sessionIdManager.onSessionId(this._onSessionIdCallback);
  }
  _getStoredProps() {
    return this._persistence.props[Le];
  }
  getSessionProps() {
    var e,
      t = null === (e = this._getStoredProps()) || void 0 === e ? void 0 : e.props;
    return t ? {
      $client_session_initial_referring_host: t.referringDomain,
      $client_session_initial_pathname: t.initialPathName,
      $client_session_initial_utm_source: t.utm_source,
      $client_session_initial_utm_campaign: t.utm_campaign,
      $client_session_initial_utm_medium: t.utm_medium,
      $client_session_initial_utm_content: t.utm_content,
      $client_session_initial_utm_term: t.utm_term
    } : {};
  }
}
var Ln = ["ahrefsbot", "ahrefssiteaudit", "applebot", "baiduspider", "better uptime bot", "bingbot", "bingpreview", "bot.htm", "bot.php", "crawler", "deepscan", "duckduckbot", "facebookexternal", "facebookcatalog", "http://yandex.com/bots", "hubspot", "ia_archiver", "linkedinbot", "mj12bot", "msnbot", "nessus", "petalbot", "pinterest", "prerender", "rogerbot", "screaming frog", "semrushbot", "sitebulb", "slurp", "turnitin", "twitterbot", "vercelbot", "yahoo! slurp", "yandexbot", "gptbot", "oai-searchbot", "chatgpt-user", "headlesschrome", "cypress", "Google-HotelAdsVerifier", "adsbot-google", "apis-google", "duplexweb-google", "feedfetcher-google", "google favicon", "google web preview", "google-read-aloud", "googlebot", "googleweblight", "mediapartners-google", "storebot-google", "Bytespider;"],
  Dn = function (e, t) {
    if (!e) return !1;
    var i = e.toLowerCase();
    return Ln.concat(t || []).some(e => {
      var t = e.toLowerCase();
      return -1 !== i.indexOf(t);
    });
  },
  Nn = function (e, t) {
    if (!e) return !1;
    var i = e.userAgent;
    if (i && Dn(i, t)) return !0;
    try {
      var r = null == e ? void 0 : e.userAgentData;
      if (null != r && r.brands && r.brands.some(e => Dn(null == e ? void 0 : e.brand, t))) return !0;
    } catch (e) {}
    return !!e.webdriver;
  };
class qn {
  constructor() {
    this.clicks = [];
  }
  isRageClick(e, t, i) {
    var r = this.clicks[this.clicks.length - 1];
    if (r && Math.abs(e - r.x) + Math.abs(t - r.y) < 30 && i - r.timestamp < 1e3) {
      if (this.clicks.push({
        x: e,
        y: t,
        timestamp: i
      }), 3 === this.clicks.length) return !0;
    } else this.clicks = [{
      x: e,
      y: t,
      timestamp: i
    }];
    return !1;
  }
}
var Bn = te("[Dead Clicks]"),
  Hn = () => !0,
  Un = e => {
    var t,
      i = !(null === (t = e.instance.persistence) || void 0 === t || !t.get_property(ve)),
      r = e.instance.config.capture_dead_clicks;
    return K(r) ? r : i;
  };
class jn {
  get lazyLoadedDeadClicksAutocapture() {
    return this._lazyLoadedDeadClicksAutocapture;
  }
  constructor(e, t, i) {
    this.instance = e, this.isEnabled = t, this.onCapture = i, this.startIfEnabled();
  }
  onRemoteConfig(e) {
    this.instance.persistence && this.instance.persistence.register({
      [ve]: null == e ? void 0 : e.captureDeadClicks
    }), this.startIfEnabled();
  }
  startIfEnabled() {
    this.isEnabled(this) && this.loadScript(() => {
      this.start();
    });
  }
  loadScript(e) {
    var t, i, r;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.initDeadClicksAutocapture && e(), null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "dead-clicks-autocapture", t => {
      t ? Bn.error("failed to load script", t) : e();
    });
  }
  start() {
    var e;
    if (a) {
      if (!this._lazyLoadedDeadClicksAutocapture && null !== (e = _.__PosthogExtensions__) && void 0 !== e && e.initDeadClicksAutocapture) {
        var t = U(this.instance.config.capture_dead_clicks) ? this.instance.config.capture_dead_clicks : {};
        t.__onCapture = this.onCapture, this._lazyLoadedDeadClicksAutocapture = _.__PosthogExtensions__.initDeadClicksAutocapture(this.instance, t), this._lazyLoadedDeadClicksAutocapture.start(a), Bn.info("starting...");
      }
    } else Bn.error("`document` not found. Cannot start.");
  }
  stop() {
    this._lazyLoadedDeadClicksAutocapture && (this._lazyLoadedDeadClicksAutocapture.stop(), this._lazyLoadedDeadClicksAutocapture = void 0, Bn.info("stopping..."));
  }
}
var zn = te("[Heatmaps]");
function Wn(e) {
  return U(e) && "clientX" in e && "clientY" in e && Y(e.clientX) && Y(e.clientY);
}
class Vn {
  constructor(e) {
    var i;
    oe(this, "rageclicks", new qn()), oe(this, "_enabledServerSide", !1), oe(this, "_initialized", !1), oe(this, "_flushInterval", null), this.instance = e, this._enabledServerSide = !(null === (i = this.instance.persistence) || void 0 === i || !i.props[he]), R(t, "beforeunload", this.flush);
  }
  get flushIntervalMilliseconds() {
    var e = 5e3;
    return U(this.instance.config.capture_heatmaps) && this.instance.config.capture_heatmaps.flush_interval_milliseconds && (e = this.instance.config.capture_heatmaps.flush_interval_milliseconds), e;
  }
  get isEnabled() {
    return z(this.instance.config.capture_heatmaps) ? z(this.instance.config.enable_heatmaps) ? this._enabledServerSide : this.instance.config.enable_heatmaps : !1 !== this.instance.config.capture_heatmaps;
  }
  startIfEnabled() {
    if (this.isEnabled) {
      if (this._initialized) return;
      zn.info("starting..."), this._setupListeners(), this._flushInterval = setInterval(this.flush.bind(this), this.flushIntervalMilliseconds);
    } else {
      var e, t;
      clearInterval(null !== (e = this._flushInterval) && void 0 !== e ? e : void 0), null === (t = this.deadClicksCapture) || void 0 === t || t.stop(), this.getAndClearBuffer();
    }
  }
  onRemoteConfig(e) {
    var t = !!e.heatmaps;
    this.instance.persistence && this.instance.persistence.register({
      [he]: t
    }), this._enabledServerSide = t, this.startIfEnabled();
  }
  getAndClearBuffer() {
    var e = this.buffer;
    return this.buffer = void 0, e;
  }
  _onDeadClick(e) {
    this._onClick(e.originalEvent, "deadclick");
  }
  _setupListeners() {
    t && a && (R(a, "click", e => this._onClick(e || (null == t ? void 0 : t.event)), {
      capture: !0
    }), R(a, "mousemove", e => this._onMouseMove(e || (null == t ? void 0 : t.event)), {
      capture: !0
    }), this.deadClicksCapture = new jn(this.instance, Hn, this._onDeadClick.bind(this)), this.deadClicksCapture.startIfEnabled(), this._initialized = !0);
  }
  _getProperties(e, i) {
    var r = this.instance.scrollManager.scrollY(),
      s = this.instance.scrollManager.scrollX(),
      n = this.instance.scrollManager.scrollElement(),
      o = function (e, i, r) {
        for (var s = e; s && $i(s) && !Oi(s, "body");) {
          if (s === r) return !1;
          if ($(i, null == t ? void 0 : t.getComputedStyle(s).position)) return !0;
          s = ji(s);
        }
        return !1;
      }(Hi(e), ["fixed", "sticky"], n);
    return {
      x: e.clientX + (o ? 0 : s),
      y: e.clientY + (o ? 0 : r),
      target_fixed: o,
      type: i
    };
  }
  _onClick(e) {
    var t,
      i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "click";
    if (!Ri(e.target) && Wn(e)) {
      var r = this._getProperties(e, i);
      null !== (t = this.rageclicks) && void 0 !== t && t.isRageClick(e.clientX, e.clientY, new Date().getTime()) && this._capture(ne(ne({}, r), {}, {
        type: "rageclick"
      })), this._capture(r);
    }
  }
  _onMouseMove(e) {
    !Ri(e.target) && Wn(e) && (clearTimeout(this._mouseMoveTimeout), this._mouseMoveTimeout = setTimeout(() => {
      this._capture(this._getProperties(e, "mousemove"));
    }, 500));
  }
  _capture(e) {
    if (t) {
      var i = t.location.href;
      this.buffer = this.buffer || {}, this.buffer[i] || (this.buffer[i] = []), this.buffer[i].push(e);
    }
  }
  flush() {
    this.buffer && !j(this.buffer) && this.instance.capture("$$heatmap", {
      $heatmap_data: this.getAndClearBuffer()
    });
  }
}
class Gn {
  constructor(e) {
    oe(this, "_updateScrollData", () => {
      var e, t, i, r;
      this.context || (this.context = {});
      var s = this.scrollElement(),
        n = this.scrollY(),
        o = s ? Math.max(0, s.scrollHeight - s.clientHeight) : 0,
        a = n + ((null == s ? void 0 : s.clientHeight) || 0),
        l = (null == s ? void 0 : s.scrollHeight) || 0;
      this.context.lastScrollY = Math.ceil(n), this.context.maxScrollY = Math.max(n, null !== (e = this.context.maxScrollY) && void 0 !== e ? e : 0), this.context.maxScrollHeight = Math.max(o, null !== (t = this.context.maxScrollHeight) && void 0 !== t ? t : 0), this.context.lastContentY = a, this.context.maxContentY = Math.max(a, null !== (i = this.context.maxContentY) && void 0 !== i ? i : 0), this.context.maxContentHeight = Math.max(l, null !== (r = this.context.maxContentHeight) && void 0 !== r ? r : 0);
    }), this.instance = e;
  }
  getContext() {
    return this.context;
  }
  resetContext() {
    var e = this.context;
    return setTimeout(this._updateScrollData, 0), e;
  }
  startMeasuringScrollPosition() {
    R(t, "scroll", this._updateScrollData, {
      capture: !0
    }), R(t, "scrollend", this._updateScrollData, {
      capture: !0
    }), R(t, "resize", this._updateScrollData);
  }
  scrollElement() {
    if (!this.instance.config.scroll_root_selector) return null == t ? void 0 : t.document.documentElement;
    var e = B(this.instance.config.scroll_root_selector) ? this.instance.config.scroll_root_selector : [this.instance.config.scroll_root_selector];
    for (var i of e) {
      var r = null == t ? void 0 : t.document.querySelector(i);
      if (r) return r;
    }
  }
  scrollY() {
    if (this.instance.config.scroll_root_selector) {
      var e = this.scrollElement();
      return e && e.scrollTop || 0;
    }
    return t && (t.scrollY || t.pageYOffset || t.document.documentElement.scrollTop) || 0;
  }
  scrollX() {
    if (this.instance.config.scroll_root_selector) {
      var e = this.scrollElement();
      return e && e.scrollLeft || 0;
    }
    return t && (t.scrollX || t.pageXOffset || t.document.documentElement.scrollLeft) || 0;
  }
}
var Jn = te("[AutoCapture]");
function Yn(e, t) {
  return t.length > e ? t.slice(0, e) + "..." : t;
}
function Kn(e) {
  if (e.previousElementSibling) return e.previousElementSibling;
  var t = e;
  do {
    t = t.previousSibling;
  } while (t && !$i(t));
  return t;
}
function Xn(e, t, i, r) {
  var s = e.tagName.toLowerCase(),
    n = {
      tag_name: s
    };
  Ui.indexOf(s) > -1 && !i && ("a" === s.toLowerCase() || "button" === s.toLowerCase() ? n.$el_text = Yn(1024, er(e)) : n.$el_text = Yn(1024, Bi(e)));
  var o = Ni(e);
  o.length > 0 && (n.classes = o.filter(function (e) {
    return "" !== e;
  })), y(e.attributes, function (i) {
    var s;
    if ((!Vi(e) || -1 !== ["name", "id", "class", "aria-label"].indexOf(i.name)) && (null == r || !r.includes(i.name)) && !t && Zi(i.value) && (s = i.name, !W(s) || "_ngcontent" !== s.substring(0, 10) && "_nghost" !== s.substring(0, 7))) {
      var o = i.value;
      "class" === i.name && (o = Li(o).join(" ")), n["attr__" + i.name] = Yn(1024, o);
    }
  });
  for (var a = 1, l = 1, u = e; u = Kn(u);) a++, u.tagName === e.tagName && l++;
  return n.nth_child = a, n.nth_of_type = l, n;
}
function Qn(e, i) {
  for (var r, s, {
      e: n,
      maskAllElementAttributes: o,
      maskAllText: a,
      elementAttributeIgnoreList: l,
      elementsChainAsString: u
    } = i, c = [e], d = e; d.parentNode && !Oi(d, "body");) Ai(d.parentNode) ? (c.push(d.parentNode.host), d = d.parentNode.host) : (c.push(d.parentNode), d = d.parentNode);
  var h,
    _ = [],
    p = {},
    v = !1,
    g = !1;
  if (y(c, e => {
    var t = Wi(e);
    "a" === e.tagName.toLowerCase() && (v = e.getAttribute("href"), v = t && v && Zi(v) && v), $(Ni(e), "ph-no-capture") && (g = !0), _.push(Xn(e, o, a, l));
    var i = function (e) {
      if (!Wi(e)) return {};
      var t = {};
      return y(e.attributes, function (e) {
        if (e.name && 0 === e.name.indexOf("data-ph-capture-attribute")) {
          var i = e.name.replace("data-ph-capture-attribute-", ""),
            r = e.value;
          i && r && Zi(r) && (t[i] = r);
        }
      }), t;
    }(e);
    w(p, i);
  }), g) return {
    props: {},
    explicitNoCapture: g
  };
  if (a || ("a" === e.tagName.toLowerCase() || "button" === e.tagName.toLowerCase() ? _[0].$el_text = er(e) : _[0].$el_text = Bi(e)), v) {
    var f, m;
    _[0].attr__href = v;
    var b = null === (f = yt(v)) || void 0 === f ? void 0 : f.host,
      S = null == t || null === (m = t.location) || void 0 === m ? void 0 : m.host;
    b && S && b !== S && (h = v);
  }
  return {
    props: w({
      $event_type: n.type,
      $ce_version: 1
    }, u ? {} : {
      $elements: _
    }, {
      $elements_chain: ir(_)
    }, null !== (r = _[0]) && void 0 !== r && r.$el_text ? {
      $el_text: null === (s = _[0]) || void 0 === s ? void 0 : s.$el_text
    } : {}, h && "click" === n.type ? {
      $external_click_url: h
    } : {}, p)
  };
}
class Zn {
  constructor(e) {
    oe(this, "_initialized", !1), oe(this, "_isDisabledServerSide", null), oe(this, "rageclicks", new qn()), oe(this, "_elementsChainAsString", !1), this.instance = e, this._elementSelectors = null;
  }
  get config() {
    var e,
      t,
      i = U(this.instance.config.autocapture) ? this.instance.config.autocapture : {};
    return i.url_allowlist = null === (e = i.url_allowlist) || void 0 === e ? void 0 : e.map(e => new RegExp(e)), i.url_ignorelist = null === (t = i.url_ignorelist) || void 0 === t ? void 0 : t.map(e => new RegExp(e)), i;
  }
  _addDomEventHandlers() {
    if (this.isBrowserSupported()) {
      if (t && a) {
        var e = e => {
          e = e || (null == t ? void 0 : t.event);
          try {
            this._captureEvent(e);
          } catch (e) {
            Jn.error("Failed to capture event", e);
          }
        };
        if (R(a, "submit", e, {
          capture: !0
        }), R(a, "change", e, {
          capture: !0
        }), R(a, "click", e, {
          capture: !0
        }), this.config.capture_copied_text) {
          var i = e => {
            e = e || (null == t ? void 0 : t.event), this._captureEvent(e, v);
          };
          R(a, "copy", i, {
            capture: !0
          }), R(a, "cut", i, {
            capture: !0
          });
        }
      }
    } else Jn.info("Disabling Automatic Event Collection because this browser is not supported");
  }
  startIfEnabled() {
    this.isEnabled && !this._initialized && (this._addDomEventHandlers(), this._initialized = !0);
  }
  onRemoteConfig(e) {
    e.elementsChainAsString && (this._elementsChainAsString = e.elementsChainAsString), this.instance.persistence && this.instance.persistence.register({
      [de]: !!e.autocapture_opt_out
    }), this._isDisabledServerSide = !!e.autocapture_opt_out, this.startIfEnabled();
  }
  setElementSelectors(e) {
    this._elementSelectors = e;
  }
  getElementSelectors(e) {
    var t,
      i = [];
    return null === (t = this._elementSelectors) || void 0 === t || t.forEach(t => {
      var r = null == a ? void 0 : a.querySelectorAll(t);
      null == r || r.forEach(r => {
        e === r && i.push(t);
      });
    }), i;
  }
  get isEnabled() {
    var e,
      t,
      i = null === (e = this.instance.persistence) || void 0 === e ? void 0 : e.props[de],
      r = this._isDisabledServerSide;
    if (G(r) && !K(i) && !this.instance.config.advanced_disable_decide) return !1;
    var s = null !== (t = this._isDisabledServerSide) && void 0 !== t ? t : !!i;
    return !!this.instance.config.autocapture && !s;
  }
  _captureEvent(e) {
    var i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "$autocapture";
    if (this.isEnabled) {
      var r,
        s = Hi(e);
      if (Mi(s) && (s = s.parentNode || null), "$autocapture" === i && "click" === e.type && e instanceof MouseEvent) this.instance.config.rageclick && null !== (r = this.rageclicks) && void 0 !== r && r.isRageClick(e.clientX, e.clientY, new Date().getTime()) && this._captureEvent(e, "$rageclick");
      var n = i === v;
      if (s && zi(s, e, this.config, n, n ? ["copy", "cut"] : void 0)) {
        var {
          props: o,
          explicitNoCapture: a
        } = Qn(s, {
          e: e,
          maskAllElementAttributes: this.instance.config.mask_all_element_attributes,
          maskAllText: this.instance.config.mask_all_text,
          elementAttributeIgnoreList: this.config.element_attribute_ignorelist,
          elementsChainAsString: this._elementsChainAsString
        });
        if (a) return !1;
        var l = this.getElementSelectors(s);
        if (l && l.length > 0 && (o.$element_selectors = l), i === v) {
          var u,
            c = qi(null == t || null === (u = t.getSelection()) || void 0 === u ? void 0 : u.toString()),
            d = e.type || "clipboard";
          if (!c) return !1;
          o.$selected_content = c, o.$copy_type = d;
        }
        return this.instance.capture(i, o), !0;
      }
    }
  }
  isBrowserSupported() {
    return H(null == a ? void 0 : a.querySelectorAll);
  }
}
var eo = te("[TracingHeaders]");
class to {
  constructor(e) {
    oe(this, "_restoreXHRPatch", void 0), oe(this, "_restoreFetchPatch", void 0), oe(this, "_startCapturing", () => {
      var e, t, i, r;
      z(this._restoreXHRPatch) && (null === (e = _.__PosthogExtensions__) || void 0 === e || null === (t = e.tracingHeadersPatchFns) || void 0 === t || t._patchXHR(this.instance.sessionManager));
      z(this._restoreFetchPatch) && (null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.tracingHeadersPatchFns) || void 0 === r || r._patchFetch(this.instance.sessionManager));
    }), this.instance = e;
  }
  _loadScript(e) {
    var t, i, r;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.tracingHeadersPatchFns && e(), null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "tracing-headers", t => {
      if (t) return eo.error("failed to load script", t);
      e();
    });
  }
  startIfEnabledOrStop() {
    var e, t;
    this.instance.config.__add_tracing_headers ? this._loadScript(this._startCapturing) : (null === (e = this._restoreXHRPatch) || void 0 === e || e.call(this), null === (t = this._restoreFetchPatch) || void 0 === t || t.call(this), this._restoreXHRPatch = void 0, this._restoreFetchPatch = void 0);
  }
}
var io;
!function (e) {
  e[e.PENDING = -1] = "PENDING", e[e.DENIED = 0] = "DENIED", e[e.GRANTED = 1] = "GRANTED";
}(io || (io = {}));
class ro {
  constructor(e) {
    this.instance = e;
  }
  get config() {
    return this.instance.config;
  }
  get consent() {
    return this.getDnt() ? io.DENIED : this.storedConsent;
  }
  isOptedOut() {
    return this.consent === io.DENIED || this.consent === io.PENDING && this.config.opt_out_capturing_by_default;
  }
  isOptedIn() {
    return !this.isOptedOut();
  }
  optInOut(e) {
    this.storage.set(this.storageKey, e ? 1 : 0, this.config.cookie_expiration, this.config.cross_subdomain_cookie, this.config.secure_cookie);
  }
  reset() {
    this.storage.remove(this.storageKey, this.config.cross_subdomain_cookie);
  }
  get storageKey() {
    var {
      token: e,
      opt_out_capturing_cookie_prefix: t
    } = this.instance.config;
    return (t || "__ph_opt_in_out_") + e;
  }
  get storedConsent() {
    var e = this.storage.get(this.storageKey);
    return "1" === e ? io.GRANTED : "0" === e ? io.DENIED : io.PENDING;
  }
  get storage() {
    if (!this._storage) {
      var e = this.config.opt_out_capturing_persistence_type;
      this._storage = "localStorage" === e ? ht : ct;
      var t = "localStorage" === e ? ct : ht;
      t.get(this.storageKey) && (this._storage.get(this.storageKey) || this.optInOut("1" === t.get(this.storageKey)), t.remove(this.storageKey, this.config.cross_subdomain_cookie));
    }
    return this._storage;
  }
  getDnt() {
    return !!this.config.respect_dnt && !!T([null == o ? void 0 : o.doNotTrack, null == o ? void 0 : o.msDoNotTrack, _.doNotTrack], e => $([!0, 1, "1", "yes"], e));
  }
}
var so = te("[ExceptionAutocapture]");
class no {
  constructor(e) {
    var i;
    oe(this, "originalOnUnhandledRejectionHandler", void 0), oe(this, "startCapturing", () => {
      var e, i, r, s;
      if (t && this.isEnabled && !this.hasHandlers && !this.isCapturing) {
        var n = null === (e = _.__PosthogExtensions__) || void 0 === e || null === (i = e.errorWrappingFunctions) || void 0 === i ? void 0 : i.wrapOnError,
          o = null === (r = _.__PosthogExtensions__) || void 0 === r || null === (s = r.errorWrappingFunctions) || void 0 === s ? void 0 : s.wrapUnhandledRejection;
        if (n && o) try {
          this.unwrapOnError = n(this.captureException.bind(this)), this.unwrapUnhandledRejection = o(this.captureException.bind(this));
        } catch (e) {
          so.error("failed to start", e), this.stopCapturing();
        } else so.error("failed to load error wrapping functions - cannot start");
      }
    }), this.instance = e, this.remoteEnabled = !(null === (i = this.instance.persistence) || void 0 === i || !i.props[_e]), this.startIfEnabled();
  }
  get isEnabled() {
    var e;
    return null !== (e = this.remoteEnabled) && void 0 !== e && e;
  }
  get isCapturing() {
    var e;
    return !(null == t || null === (e = t.onerror) || void 0 === e || !e.__POSTHOG_INSTRUMENTED__);
  }
  get hasHandlers() {
    return this.originalOnUnhandledRejectionHandler || this.unwrapOnError;
  }
  startIfEnabled() {
    this.isEnabled && !this.isCapturing && (so.info("enabled, starting..."), this.loadScript(this.startCapturing));
  }
  loadScript(e) {
    var t, i;
    this.hasHandlers && e(), null === (t = _.__PosthogExtensions__) || void 0 === t || null === (i = t.loadExternalDependency) || void 0 === i || i.call(t, this.instance, "exception-autocapture", t => {
      if (t) return so.error("failed to load script", t);
      e();
    });
  }
  stopCapturing() {
    var e, t;
    null === (e = this.unwrapOnError) || void 0 === e || e.call(this), null === (t = this.unwrapUnhandledRejection) || void 0 === t || t.call(this);
  }
  onRemoteConfig(e) {
    var t = e.autocaptureExceptions;
    this.remoteEnabled = !!t || !1, this.instance.persistence && this.instance.persistence.register({
      [_e]: this.remoteEnabled
    }), this.startIfEnabled();
  }
  captureException(e) {
    var t = this.instance.requestRouter.endpointFor("ui");
    e.$exception_personURL = "".concat(t, "/project/").concat(this.instance.config.token, "/person/").concat(this.instance.get_distinct_id()), this.instance.exceptions.sendExceptionEvent(e);
  }
}
var oo = te("[Web Vitals]"),
  ao = 9e5;
class lo {
  constructor(e) {
    var t;
    oe(this, "_enabledServerSide", !1), oe(this, "_initialized", !1), oe(this, "buffer", {
      url: void 0,
      metrics: [],
      firstMetricTimestamp: void 0
    }), oe(this, "_flushToCapture", () => {
      clearTimeout(this._delayedFlushTimer), 0 !== this.buffer.metrics.length && (this.instance.capture("$web_vitals", this.buffer.metrics.reduce((e, t) => ne(ne({}, e), {}, {
        ["$web_vitals_".concat(t.name, "_event")]: ne({}, t),
        ["$web_vitals_".concat(t.name, "_value")]: t.value
      }), {})), this.buffer = {
        url: void 0,
        metrics: [],
        firstMetricTimestamp: void 0
      });
    }), oe(this, "_addToBuffer", e => {
      var t,
        i = null === (t = this.instance.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0);
      if (z(i)) oo.error("Could not read session ID. Dropping metrics!");else {
        this.buffer = this.buffer || {
          url: void 0,
          metrics: [],
          firstMetricTimestamp: void 0
        };
        var r = this._currentURL();
        if (!z(r)) if (J(null == e ? void 0 : e.name) || J(null == e ? void 0 : e.value)) oo.error("Invalid metric received", e);else if (this._maxAllowedValue && e.value >= this._maxAllowedValue) oo.error("Ignoring metric with value >= " + this._maxAllowedValue, e);else this.buffer.url !== r && (this._flushToCapture(), this._delayedFlushTimer = setTimeout(this._flushToCapture, this.flushToCaptureTimeoutMs)), z(this.buffer.url) && (this.buffer.url = r), this.buffer.firstMetricTimestamp = z(this.buffer.firstMetricTimestamp) ? Date.now() : this.buffer.firstMetricTimestamp, e.attribution && e.attribution.interactionTargetElement && (e.attribution.interactionTargetElement = void 0), this.buffer.metrics.push(ne(ne({}, e), {}, {
          $current_url: r,
          $session_id: i.sessionId,
          $window_id: i.windowId,
          timestamp: Date.now()
        })), this.buffer.metrics.length === this.allowedMetrics.length && this._flushToCapture();
      }
    }), oe(this, "_startCapturing", () => {
      var e,
        t,
        i,
        r,
        s = _.__PosthogExtensions__;
      z(s) || z(s.postHogWebVitalsCallbacks) || ({
        onLCP: e,
        onCLS: t,
        onFCP: i,
        onINP: r
      } = s.postHogWebVitalsCallbacks), e && t && i && r ? (this.allowedMetrics.indexOf("LCP") > -1 && e(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("CLS") > -1 && t(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("FCP") > -1 && i(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("INP") > -1 && r(this._addToBuffer.bind(this)), this._initialized = !0) : oo.error("web vitals callbacks not loaded - not starting");
    }), this.instance = e, this._enabledServerSide = !(null === (t = this.instance.persistence) || void 0 === t || !t.props[pe]), this.startIfEnabled();
  }
  get allowedMetrics() {
    var e,
      t,
      i = U(this.instance.config.capture_performance) ? null === (e = this.instance.config.capture_performance) || void 0 === e ? void 0 : e.web_vitals_allowed_metrics : void 0;
    return z(i) ? (null === (t = this.instance.persistence) || void 0 === t ? void 0 : t.props[ge]) || ["CLS", "FCP", "INP", "LCP"] : i;
  }
  get flushToCaptureTimeoutMs() {
    return (U(this.instance.config.capture_performance) ? this.instance.config.capture_performance.web_vitals_delayed_flush_ms : void 0) || 5e3;
  }
  get _maxAllowedValue() {
    var e = U(this.instance.config.capture_performance) && Y(this.instance.config.capture_performance.__web_vitals_max_value) ? this.instance.config.capture_performance.__web_vitals_max_value : ao;
    return 0 < e && e <= 6e4 ? ao : e;
  }
  get isEnabled() {
    var e = U(this.instance.config.capture_performance) ? this.instance.config.capture_performance.web_vitals : void 0;
    return K(e) ? e : this._enabledServerSide;
  }
  startIfEnabled() {
    this.isEnabled && !this._initialized && (oo.info("enabled, starting..."), this.loadScript(this._startCapturing));
  }
  onRemoteConfig(e) {
    var t = U(e.capturePerformance) && !!e.capturePerformance.web_vitals,
      i = U(e.capturePerformance) ? e.capturePerformance.web_vitals_allowed_metrics : void 0;
    this.instance.persistence && (this.instance.persistence.register({
      [pe]: t
    }), this.instance.persistence.register({
      [ge]: i
    })), this._enabledServerSide = t, this.startIfEnabled();
  }
  loadScript(e) {
    var t, i, r;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.postHogWebVitalsCallbacks && e(), null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "web-vitals", t => {
      t ? oo.error("failed to load script", t) : e();
    });
  }
  _currentURL() {
    var e = t ? t.location.href : void 0;
    return e || oo.error("Could not determine current URL"), e;
  }
}
var uo = {
  icontains: (e, i) => !!t && i.href.toLowerCase().indexOf(e.toLowerCase()) > -1,
  not_icontains: (e, i) => !!t && -1 === i.href.toLowerCase().indexOf(e.toLowerCase()),
  regex: (e, i) => !!t && A(i.href, e),
  not_regex: (e, i) => !!t && !A(i.href, e),
  exact: (e, t) => t.href === e,
  is_not: (e, t) => t.href !== e
};
class co {
  constructor(e) {
    var t = this;
    oe(this, "getWebExperimentsAndEvaluateDisplayLogic", function () {
      var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
      t.getWebExperiments(e => {
        co.logInfo("retrieved web experiments from the server"), t._flagToExperiments = new Map(), e.forEach(e => {
          if (e.feature_flag_key) {
            var i;
            if (t._flagToExperiments) co.logInfo("setting flag key ", e.feature_flag_key, " to web experiment ", e), null === (i = t._flagToExperiments) || void 0 === i || i.set(e.feature_flag_key, e);
            var r = t.instance.getFeatureFlag(e.feature_flag_key);
            W(r) && e.variants[r] && t.applyTransforms(e.name, r, e.variants[r].transforms);
          } else if (e.variants) for (var s in e.variants) {
            var n = e.variants[s];
            co.matchesTestVariant(n) && t.applyTransforms(e.name, s, n.transforms);
          }
        });
      }, e);
    }), this.instance = e, this.instance.onFeatureFlags(e => {
      this.onFeatureFlags(e);
    });
  }
  onFeatureFlags(e) {
    if (this._is_bot()) co.logInfo("Refusing to render web experiment since the viewer is a likely bot");else if (!this.instance.config.disable_web_experiments) {
      if (J(this._flagToExperiments)) return this._flagToExperiments = new Map(), this.loadIfEnabled(), void this.previewWebExperiment();
      co.logInfo("applying feature flags", e), e.forEach(e => {
        var t;
        if (this._flagToExperiments && null !== (t = this._flagToExperiments) && void 0 !== t && t.has(e)) {
          var i,
            r = this.instance.getFeatureFlag(e),
            s = null === (i = this._flagToExperiments) || void 0 === i ? void 0 : i.get(e);
          r && null != s && s.variants[r] && this.applyTransforms(s.name, r, s.variants[r].transforms);
        }
      });
    }
  }
  previewWebExperiment() {
    var e = co.getWindowLocation();
    if (null != e && e.search) {
      var t = St(null == e ? void 0 : e.search, "__experiment_id"),
        i = St(null == e ? void 0 : e.search, "__experiment_variant");
      t && i && (co.logInfo("previewing web experiments ".concat(t, " && ").concat(i)), this.getWebExperiments(e => {
        this.showPreviewWebExperiment(parseInt(t), i, e);
      }, !1, !0));
    }
  }
  loadIfEnabled() {
    this.instance.config.disable_web_experiments || this.getWebExperimentsAndEvaluateDisplayLogic();
  }
  getWebExperiments(e, t, i) {
    if (this.instance.config.disable_web_experiments && !i) return e([]);
    var r = this.instance.get_property("$web_experiments");
    if (r && !t) return e(r);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", "/api/web_experiments/?token=".concat(this.instance.config.token)),
      method: "GET",
      callback: t => {
        if (200 !== t.statusCode || !t.json) return e([]);
        var i = t.json.experiments || [];
        return e(i);
      }
    });
  }
  showPreviewWebExperiment(e, t, i) {
    var r = i.filter(t => t.id === e);
    r && r.length > 0 && (co.logInfo("Previewing web experiment [".concat(r[0].name, "] with variant [").concat(t, "]")), this.applyTransforms(r[0].name, t, r[0].variants[t].transforms));
  }
  static matchesTestVariant(e) {
    return !J(e.conditions) && co.matchUrlConditions(e) && co.matchUTMConditions(e);
  }
  static matchUrlConditions(e) {
    var t;
    if (J(e.conditions) || J(null === (t = e.conditions) || void 0 === t ? void 0 : t.url)) return !0;
    var i,
      r,
      s,
      n = co.getWindowLocation();
    return !!n && (null === (i = e.conditions) || void 0 === i || !i.url || uo[null !== (r = null === (s = e.conditions) || void 0 === s ? void 0 : s.urlMatchType) && void 0 !== r ? r : "icontains"](e.conditions.url, n));
  }
  static getWindowLocation() {
    return null == t ? void 0 : t.location;
  }
  static matchUTMConditions(e) {
    var t;
    if (J(e.conditions) || J(null === (t = e.conditions) || void 0 === t ? void 0 : t.utm)) return !0;
    var i = Ei.campaignParams();
    if (i.utm_source) {
      var r,
        s,
        n,
        o,
        a,
        l,
        u,
        c,
        d,
        h,
        _,
        p,
        v,
        g,
        f,
        m,
        b = null === (r = e.conditions) || void 0 === r || null === (s = r.utm) || void 0 === s || !s.utm_campaign || (null === (n = e.conditions) || void 0 === n || null === (o = n.utm) || void 0 === o ? void 0 : o.utm_campaign) == i.utm_campaign,
        y = null === (a = e.conditions) || void 0 === a || null === (l = a.utm) || void 0 === l || !l.utm_source || (null === (u = e.conditions) || void 0 === u || null === (c = u.utm) || void 0 === c ? void 0 : c.utm_source) == i.utm_source,
        w = null === (d = e.conditions) || void 0 === d || null === (h = d.utm) || void 0 === h || !h.utm_medium || (null === (_ = e.conditions) || void 0 === _ || null === (p = _.utm) || void 0 === p ? void 0 : p.utm_medium) == i.utm_medium,
        S = null === (v = e.conditions) || void 0 === v || null === (g = v.utm) || void 0 === g || !g.utm_term || (null === (f = e.conditions) || void 0 === f || null === (m = f.utm) || void 0 === m ? void 0 : m.utm_term) == i.utm_term;
      return b && w && S && y;
    }
    return !1;
  }
  static logInfo(e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) i[r - 1] = arguments[r];
    ee.info("[WebExperiments] ".concat(e), i);
  }
  applyTransforms(e, t, i) {
    this._is_bot() ? co.logInfo("Refusing to render web experiment since the viewer is a likely bot") : "control" !== t ? i.forEach(i => {
      if (i.selector) {
        var r;
        co.logInfo("applying transform of variant ".concat(t, " for experiment ").concat(e, " "), i);
        var s = null === (r = document) || void 0 === r ? void 0 : r.querySelectorAll(i.selector);
        null == s || s.forEach(e => {
          var t = e;
          i.attributes && i.attributes.forEach(e => {
            switch (e.name) {
              case "text":
                t.innerText = e.value;
                break;
              case "html":
                t.innerHTML = e.value;
                break;
              case "cssClass":
                t.className = e.value;
                break;
              default:
                t.setAttribute(e.name, e.value);
            }
          }), i.text && (t.innerText = i.text), i.html && (t.parentElement ? t.parentElement.innerHTML = i.html : t.innerHTML = i.html), i.css && t.setAttribute("style", i.css);
        });
      }
    }) : co.logInfo("Control variants leave the page unmodified.");
  }
  _is_bot() {
    return o && this.instance ? Nn(o, this.instance.config.custom_blocked_useragents) : void 0;
  }
}
class ho {
  constructor(e) {
    this.instance = e;
  }
  sendExceptionEvent(e) {
    this.instance.capture("$exception", e, {
      _noTruncate: !0,
      _batchKey: "exceptionEvent"
    });
  }
}
var _o = ["$set_once", "$set"],
  po = te("[SiteApps]");
class vo {
  constructor(e) {
    this.instance = e, this.bufferedInvocations = [], this.apps = {};
  }
  get isEnabled() {
    return !!this.instance.config.opt_in_site_apps;
  }
  eventCollector(e, t) {
    if (t) {
      var i = this.globalsForEvent(t);
      this.bufferedInvocations.push(i), this.bufferedInvocations.length > 1e3 && (this.bufferedInvocations = this.bufferedInvocations.slice(10));
    }
  }
  get siteAppLoaders() {
    var e, t;
    return null === (e = _._POSTHOG_REMOTE_CONFIG) || void 0 === e || null === (t = e[this.instance.config.token]) || void 0 === t ? void 0 : t.siteApps;
  }
  init() {
    if (this.isEnabled) {
      var e = this.instance._addCaptureHook(this.eventCollector.bind(this));
      this.stopBuffering = () => {
        e(), this.bufferedInvocations = [], this.stopBuffering = void 0;
      };
    }
  }
  globalsForEvent(e) {
    var t, i, r, s, n, o, a;
    if (!e) throw new Error("Event payload is required");
    var l = {},
      u = this.instance.get_property("$groups") || [],
      c = this.instance.get_property("$stored_group_properties") || {};
    for (var [d, h] of Object.entries(c)) l[d] = {
      id: u[d],
      type: d,
      properties: h
    };
    var {
      $set_once: _,
      $set: p
    } = e;
    return {
      event: ne(ne({}, ae(e, _o)), {}, {
        properties: ne(ne(ne({}, e.properties), p ? {
          $set: ne(ne({}, null !== (t = null === (i = e.properties) || void 0 === i ? void 0 : i.$set) && void 0 !== t ? t : {}), p)
        } : {}), _ ? {
          $set_once: ne(ne({}, null !== (r = null === (s = e.properties) || void 0 === s ? void 0 : s.$set_once) && void 0 !== r ? r : {}), _)
        } : {}),
        elements_chain: null !== (n = null === (o = e.properties) || void 0 === o ? void 0 : o.$elements_chain) && void 0 !== n ? n : "",
        distinct_id: null === (a = e.properties) || void 0 === a ? void 0 : a.distinct_id
      }),
      person: {
        properties: this.instance.get_property("$stored_person_properties")
      },
      groups: l
    };
  }
  setupSiteApp(e) {
    var t = {
      id: e.id,
      loaded: !1,
      errored: !1
    };
    this.apps[e.id] = t;
    var i = i => {
      var r;
      for (var s of (this.apps[e.id].errored = !i, this.apps[e.id].loaded = !0, po.info("Site app with id ".concat(e.id, " ").concat(i ? "loaded" : "errored")), i && this.bufferedInvocations.length && (po.info("Processing ".concat(this.bufferedInvocations.length, " events for site app with id ").concat(e.id)), this.bufferedInvocations.forEach(e => {
        var i;
        return null === (i = t.processEvent) || void 0 === i ? void 0 : i.call(t, e);
      })), Object.values(this.apps))) if (!s.loaded) return;
      null === (r = this.stopBuffering) || void 0 === r || r.call(this);
    };
    try {
      var {
        processEvent: r
      } = e.init({
        posthog: this.instance,
        callback: e => {
          i(e);
        }
      });
      r && (t.processEvent = r);
    } catch (t) {
      po.error("Error while initializing PostHog app with config id ".concat(e.id), t), i(!1);
    }
  }
  onCapturedEvent(e) {
    if (0 !== Object.keys(this.apps).length) {
      var t = this.globalsForEvent(e);
      for (var i of Object.values(this.apps)) try {
        var r;
        null === (r = i.processEvent) || void 0 === r || r.call(i, t);
      } catch (t) {
        po.error("Error while processing event ".concat(e.event, " for site app ").concat(i.id), t);
      }
    }
  }
  onRemoteConfig(e) {
    var t,
      i,
      r,
      s = this;
    if (null !== (t = this.siteAppLoaders) && void 0 !== t && t.length) {
      if (!this.isEnabled) return void po.error('PostHog site apps are disabled. Enable the "opt_in_site_apps" config to proceed.');
      for (var n of this.siteAppLoaders) this.setupSiteApp(n);
      this.instance.on("eventCaptured", e => this.onCapturedEvent(e));
    } else if (null === (i = this.stopBuffering) || void 0 === i || i.call(this), null !== (r = e.siteApps) && void 0 !== r && r.length) if (this.isEnabled) {
      var o = function (e, t) {
        var i, r;
        _["__$$ph_site_app_".concat(e)] = s.instance, null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadSiteApp) || void 0 === r || r.call(i, s.instance, t, t => {
          if (t) return po.error("Error while initializing PostHog app with config id ".concat(e), t);
        });
      };
      for (var {
        id: a,
        url: l
      } of e.siteApps) o(a, l);
    } else po.error('PostHog site apps are disabled. Enable the "opt_in_site_apps" config to proceed.');
  }
}
function go(e, t, i) {
  return ks({
    distinct_id: e,
    userPropertiesToSet: t,
    userPropertiesToSetOnce: i
  });
}
var fo = {},
  mo = () => {},
  bo = "posthog",
  yo = !ys && -1 === (null == h ? void 0 : h.indexOf("MSIE")) && -1 === (null == h ? void 0 : h.indexOf("Mozilla")),
  wo = () => {
    var e;
    return {
      api_host: "https://us.i.posthog.com",
      ui_host: null,
      token: "",
      autocapture: !0,
      rageclick: !0,
      cross_subdomain_cookie: F(null == a ? void 0 : a.location),
      persistence: "localStorage+cookie",
      persistence_name: "",
      loaded: mo,
      save_campaign_params: !0,
      custom_campaign_params: [],
      custom_blocked_useragents: [],
      save_referrer: !0,
      capture_pageview: !0,
      capture_pageleave: "if_capture_pageview",
      debug: l && W(null == l ? void 0 : l.search) && -1 !== l.search.indexOf("__posthog_debug=true") || !1,
      cookie_expiration: 365,
      upgrade: !1,
      disable_session_recording: !1,
      disable_persistence: !1,
      disable_web_experiments: !0,
      disable_surveys: !1,
      disable_external_dependency_loading: !1,
      enable_recording_console_log: void 0,
      secure_cookie: "https:" === (null == t || null === (e = t.location) || void 0 === e ? void 0 : e.protocol),
      ip: !0,
      opt_out_capturing_by_default: !1,
      opt_out_persistence_by_default: !1,
      opt_out_useragent_filter: !1,
      opt_out_capturing_persistence_type: "localStorage",
      opt_out_capturing_cookie_prefix: null,
      opt_in_site_apps: !1,
      property_denylist: [],
      respect_dnt: !1,
      sanitize_properties: null,
      request_headers: {},
      request_batching: !0,
      properties_string_max_length: 65535,
      session_recording: {},
      mask_all_element_attributes: !1,
      mask_all_text: !1,
      mask_personal_data_properties: !1,
      custom_personal_data_properties: [],
      advanced_disable_decide: !1,
      advanced_disable_feature_flags: !1,
      advanced_disable_feature_flags_on_first_load: !1,
      advanced_disable_toolbar_metrics: !1,
      feature_flag_request_timeout_ms: 3e3,
      on_request_error: e => {
        var t = "Bad HTTP status: " + e.statusCode + " " + e.text;
        ee.error(t);
      },
      get_device_id: e => e,
      capture_performance: void 0,
      name: "posthog",
      bootstrap: {},
      disable_compression: !1,
      session_idle_timeout_seconds: 1800,
      person_profiles: "identified_only",
      before_send: void 0,
      request_queue_config: {
        flush_interval_ms: fs
      },
      _onCapture: mo
    };
  },
  So = e => {
    var t = {};
    z(e.process_person) || (t.person_profiles = e.process_person), z(e.xhr_headers) || (t.request_headers = e.xhr_headers), z(e.cookie_name) || (t.persistence_name = e.cookie_name), z(e.disable_cookie) || (t.disable_persistence = e.disable_cookie), z(e.store_google) || (t.save_campaign_params = e.store_google), z(e.verbose) || (t.debug = e.verbose);
    var i = w({}, t, e);
    return B(e.property_blacklist) && (z(e.property_denylist) ? i.property_denylist = e.property_blacklist : B(e.property_denylist) ? i.property_denylist = [...e.property_blacklist, ...e.property_denylist] : ee.error("Invalid value for property_denylist config: " + e.property_denylist)), i;
  };
class ko {
  constructor() {
    oe(this, "__forceAllowLocalhost", !1);
  }
  get _forceAllowLocalhost() {
    return this.__forceAllowLocalhost;
  }
  set _forceAllowLocalhost(e) {
    ee.error("WebPerformanceObserver is deprecated and has no impact on network capture. Use `_forceAllowLocalhostNetworkCapture` on `posthog.sessionRecording`"), this.__forceAllowLocalhost = e;
  }
}
class Eo {
  get decideEndpointWasHit() {
    var e, t;
    return null !== (e = null === (t = this.featureFlags) || void 0 === t ? void 0 : t.hasLoadedFlags) && void 0 !== e && e;
  }
  constructor() {
    oe(this, "webPerformance", new ko()), oe(this, "version", p.LIB_VERSION), oe(this, "_internalEventEmitter", new En()), this.config = wo(), this.SentryIntegration = As, this.sentryIntegration = e => function (e, t) {
      var i = Ms(e, t);
      return {
        name: Os,
        processEvent: e => i(e)
      };
    }(this, e), this.__request_queue = [], this.__loaded = !1, this.analyticsDefaultEndpoint = "/e/", this._initialPageviewCaptured = !1, this._initialPersonProfilesConfig = null, this._cachedIdentify = null, this.featureFlags = new Xe(this), this.toolbar = new gs(this), this.scrollManager = new Gn(this), this.pageViewManager = new Ns(this), this.surveys = new Rn(this), this.experiments = new co(this), this.exceptions = new ho(this), this.rateLimiter = new On(this), this.requestRouter = new $s(this), this.consent = new ro(this), this.people = {
      set: (e, t, i) => {
        var r = W(e) ? {
          [e]: t
        } : e;
        this.setPersonProperties(r), null == i || i({});
      },
      set_once: (e, t, i) => {
        var r = W(e) ? {
          [e]: t
        } : e;
        this.setPersonProperties(void 0, r), null == i || i({});
      }
    }, this.on("eventCaptured", e => ee.info('send "'.concat(null == e ? void 0 : e.event, '"'), e));
  }
  init(e, t, i) {
    if (i && i !== bo) {
      var r,
        s = null !== (r = fo[i]) && void 0 !== r ? r : new Eo();
      return s._init(e, t, i), fo[i] = s, fo[bo][i] = s, s;
    }
    return this._init(e, t, i);
  }
  _init(i) {
    var r,
      s,
      n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
      o = arguments.length > 2 ? arguments[2] : void 0;
    if (z(i) || V(i)) return ee.critical("PostHog was initialized without a token. This likely indicates a misconfiguration. Please check the first argument passed to posthog.init()"), this;
    if (this.__loaded) return ee.warn("You have already initialized PostHog! Re-initializing is a no-op"), this;
    this.__loaded = !0, this.config = {}, this._triggered_notifs = [], n.person_profiles && (this._initialPersonProfilesConfig = n.person_profiles), this.set_config(w({}, wo(), So(n), {
      name: o,
      token: i
    })), this.config.on_xhr_error && ee.error("on_xhr_error is deprecated. Use on_request_error instead"), this.compression = n.disable_compression ? void 0 : e.GZipJS, this.persistence = new Ii(this.config), this.sessionPersistence = "sessionStorage" === this.config.persistence || "memory" === this.config.persistence ? this.persistence : new Ii(ne(ne({}, this.config), {}, {
      persistence: "sessionStorage"
    }));
    var a = ne({}, this.persistence.props),
      l = ne({}, this.sessionPersistence.props);
    if (this._requestQueue = new ms(e => this._send_retriable_request(e), this.config.request_queue_config), this._retryQueue = new Cs(this), this.__request_queue = [], this.config.__preview_experimental_cookieless_mode || (this.sessionManager = new Ts(this), this.sessionPropsManager = new An(this, this.sessionManager, this.persistence)), new to(this).startIfEnabledOrStop(), this.siteApps = new vo(this), null === (r = this.siteApps) || void 0 === r || r.init(), this.config.__preview_experimental_cookieless_mode || (this.sessionRecording = new us(this), this.sessionRecording.startIfEnabledOrStop()), this.config.disable_scroll_properties || this.scrollManager.startMeasuringScrollPosition(), this.autocapture = new Zn(this), this.autocapture.startIfEnabled(), this.surveys.loadIfEnabled(), this.heatmaps = new Vn(this), this.heatmaps.startIfEnabled(), this.webVitalsAutocapture = new lo(this), this.exceptionObserver = new no(this), this.exceptionObserver.startIfEnabled(), this.deadClicksAutocapture = new jn(this, Un), this.deadClicksAutocapture.startIfEnabled(), p.DEBUG = p.DEBUG || this.config.debug, p.DEBUG && ee.info("Starting in debug mode", {
      this: this,
      config: n,
      thisC: ne({}, this.config),
      p: a,
      s: l
    }), this._sync_opt_out_with_persistence(), void 0 !== (null === (s = n.bootstrap) || void 0 === s ? void 0 : s.distinctID)) {
      var u,
        c,
        d = this.config.get_device_id(st()),
        h = null !== (u = n.bootstrap) && void 0 !== u && u.isIdentifiedID ? d : n.bootstrap.distinctID;
      this.persistence.set_property(Ae, null !== (c = n.bootstrap) && void 0 !== c && c.isIdentifiedID ? "identified" : "anonymous"), this.register({
        distinct_id: n.bootstrap.distinctID,
        $device_id: h
      });
    }
    if (this._hasBootstrappedFeatureFlags()) {
      var _,
        v,
        g = Object.keys((null === (_ = n.bootstrap) || void 0 === _ ? void 0 : _.featureFlags) || {}).filter(e => {
          var t, i;
          return !(null === (t = n.bootstrap) || void 0 === t || null === (i = t.featureFlags) || void 0 === i || !i[e]);
        }).reduce((e, t) => {
          var i, r;
          return e[t] = (null === (i = n.bootstrap) || void 0 === i || null === (r = i.featureFlags) || void 0 === r ? void 0 : r[t]) || !1, e;
        }, {}),
        f = Object.keys((null === (v = n.bootstrap) || void 0 === v ? void 0 : v.featureFlagPayloads) || {}).filter(e => g[e]).reduce((e, t) => {
          var i, r, s, o;
          null !== (i = n.bootstrap) && void 0 !== i && null !== (r = i.featureFlagPayloads) && void 0 !== r && r[t] && (e[t] = null === (s = n.bootstrap) || void 0 === s || null === (o = s.featureFlagPayloads) || void 0 === o ? void 0 : o[t]);
          return e;
        }, {});
      this.featureFlags.receivedFeatureFlags({
        featureFlags: g,
        featureFlagPayloads: f
      });
    }
    if (this.config.__preview_experimental_cookieless_mode) this.register_once({
      distinct_id: je,
      $device_id: null
    }, "");else if (!this.get_distinct_id()) {
      var m = this.config.get_device_id(st());
      this.register_once({
        distinct_id: m,
        $device_id: m
      }, ""), this.persistence.set_property(Ae, "anonymous");
    }
    return R(t, "onpagehide" in self ? "pagehide" : "unload", this._handle_unload.bind(this), {
      passive: !1
    }), this.toolbar.maybeLoadToolbar(), n.segment ? Ds(this, () => this._loaded()) : this._loaded(), H(this.config._onCapture) && this.config._onCapture !== mo && (ee.warn("onCapture is deprecated. Please use `before_send` instead"), this.on("eventCaptured", e => this.config._onCapture(e.event, e))), this;
  }
  _onRemoteConfig(t) {
    var i, r, s, n, o, l, u, c;
    if (!a || !a.body) return ee.info("document not ready yet, trying again in 500 milliseconds..."), void setTimeout(() => {
      this._onRemoteConfig(t);
    }, 500);
    this.compression = void 0, t.supportedCompression && !this.config.disable_compression && (this.compression = $(t.supportedCompression, e.GZipJS) ? e.GZipJS : $(t.supportedCompression, e.Base64) ? e.Base64 : void 0), null !== (i = t.analytics) && void 0 !== i && i.endpoint && (this.analyticsDefaultEndpoint = t.analytics.endpoint), this.set_config({
      person_profiles: this._initialPersonProfilesConfig ? this._initialPersonProfilesConfig : "identified_only"
    }), null === (r = this.siteApps) || void 0 === r || r.onRemoteConfig(t), null === (s = this.sessionRecording) || void 0 === s || s.onRemoteConfig(t), null === (n = this.autocapture) || void 0 === n || n.onRemoteConfig(t), null === (o = this.heatmaps) || void 0 === o || o.onRemoteConfig(t), this.surveys.onRemoteConfig(t), null === (l = this.webVitalsAutocapture) || void 0 === l || l.onRemoteConfig(t), null === (u = this.exceptionObserver) || void 0 === u || u.onRemoteConfig(t), null === (c = this.deadClicksAutocapture) || void 0 === c || c.onRemoteConfig(t);
  }
  _loaded() {
    try {
      this.config.loaded(this);
    } catch (e) {
      ee.critical("`loaded` function failed", e);
    }
    this._start_queue_if_opted_in(), this.config.capture_pageview && setTimeout(() => {
      this.consent.isOptedIn() && this._captureInitialPageview();
    }, 1), new ds(this).load(), this.featureFlags.decide();
  }
  _start_queue_if_opted_in() {
    var e;
    this.has_opted_out_capturing() || this.config.request_batching && (null === (e = this._requestQueue) || void 0 === e || e.enable());
  }
  _dom_loaded() {
    this.has_opted_out_capturing() || b(this.__request_queue, e => this._send_retriable_request(e)), this.__request_queue = [], this._start_queue_if_opted_in();
  }
  _handle_unload() {
    var e, t;
    this.config.request_batching ? (this._shouldCapturePageleave() && this.capture("$pageleave"), null === (e = this._requestQueue) || void 0 === e || e.unload(), null === (t = this._retryQueue) || void 0 === t || t.unload()) : this._shouldCapturePageleave() && this.capture("$pageleave", null, {
      transport: "sendBeacon"
    });
  }
  _send_request(e) {
    this.__loaded && (yo ? this.__request_queue.push(e) : this.rateLimiter.isServerRateLimited(e.batchKey) || (e.transport = e.transport || this.config.api_transport, e.url = Ss(e.url, {
      ip: this.config.ip ? 1 : 0
    }), e.headers = ne({}, this.config.request_headers), e.compression = "best-available" === e.compression ? this.compression : e.compression, e.fetchOptions = e.fetchOptions || this.config.fetch_options, (e => {
      var t,
        i,
        r,
        s = ne({}, e);
      s.timeout = s.timeout || 6e4, s.url = Ss(s.url, {
        _: new Date().getTime().toString(),
        ver: p.LIB_VERSION,
        compression: s.compression
      });
      var n = null !== (t = s.transport) && void 0 !== t ? t : "fetch",
        o = null !== (i = null === (r = T(xs, e => e.transport === n)) || void 0 === r ? void 0 : r.method) && void 0 !== i ? i : xs[0].method;
      if (!o) throw new Error("No available transport method");
      o(s);
    })(ne(ne({}, e), {}, {
      callback: t => {
        var i, r, s;
        (this.rateLimiter.checkForLimiting(t), t.statusCode >= 400) && (null === (r = (s = this.config).on_request_error) || void 0 === r || r.call(s, t));
        null === (i = e.callback) || void 0 === i || i.call(e, t);
      }
    }))));
  }
  _send_retriable_request(e) {
    this._retryQueue ? this._retryQueue.retriableRequest(e) : this._send_request(e);
  }
  _execute_array(e) {
    var t,
      i = [],
      r = [],
      s = [];
    b(e, e => {
      e && (t = e[0], B(t) ? s.push(e) : H(e) ? e.call(this) : B(e) && "alias" === t ? i.push(e) : B(e) && -1 !== t.indexOf("capture") && H(this[t]) ? s.push(e) : r.push(e));
    });
    var n = function (e, t) {
      b(e, function (e) {
        if (B(e[0])) {
          var i = t;
          y(e, function (e) {
            i = i[e[0]].apply(i, e.slice(1));
          });
        } else this[e[0]].apply(this, e.slice(1));
      }, t);
    };
    n(i, this), n(r, this), n(s, this);
  }
  _hasBootstrappedFeatureFlags() {
    var e, t;
    return (null === (e = this.config.bootstrap) || void 0 === e ? void 0 : e.featureFlags) && Object.keys(null === (t = this.config.bootstrap) || void 0 === t ? void 0 : t.featureFlags).length > 0 || !1;
  }
  push(e) {
    this._execute_array([e]);
  }
  capture(e, t, i) {
    var r;
    if (this.__loaded && this.persistence && this.sessionPersistence && this._requestQueue) {
      if (!this.consent.isOptedOut()) if (!z(e) && W(e)) {
        if (this.config.opt_out_useragent_filter || !this._is_bot()) {
          var s = null != i && i.skip_client_rate_limiting ? void 0 : this.rateLimiter.clientRateLimitContext();
          if (null == s || !s.isRateLimited) {
            this.sessionPersistence.update_search_keyword(), this.config.save_campaign_params && this.sessionPersistence.update_campaign_params(), this.config.save_referrer && this.sessionPersistence.update_referrer_info(), (this.config.save_campaign_params || this.config.save_referrer) && this.persistence.set_initial_person_info();
            var n = new Date(),
              o = (null == i ? void 0 : i.timestamp) || n,
              a = st(),
              l = {
                uuid: a,
                event: e,
                properties: this._calculate_event_properties(e, t || {}, o, a)
              };
            s && (l.properties.$lib_rate_limit_remaining_tokens = s.remainingTokens), (null == i ? void 0 : i.$set) && (l.$set = null == i ? void 0 : i.$set);
            var u = this._calculate_set_once_properties(null == i ? void 0 : i.$set_once);
            u && (l.$set_once = u), (l = C(l, null != i && i._noTruncate ? null : this.config.properties_string_max_length)).timestamp = o, z(null == i ? void 0 : i.timestamp) || (l.properties.$event_time_override_provided = !0, l.properties.$event_time_override_system_time = n);
            var c = ne(ne({}, l.properties.$set), l.$set);
            if (j(c) || this.setPersonPropertiesForFlags(c), !J(this.config.before_send)) {
              var d = this._runBeforeSend(l);
              if (!d) return;
              l = d;
            }
            this._internalEventEmitter.emit("eventCaptured", l);
            var h = {
              method: "POST",
              url: null !== (r = null == i ? void 0 : i._url) && void 0 !== r ? r : this.requestRouter.endpointFor("api", this.analyticsDefaultEndpoint),
              data: l,
              compression: "best-available",
              batchKey: null == i ? void 0 : i._batchKey
            };
            return !this.config.request_batching || i && (null == i || !i._batchKey) || null != i && i.send_instantly ? this._send_retriable_request(h) : this._requestQueue.enqueue(h), l;
          }
          ee.critical("This capture call is ignored due to client rate limiting.");
        }
      } else ee.error("No event name provided to posthog.capture");
    } else ee.uninitializedWarning("posthog.capture");
  }
  _addCaptureHook(e) {
    return this.on("eventCaptured", t => e(t.event, t));
  }
  _calculate_event_properties(e, t, i, r) {
    if (i = i || new Date(), !this.persistence || !this.sessionPersistence) return t;
    var s = this.persistence.remove_event_timer(e),
      n = ne({}, t);
    if (n.token = this.config.token, this.config.__preview_experimental_cookieless_mode && (n.$cookieless_mode = !0), "$snapshot" === e) {
      var o = ne(ne({}, this.persistence.properties()), this.sessionPersistence.properties());
      return n.distinct_id = o.distinct_id, (!W(n.distinct_id) && !Y(n.distinct_id) || V(n.distinct_id)) && ee.error("Invalid distinct_id for replay event. This indicates a bug in your implementation"), n;
    }
    var l,
      u = Ei.properties({
        maskPersonalDataProperties: this.config.mask_personal_data_properties,
        customPersonalDataProperties: this.config.custom_personal_data_properties
      });
    if (this.sessionManager) {
      var {
        sessionId: c,
        windowId: d
      } = this.sessionManager.checkAndGetSessionAndWindowId();
      n.$session_id = c, n.$window_id = d;
    }
    if (this.sessionRecording && (n.$recording_status = this.sessionRecording.status), this.requestRouter.region === Ps.CUSTOM && (n.$lib_custom_api_host = this.config.api_host), l = "$pageview" === e ? this.pageViewManager.doPageView(i, r) : "$pageleave" === e ? this.pageViewManager.doPageLeave(i) : this.pageViewManager.doEvent(), n = w(n, l), "$pageview" === e && a && (n.title = a.title), !z(s)) {
      var _ = i.getTime() - s;
      n.$duration = parseFloat((_ / 1e3).toFixed(3));
    }
    h && this.config.opt_out_useragent_filter && (n.$browser_type = this._is_bot() ? "bot" : "browser"), (n = w({}, u, this.persistence.properties(), this.sessionPersistence.properties(), n)).$is_identified = this._isIdentified(), B(this.config.property_denylist) ? y(this.config.property_denylist, function (e) {
      delete n[e];
    }) : ee.error("Invalid value for property_denylist config: " + this.config.property_denylist + " or property_blacklist config: " + this.config.property_blacklist);
    var p = this.config.sanitize_properties;
    p && (ee.error("sanitize_properties is deprecated. Use before_send instead"), n = p(n, e));
    var v = this._hasPersonProcessing();
    return n.$process_person_profile = v, v && this._requirePersonProcessing("_calculate_event_properties"), n;
  }
  _calculate_set_once_properties(e) {
    if (!this.persistence || !this._hasPersonProcessing()) return e;
    var t = w({}, this.persistence.get_initial_props(), e || {}),
      i = this.config.sanitize_properties;
    return i && (ee.error("sanitize_properties is deprecated. Use before_send instead"), t = i(t, "$set_once")), j(t) ? void 0 : t;
  }
  register(e, t) {
    var i;
    null === (i = this.persistence) || void 0 === i || i.register(e, t);
  }
  register_once(e, t, i) {
    var r;
    null === (r = this.persistence) || void 0 === r || r.register_once(e, t, i);
  }
  register_for_session(e) {
    var t;
    null === (t = this.sessionPersistence) || void 0 === t || t.register(e);
  }
  unregister(e) {
    var t;
    null === (t = this.persistence) || void 0 === t || t.unregister(e);
  }
  unregister_for_session(e) {
    var t;
    null === (t = this.sessionPersistence) || void 0 === t || t.unregister(e);
  }
  _register_single(e, t) {
    this.register({
      [e]: t
    });
  }
  getFeatureFlag(e, t) {
    return this.featureFlags.getFeatureFlag(e, t);
  }
  getFeatureFlagPayload(e) {
    var t = this.featureFlags.getFeatureFlagPayload(e);
    try {
      return JSON.parse(t);
    } catch (e) {
      return t;
    }
  }
  isFeatureEnabled(e, t) {
    return this.featureFlags.isFeatureEnabled(e, t);
  }
  reloadFeatureFlags() {
    this.featureFlags.reloadFeatureFlags();
  }
  updateEarlyAccessFeatureEnrollment(e, t) {
    this.featureFlags.updateEarlyAccessFeatureEnrollment(e, t);
  }
  getEarlyAccessFeatures(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    return this.featureFlags.getEarlyAccessFeatures(e, t);
  }
  on(e, t) {
    return this._internalEventEmitter.on(e, t);
  }
  onFeatureFlags(e) {
    return this.featureFlags.onFeatureFlags(e);
  }
  onSessionId(e) {
    var t, i;
    return null !== (t = null === (i = this.sessionManager) || void 0 === i ? void 0 : i.onSessionId(e)) && void 0 !== t ? t : () => {};
  }
  getSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.surveys.getSurveys(e, t);
  }
  getActiveMatchingSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.surveys.getActiveMatchingSurveys(e, t);
  }
  renderSurvey(e, t) {
    this.surveys.renderSurvey(e, t);
  }
  canRenderSurvey(e) {
    this.surveys.canRenderSurvey(e);
  }
  getNextSurveyStep(e, t, i) {
    return this.surveys.getNextSurveyStep(e, t, i);
  }
  identify(e, t, i) {
    if (!this.__loaded || !this.persistence) return ee.uninitializedWarning("posthog.identify");
    if (Y(e) && (e = e.toString(), ee.warn("The first argument to posthog.identify was a number, but it should be a string. It has been converted to a string.")), e) {
      if (["distinct_id", "distinctid"].includes(e.toLowerCase())) ee.critical('The string "'.concat(e, '" was set in posthog.identify which indicates an error. This ID should be unique to the user and not a hardcoded string.'));else if (this._requirePersonProcessing("posthog.identify")) {
        var r = this.get_distinct_id();
        if (this.register({
          $user_id: e
        }), !this.get_property("$device_id")) {
          var s = r;
          this.register_once({
            $had_persisted_distinct_id: !0,
            $device_id: s
          }, "");
        }
        e !== r && e !== this.get_property(ue) && (this.unregister(ue), this.register({
          distinct_id: e
        }));
        var n = "anonymous" === (this.persistence.get_property(Ae) || "anonymous");
        e !== r && n ? (this.persistence.set_property(Ae, "identified"), this.setPersonPropertiesForFlags(t || {}, !1), this.capture("$identify", {
          distinct_id: e,
          $anon_distinct_id: r
        }, {
          $set: t || {},
          $set_once: i || {}
        }), this.featureFlags.setAnonymousDistinctId(r), this._cachedIdentify = go(e, t, i)) : (t || i) && (this._cachedIdentify !== go(e, t, i) ? (this.setPersonProperties(t, i), this._cachedIdentify = go(e, t, i)) : ee.info("A duplicate posthog.identify call was made with the same properties. It has been ignored.")), e !== r && (this.reloadFeatureFlags(), this.unregister(Me));
      }
    } else ee.error("Unique user id has not been set in posthog.identify");
  }
  setPersonProperties(e, t) {
    (e || t) && this._requirePersonProcessing("posthog.setPersonProperties") && (this.setPersonPropertiesForFlags(e || {}), this.capture("$set", {
      $set: e || {},
      $set_once: t || {}
    }));
  }
  group(e, t, i) {
    if (e && t) {
      if (this._requirePersonProcessing("posthog.group")) {
        var r = this.getGroups();
        r[e] !== t && this.resetGroupPropertiesForFlags(e), this.register({
          $groups: ne(ne({}, r), {}, {
            [e]: t
          })
        }), i && (this.capture("$groupidentify", {
          $group_type: e,
          $group_key: t,
          $group_set: i
        }), this.setGroupPropertiesForFlags({
          [e]: i
        })), r[e] === t || i || this.reloadFeatureFlags();
      }
    } else ee.error("posthog.group requires a group type and group key");
  }
  resetGroups() {
    this.register({
      $groups: {}
    }), this.resetGroupPropertiesForFlags(), this.reloadFeatureFlags();
  }
  setPersonPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    this.featureFlags.setPersonPropertiesForFlags(e, t);
  }
  resetPersonPropertiesForFlags() {
    this.featureFlags.resetPersonPropertiesForFlags();
  }
  setGroupPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    this._requirePersonProcessing("posthog.setGroupPropertiesForFlags") && this.featureFlags.setGroupPropertiesForFlags(e, t);
  }
  resetGroupPropertiesForFlags(e) {
    this.featureFlags.resetGroupPropertiesForFlags(e);
  }
  reset(e) {
    var t, i, r, s;
    if (ee.info("reset"), !this.__loaded) return ee.uninitializedWarning("posthog.reset");
    var n = this.get_property("$device_id");
    if (this.consent.reset(), null === (t = this.persistence) || void 0 === t || t.clear(), null === (i = this.sessionPersistence) || void 0 === i || i.clear(), this.surveys.reset(), null === (r = this.persistence) || void 0 === r || r.set_property(Ae, "anonymous"), null === (s = this.sessionManager) || void 0 === s || s.resetSessionId(), this._cachedIdentify = null, this.config.__preview_experimental_cookieless_mode) this.register_once({
      distinct_id: je,
      $device_id: null
    }, "");else {
      var o = this.config.get_device_id(st());
      this.register_once({
        distinct_id: o,
        $device_id: e ? o : n
      }, "");
    }
    this.register({
      $last_posthog_reset: new Date().toISOString()
    }, 1);
  }
  get_distinct_id() {
    return this.get_property("distinct_id");
  }
  getGroups() {
    return this.get_property("$groups") || {};
  }
  get_session_id() {
    var e, t;
    return null !== (e = null === (t = this.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0).sessionId) && void 0 !== e ? e : "";
  }
  get_session_replay_url(e) {
    if (!this.sessionManager) return "";
    var {
        sessionId: t,
        sessionStartTimestamp: i
      } = this.sessionManager.checkAndGetSessionAndWindowId(!0),
      r = this.requestRouter.endpointFor("ui", "/project/".concat(this.config.token, "/replay/").concat(t));
    if (null != e && e.withTimestamp && i) {
      var s,
        n = null !== (s = e.timestampLookBack) && void 0 !== s ? s : 10;
      if (!i) return r;
      var o = Math.max(Math.floor((new Date().getTime() - i) / 1e3) - n, 0);
      r += "?t=".concat(o);
    }
    return r;
  }
  alias(e, t) {
    return e === this.get_property(le) ? (ee.critical("Attempting to create alias for existing People user - aborting."), -2) : this._requirePersonProcessing("posthog.alias") ? (z(t) && (t = this.get_distinct_id()), e !== t ? (this._register_single(ue, e), this.capture("$create_alias", {
      alias: e,
      distinct_id: t
    })) : (ee.warn("alias matches current distinct_id - skipping api call."), this.identify(e), -1)) : void 0;
  }
  set_config(e) {
    var t,
      i,
      r,
      s,
      n = ne({}, this.config);
    U(e) && (w(this.config, So(e)), null === (t = this.persistence) || void 0 === t || t.update_config(this.config, n), this.sessionPersistence = "sessionStorage" === this.config.persistence || "memory" === this.config.persistence ? this.persistence : new Ii(ne(ne({}, this.config), {}, {
      persistence: "sessionStorage"
    })), ht.is_supported() && "true" === ht.get("ph_debug") && (this.config.debug = !0), this.config.debug && (p.DEBUG = !0, ee.info("set_config", {
      config: e,
      oldConfig: n,
      newConfig: ne({}, this.config)
    })), null === (i = this.sessionRecording) || void 0 === i || i.startIfEnabledOrStop(), null === (r = this.autocapture) || void 0 === r || r.startIfEnabled(), null === (s = this.heatmaps) || void 0 === s || s.startIfEnabled(), this.surveys.loadIfEnabled(), this._sync_opt_out_with_persistence());
  }
  startSessionRecording(e) {
    var t = !0 === e,
      i = {
        sampling: t || !(null == e || !e.sampling),
        linked_flag: t || !(null == e || !e.linked_flag),
        url_trigger: t || !(null == e || !e.url_trigger),
        event_trigger: t || !(null == e || !e.event_trigger)
      };
    if (Object.values(i).some(Boolean)) {
      var r, s, n, o, a;
      if (null === (r = this.sessionManager) || void 0 === r || r.checkAndGetSessionAndWindowId(), i.sampling) null === (s = this.sessionRecording) || void 0 === s || s.overrideSampling();
      if (i.linked_flag) null === (n = this.sessionRecording) || void 0 === n || n.overrideLinkedFlag();
      if (i.url_trigger) null === (o = this.sessionRecording) || void 0 === o || o.overrideTrigger("url");
      if (i.event_trigger) null === (a = this.sessionRecording) || void 0 === a || a.overrideTrigger("event");
    }
    this.set_config({
      disable_session_recording: !1
    });
  }
  stopSessionRecording() {
    this.set_config({
      disable_session_recording: !0
    });
  }
  sessionRecordingStarted() {
    var e;
    return !(null === (e = this.sessionRecording) || void 0 === e || !e.started);
  }
  captureException(e, t) {
    var i,
      r = new Error("PostHog syntheticException"),
      s = H(null === (i = _.__PosthogExtensions__) || void 0 === i ? void 0 : i.parseErrorAsProperties) ? ne(ne({}, _.__PosthogExtensions__.parseErrorAsProperties([e.message, void 0, void 0, void 0, e], {
        syntheticException: r
      })), t) : ne({
        $exception_level: "error",
        $exception_list: [{
          type: e.name,
          value: e.message,
          mechanism: {
            handled: !0,
            synthetic: !1
          }
        }]
      }, t);
    this.exceptions.sendExceptionEvent(s);
  }
  loadToolbar(e) {
    return this.toolbar.loadToolbar(e);
  }
  get_property(e) {
    var t;
    return null === (t = this.persistence) || void 0 === t ? void 0 : t.props[e];
  }
  getSessionProperty(e) {
    var t;
    return null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.props[e];
  }
  toString() {
    var e,
      t = null !== (e = this.config.name) && void 0 !== e ? e : bo;
    return t !== bo && (t = bo + "." + t), t;
  }
  _isIdentified() {
    var e, t;
    return "identified" === (null === (e = this.persistence) || void 0 === e ? void 0 : e.get_property(Ae)) || "identified" === (null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.get_property(Ae));
  }
  _hasPersonProcessing() {
    var e, t, i, r;
    return !("never" === this.config.person_profiles || "identified_only" === this.config.person_profiles && !this._isIdentified() && j(this.getGroups()) && (null === (e = this.persistence) || void 0 === e || null === (t = e.props) || void 0 === t || !t[ue]) && (null === (i = this.persistence) || void 0 === i || null === (r = i.props) || void 0 === r || !r[He]));
  }
  _shouldCapturePageleave() {
    return !0 === this.config.capture_pageleave || "if_capture_pageview" === this.config.capture_pageleave && this.config.capture_pageview;
  }
  createPersonProfile() {
    this._hasPersonProcessing() || this._requirePersonProcessing("posthog.createPersonProfile") && this.setPersonProperties({}, {});
  }
  _requirePersonProcessing(e) {
    return "never" === this.config.person_profiles ? (ee.error(e + ' was called, but process_person is set to "never". This call will be ignored.'), !1) : (this._register_single(He, !0), !0);
  }
  _sync_opt_out_with_persistence() {
    var e,
      t,
      i,
      r,
      s = this.consent.isOptedOut(),
      n = this.config.opt_out_persistence_by_default,
      o = this.config.disable_persistence || s && !!n;
    (null === (e = this.persistence) || void 0 === e ? void 0 : e.disabled) !== o && (null === (i = this.persistence) || void 0 === i || i.set_disabled(o));
    (null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.disabled) !== o && (null === (r = this.sessionPersistence) || void 0 === r || r.set_disabled(o));
  }
  opt_in_capturing(e) {
    var t;
    (this.consent.optInOut(!0), this._sync_opt_out_with_persistence(), z(null == e ? void 0 : e.captureEventName) || null != e && e.captureEventName) && this.capture(null !== (t = null == e ? void 0 : e.captureEventName) && void 0 !== t ? t : "$opt_in", null == e ? void 0 : e.captureProperties, {
      send_instantly: !0
    });
    this.config.capture_pageview && this._captureInitialPageview();
  }
  opt_out_capturing() {
    this.consent.optInOut(!1), this._sync_opt_out_with_persistence();
  }
  has_opted_in_capturing() {
    return this.consent.isOptedIn();
  }
  has_opted_out_capturing() {
    return this.consent.isOptedOut();
  }
  clear_opt_in_out_capturing() {
    this.consent.reset(), this._sync_opt_out_with_persistence();
  }
  _is_bot() {
    return o ? Nn(o, this.config.custom_blocked_useragents) : void 0;
  }
  _captureInitialPageview() {
    a && !this._initialPageviewCaptured && (this._initialPageviewCaptured = !0, this.capture("$pageview", {
      title: a.title
    }, {
      send_instantly: !0
    }));
  }
  debug(e) {
    !1 === e ? (null == t || t.console.log("You've disabled debug mode."), localStorage && localStorage.removeItem("ph_debug"), this.set_config({
      debug: !1
    })) : (null == t || t.console.log("You're now in debug mode. All calls to PostHog will be logged in your console.\nYou can disable this with `posthog.debug(false)`."), localStorage && localStorage.setItem("ph_debug", "true"), this.set_config({
      debug: !0
    }));
  }
  _runBeforeSend(e) {
    if (J(this.config.before_send)) return e;
    var t = B(this.config.before_send) ? this.config.before_send : [this.config.before_send],
      i = e;
    for (var r of t) {
      if (i = r(i), J(i)) {
        var s = "Event '".concat(e.event, "' was rejected in beforeSend function");
        return Q(e.event) ? ee.warn("".concat(s, ". This can cause unexpected behavior.")) : ee.info(s), null;
      }
      i.properties && !j(i.properties) || ee.warn("Event '".concat(e.event, "' has no properties after beforeSend function, this is likely an error."));
    }
    return i;
  }
  getPageViewId() {
    var e;
    return null === (e = this.pageViewManager._currentPageview) || void 0 === e ? void 0 : e.pageViewId;
  }
}
!function (e, t) {
  for (var i = 0; i < t.length; i++) e.prototype[t[i]] = x(e.prototype[t[i]]);
}(Eo, ["identify"]);
var xo,
  Io = (xo = fo[bo] = new Eo(), function () {
    function e() {
      e.done || (e.done = !0, yo = !1, y(fo, function (e) {
        e._dom_loaded();
      }));
    }
    null != a && a.addEventListener ? "complete" === a.readyState ? e() : R(a, "DOMContentLoaded", e, {
      capture: !1
    }) : t && ee.error("Browser doesn't support `document.addEventListener` so PostHog couldn't be initialized");
  }(), xo);
export { v as COPY_AUTOCAPTURE_EVENT, e as Compression, Eo as PostHog, kn as SurveyQuestionBranchingType, Sn as SurveyQuestionType, wn as SurveyType, Io as default, g as knownUnsafeEditableEvent, Io as posthog, f as severityLevels };
